import { CategoryRepository } from "app/categories/repositories/CategoryRepository";
import { UserRepository } from "app/users/repositories/UserRepository";
import { MapsRepository } from "./MapsRepository";
import { TestimonialRepository } from "app/testimonials/repositories/TestimonialRepository";
import { CityContentRepository } from "app/cityContent/repositories/CityContentRepository";
import { CampaignRepository } from "app/campaign/repositories/CampaignRepository";

import React, { useContext } from "react";

interface RepositoryContextProps {
  categoryRepository: CategoryRepository;
  mapsRepository: MapsRepository;
  userRepository: UserRepository;
  testimonialRepository: TestimonialRepository;
  cityContentRepository: CityContentRepository;
  campaignRepository: CampaignRepository;
}

export const RootRepositoryContext =
  React.createContext<RepositoryContextProps>({} as RepositoryContextProps);

export const useRootRepositoryContext = () => useContext(RootRepositoryContext);
