import { Flex } from "@chakra-ui/react";
import React from "react";
import { SearchInfo } from "./SearchInfo";
import { SearchInputsGroup } from "./SearchInputsGroup";

export const SearchBox: React.FC = () => (
  <Flex
    ml={[0, 0, 0, 0, "105px"]}
    mt={[4, 4, 4, 4, 13]}
    flexDirection={["column", "column", "column", "column", "row"]}
    alignItems={["center", "center", "center", "center", null]}>
    <SearchInfo pr={[0, 0, 0, 0, 16]} />
    <SearchInputsGroup />
  </Flex>
);
