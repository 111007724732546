import { Photo } from "app/shared/models";
import { MAX_PHOTOS, MAX_SUB_PHOTOS } from "./constants";

export const generatePhotoAreas = (
  photos: Photo[],
  coverPhotoIndex: number,
  videoUrl?: string
): string[] => {
  const photosArea: string[] = [];
  for (let i = 0, j = 0; i < photos.length; i++) {
    if (i === coverPhotoIndex && !videoUrl) {
      photosArea.push("cover");
    } else {
      if (photos.length > MAX_PHOTOS) {
        if (j < MAX_SUB_PHOTOS) {
          photosArea.push(`photo_${j}`);
        } else {
          photosArea.push(`break`);
        }
      } else {
        photosArea.push(`photo_${j}`);
      }
      j++;
    }
  }
  return photosArea;
};
