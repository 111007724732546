import { ButtonProps, Button } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface Props extends ButtonProps {
  btnVariant?: "primary" | "secondary";
  children: ReactNode;
  disableHover?: boolean;
  hoverBgColor?: string;
  activeBgColor?: string;
}

export const WLIButton: React.FC<Props> = ({
  btnVariant,
  disableHover,
  isDisabled,
  children,
  onClick,
  hoverBgColor,
  activeBgColor,
  ...rest
}) => (
  <Button
    transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
    px={2}
    py={1}
    width="100%"
    fontSize="md"
    fontWeight="bold"
    color="content.button.color"
    bg="content.button.background"
    borderRadius={0}
    _hover={{ bg: "content.button.hover" }}
    _focus={{ textDecoration: "none" }}
    onClick={isDisabled ? undefined : onClick}
    {...rest}
  >
    {children}
  </Button>
);

//onClick = { isDisabled? undefined: onClick }

//_hover = {
//  isDisabled
//  ? { cursor: "not-allowed" }
//        : disableHover
//  ? undefined
//  : { bg: hoverBgColor ?? "brandRed.100" }
//    }
//_active = {
//  isDisabled
//  ? undefined
//    : {
//    bg: activeBgColor ?? "brandRed.500",
//    transform: "scale(0.98)",
//  }
//}
