import { Stack, Text } from "@chakra-ui/react";
import { StyledInput, Button } from "app/shared";
import { TextLink } from "app/shared/textLink";
import i18next from "i18next";
import React from "react";
import { MenuWrapper } from "../MenuWrapper";
import { observer } from "mobx-react";
import { useRootRepositoryContext } from "rootRepository";
import { compose } from "recompose";
import {
  IUserLoginSchema,
  IUserLoginResponseSchema,
} from "../../../users/forms/UserFormSchema";

interface Props {
  onLogin: () => void;
  onClose?: () => void;
  isInPopup?: boolean;
}

const LoginFormBase: React.FC<Props> = ({ onLogin, isInPopup, onClose }) => {
  const { userRepository } = useRootRepositoryContext();

  const loginObject: IUserLoginSchema = {
    grant_type: "password",
    username: "",
    password: "",
  };

  const handleChangeUsername = (event: any) => {
    loginObject.username = event.target.value;
  };
  const handleChangePassword = (event: any) => {
    loginObject.password = event.target.value;
  };

  return (
    <MenuWrapper
      isInPopup={isInPopup}
      maxWidth={"556px"}
      backgroundColor="lightestGrey"
    >
      <Stack direction="column" spacing={3} py={4} px={8}>
        <Text fontSize="lg" color="darkGrey" fontWeight="bold">
          {i18next.t("shared:navbar.loginMenu.loginForm.header")}
        </Text>
        <Stack spacing={2} color="black">
          <StyledInput
            width="100%"
            id="username"
            type="text"
            fontSize="lg"
            onChange={handleChangeUsername}
            placeholder={i18next.t(
              "shared:navbar.loginMenu.loginForm.usernamePlaceholder"
            )}
            variant="unstyled"
            px={3}
            py={2}
            mt={3}
            bg="white"
          />
          <StyledInput
            width="100%"
            id="password"
            type="password"
            fontSize="lg"
            onChange={handleChangePassword}
            placeholder={i18next.t(
              "shared:navbar.loginMenu.loginForm.passwordPlaceholder"
            )}
            variant="unstyled"
            px={3}
            py={2}
            mt={3}
            bg="white"
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={[2, 5]}>
            <TextLink
              to="/newuser"
              onClick={onClose}
              fontWeight="bold"
              color="brandRed.300"
              fontSize={["xs", "sm"]}
            >
              {i18next.t("shared:navbar.loginMenu.loginForm.registerButton")}
            </TextLink>
            <TextLink
              to="/passwort-zuruecksetzen"
              onClick={onClose}
              fontWeight="bold"
              color="brandRed.300"
              fontSize={["xs", "sm"]}
            >
              {i18next.t("shared:navbar.loginMenu.loginForm.recoverPassword")}
            </TextLink>
          </Stack>
          <Button
            onClick={() => {
              userRepository
                .getToken(loginObject)
                .then((a) => {
                  userRepository
                    .login(a)
                    .then(() => {
                      onLogin();
                      if (onClose) onClose();
                    })
                    .catch((e) => {
                      alert(e);
                    });
                })
                .catch((e) => {
                  alert(e);
                });
            }}
            color="content.button.color"
            bg="content.button.background"
            width={["40%", "30%"]}
            fontSize={["xs", "unset"]}
            borderRadius={0}
            _hover={{ bg: "content.button.hover" }}
            _focus={{ textDecoration: "none" }}
          >
            {i18next.t("shared:navbar.loginMenu.loginForm.loginButton")}
          </Button>
        </Stack>
      </Stack>
    </MenuWrapper>
  );
};

export const LoginForm = compose<Props, Props>(observer)(LoginFormBase);
