import { Flex, Box, BoxProps, Image, Text, Button } from "@chakra-ui/react";
import React from "react";
import { ButtonLink } from "../buttonLink"

interface Props extends BoxProps {
  headline?: string;
  imgSrc?: string;
  iconSrc?: string;
  buttonText?: string;
  buttonLink?: string;
}

const editButtonStyle = {
  color: "content.button.color",
  bg: "content.button.background",
  borderRadius: "0",   
  _hover: {
    cursor: "pointer",
    bg: "content.button.hover",
  },

};

export const SplitStage: React.FC<Props> = ({
  headline,
  imgSrc,
  iconSrc,
  buttonText,
  buttonLink,
  ...rest
}) => {

  return (
    <Flex
      ml={[0, 0, 0, 0, 0]}
      mt={[4, 4, 4, 4, 13]}
      mb={[0, 0, "36px","45px", 0]}
      flexDirection={["column", "column", "row", "row", "row"]}
      alignItems={["center", "center", "center", "center", null]}
      position="relative"
    >
      <Box
        width={["100%", "100%", "50%", "50%", "50%"]}
        pr={["0", "0", "20px", "20px", "64px"]}
        display="flex"
        alignItems={["center", "center", "flex-end", "flex-end", "flex-end"]}
        flexDirection="column"
        mt={["0", "0", "0", "0", "-150px"]}
        mb={["30px", "30px", "30px", "0", "0"]}
      >
        {iconSrc && (
          <Image
            mb="25px"
            maxHeight="135px"
            src={iconSrc}
          ></Image>
        )}
        {headline && (
          <Text
            fontWeight="bold"
            fontSize={["32px", "32px", "32px", "43px", " 43px"]}
            wordBreak="normal"
            textAlign={["center", "center", "right", "right", "right"]}
            lineHeight={["30px", "30px", "30px", "60px"]}
            maxWidth="400px"
            dangerouslySetInnerHTML={{ __html: headline}}
          >            
          </Text>
        )}
      </Box>
      <Box
        width={["100%", "100%", "50%", "50%", "50%"]}
        position="relative"
      >
        {imgSrc && (
          <Image
            src={imgSrc}
            width="100%"
          ></Image>
        )}

      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position={["static", "static", "absolute", "absolute", "absolute"]}
        bottom={["unset", "unset", "-60px", "-60px", "0"]}
        width="100%"
        mt={["27px", "27px", 0]}
      >
        <ButtonLink
          {...editButtonStyle}
          pl={["30px", "30px", "30px", "30px", "40px"]}
          pr={["30px", "30px", "30px", "30px", "40px"]}
          fontSize="28px"
          height={["unset", "unset", "50px", "50px", "65px"]}
          width={["100%", "100%", "unset", "unset", "unset"]}
          to={buttonLink ? buttonLink : "/"}
          pt={["10px", "10px", "0"]}
          pb={["10px", "10px", "0"]}
          textAlign={["center"]}
          whiteSpace={["initial", "initial","nowrap"]}          
         >
          {buttonText}
        </ButtonLink>
      </Box>
    </Flex>
  )
}

/*wordBreak="break-word"*/
