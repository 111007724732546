import { Grid } from "@chakra-ui/react";
import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";
import { customColors } from "styles";

const FooterArea = styled(Grid)`
  height: ${themeGet("sizes.footerHeight.sm")};
  ${themeGet("mediaQueries.lg")} {
    height: ${themeGet("sizes.footerHeight.lg")};
  }
`;

FooterArea.defaultProps = {
  gridArea: "footer",
  backgroundColor:  "footer.background"
  //background: `${customColors.brandRed[300]}`,
};

export default FooterArea;
