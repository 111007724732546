import React from "react";
import { Image, Box, Flex, FormControl, Input, Text } from "@chakra-ui/react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Field } from "formik";
import { Button } from "app/shared";
import i18next from "i18next";
import { ImageUploaderProps } from "./ImageUploaderProps";
import {
  FieldInternalProps,
  DEFAULT_LABEL_WIDTH,
} from "app/shared/forms/GenericInput";
import { useMediaQueryContext } from "styles/context";
/*
 * Provides a specific variant of the image upload component better suited for the business creation form
 */
interface ImageThumbnailProps {
  img: File;
  index: number;
  onDelete: (index: number) => void;
}

const UploadedImageThumbnail: React.FC<ImageThumbnailProps> = (props) => {
  const { img, index, onDelete } = props;
  return (
    <Image
      height="80px"
      src={URL.createObjectURL(img)}
      key={img.name}
      onClick={() => onDelete(index)}
    />
  );
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: 2,
  height: "84px",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  display: "flex",
  padding: 2,
  overflow: "auto",
});

export const CreateFormImageUploader: React.FC<ImageUploaderProps> = (
  props
) => {
  const { fieldName, parentForm } = props;
  const photos = parentForm.values.photos as File[];
  let hiddenInput: HTMLInputElement | undefined = undefined;

  const onPictureUpload = (e: any) => {
    const newPhoto = e.currentTarget.files[0] as File;
    const pictureAlreadyUploaded = photos.find(
      (it: File) => it.name === newPhoto.name
    );
    //if (!pictureAlreadyUploaded) {
    const result = photos.concat([newPhoto]);
    parentForm.setFieldTouched(fieldName, true);
    parentForm.setFieldValue(fieldName, result);

    if (photos.length == 1) {
      parentForm.setFieldValue("mainPhoto", photos[0].name);
    }
    //} else {
    //  console.warn(
    //    `A picture named: ${newPhoto.name} already exists in the collection`
    //  );
    //}
  };

  const onPictureDelete = (index: number) => {
    const updatedPhotos = photos.concat([]); // Work over a new array
    updatedPhotos.splice(index, 1);

    parentForm.setFieldTouched(fieldName, true);
    parentForm.setFieldValue(fieldName, updatedPhotos);

    if (photos.length == 1) {
      parentForm.setFieldValue("mainPhoto", photos[0].name);
    }
  };

  const onPictureReorder = (fromIndex: number, toIndex: number) => {
    const updatedPhotos = photos.concat([]); // Work over a new array
    const movedItem = updatedPhotos.splice(fromIndex, 1);
    updatedPhotos.splice(toIndex, 0, movedItem[0]);
    parentForm.setFieldValue(fieldName, updatedPhotos);
    parentForm.setFieldTouched(fieldName, true);

    if (toIndex == 0) {
      parentForm.setFieldValue("mainPhoto", movedItem[0].name);
    }
  };

  const renderUploadedPhotos = () =>
    photos.map((img, idx) => {
      if (idx !== -1) {
        return (
          <Draggable key={img.name} draggableId={img.name} index={idx}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <UploadedImageThumbnail
                  img={img}
                  index={idx}
                  onDelete={() => {
                    if (
                      window.confirm(
                        "Soll das Bild wirklich gelöscht werden?"
                      ) == true
                    ) {
                      onPictureDelete(idx);
                    }
                  }}
                  key={img.name}
                />
              </div>
            )}
          </Draggable>
        );
      } else {
        return <></>;
      }
    });

  const { isSmallMedium } = useMediaQueryContext();

  return (
    <Box mt={3}>
      <Flex
        direction={isSmallMedium ? "column" : "row"}
        justifyContent="flex-start"
      >
        <Text width={DEFAULT_LABEL_WIDTH}>
          {i18next.t(`stores:form.fields.${fieldName}.label`)}
        </Text>
        <Box width="100px">
          <Field className="block" name={fieldName} type="file">
            {(renderProps: FieldInternalProps) => (
              <FormControl
                isInvalid={
                  renderProps.form.errors[fieldName] &&
                  renderProps.form.touched[fieldName]
                }
              >
                <Button
                  width="80px"
                  height="80px"
                  onClick={(e) => (
                    e.preventDefault(), hiddenInput && hiddenInput.click()
                  )}
                >
                  +
                </Button>
                <Input
                  accept="image/png, image/jpeg, image/png"
                  hidden
                  type="file"
                  id={fieldName}
                  onChange={onPictureUpload}
                  ref={(el: HTMLInputElement) => (hiddenInput = el)}
                />
              </FormControl>
            )}
          </Field>
        </Box>
        <Flex flex="1">
          <DragDropContext
            onDragEnd={(opResult: DropResult) => {
              // Since index is a number we need to compare with undefined manually, otherwise the 0 index will fail
              if (
                opResult.destination &&
                opResult.destination.index !== undefined
              ) {
                onPictureReorder(
                  opResult.source.index,
                  opResult.destination.index
                );
              }
            }}
          >
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle()}
                  {...provided.droppableProps}
                >
                  {renderUploadedPhotos()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
      </Flex>
      <Box ml={isSmallMedium ? "0" : DEFAULT_LABEL_WIDTH}>
        <Text>{i18next.t(`stores:form.fields.${fieldName}.helpText`)}</Text>
        {parentForm?.errors[fieldName] && parentForm.touched[fieldName] ? (
          <Box color="brandRed.300">{parentForm.errors[fieldName]}</Box>
        ) : null}
      </Box>
    </Box>
  );
};
