import { BoxProps, Stack } from "@chakra-ui/react";
import { Divider } from "app/shared/divider";
import React from "react";
import { AddBusinessButton } from "../addBusinessButton";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { LoginMenu } from "../loginMenu";
import { observer } from "mobx-react";
import { useRootRepositoryContext } from "rootRepository";
import { ProfileMenu } from "../profileMenu";

interface ExternalProps extends BoxProps {
  onClose?: () => void;
}

interface Props extends ExternalProps, RouteComponentProps {}

const NavbarActionsBase: React.FC<Props> = ({
  color,
  history,
  onClose,
  ...rest
}) => {
  const { userRepository, cityContentRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  const isNavbar = color === "navbar.drawer.color";

  return (
    <Stack alignItems="center" direction="row" {...rest}>
      <AddBusinessButton
        iconSize="30px"
        isInNavBarMenu={isNavbar}
        color={color}
        onClick={() => {
          history.push("/newUserAndStore");
          if (onClose) onClose();
        }}
      />
      <Divider color={color} fontSize="xl" />
      {user ? (
        <ProfileMenu
          user={user}
          onLogout={() => {
            userRepository.logout();
            history.push("/");
          }}
          onClose={onClose}
        />
      ) : (
        <LoginMenu color={color} onClose={onClose} />
      )}
    </Stack>
  );
};
export const NavbarActions = compose<Props, ExternalProps>(
  withRouter,
  observer
)(NavbarActionsBase);
