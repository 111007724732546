import { theme as chakraUiTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

export const layoutSizes = {
  maxWidth: "1440px",
  navbarHeight: { sm: "70px", lg: "90px" },
  footerHeight: { sm: "auto", lg: "220px" },
};

// 1660-Breakpoint für HomePage-Headline hinzugefügt
export const breakpoints = createBreakpoints({
  sm: "544px",
  smmd: "768px",
  md: "1024px",
  mdlg: "1250px",
  lg: "1440px",
  xl: "1660px",
});

export const customColors = {
  brandRed: {
    50: "#ffe5e5",
    100: "#fbb9ba",
    200: "#F17878",
    300: "#EB5757",
    400: "#e63333",
    500: "#cc1a19",
    600: "#a01313",
    700: "#730c0d",
    800: "#460506",
    900: "#1e0000",
  },
  darkRed: "#DA1818",

  brandGrey: "#455A64",
  lighterBrandGrey: "#576a74",
  lightestGrey: "#F2F2F2",
  lightGrey: "#F4F3F3",
  grey: "#E0E0E0",
  mdGrey: "#C4C4C4",
  darkGrey: "#455A64",
  darkestGrey: "#828282",

  lightestBlack: "#BDBDBD",
  lightBlack: "#3D3C3C",
};

export const theme = {
  ...chakraUiTheme,
  fonts: {
    body: "Yaldevi Colombo Regular",
    heading: "Yaldevi Colombo Bold",
    mono: "Yaldevi Colombo Regular",
  },
  fontSizes: {
    xxs: "12px",
    xs: "14px",
    "2xs": "15px",
    sm: "16px",
    md: "18px",
    lg: "20px",
    xl: "24px",
    "2lg": "22px",
    "2xl": "26px",
    "3xl": "28px",
    "4xl": "32px",
    "5xl": "36px",
    "6xl": "45px",
    "7xl": "48px",
  },
  lineHeights: {
    normal: "normal",
    none: "1",
    shorter: "1.25",
    short: "1.375",
    base: "1.5",
    tall: "1.625",
    taller: "2",
  },
  colors: {
    ...chakraUiTheme.colors,
    ...customColors,
  },
  sizes: {
    ...chakraUiTheme.sizes,
    ...layoutSizes,
  },
  space: {
    ...chakraUiTheme.space,
    "7": "1.75rem",
    "9": "2.25rem",
    "13": "3.25rem",
    "18": "4.5rem",
  },
  breakpoints,
  mediaQueries: {
    sm: `@media screen and (min-width: ${breakpoints.sm})`,
    md: `@media screen and (min-width: ${breakpoints.smmd})`,
    lg: `@media screen and (min-width: ${breakpoints.md})`,
    vlg: `@media screen and (min-width: ${breakpoints.mdlg})`,
  },
};
