import { Grid, GridProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { customColors } from "styles";
import { PAGES_WITH_GREY_LAYOUT } from "./constants";
import { useRootRepositoryContext } from "rootRepository";

const StyledMainLayout = styled(Grid)<GridProps>`
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "navbar"
    "header"
    "content"
    "footer";

  ${themeGet("mediaQueries.md")} {
    grid-template-columns:
      auto minmax(auto, ${themeGet("sizes.maxWidth")})
      auto;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
      "navbar navbar navbar"
      "header header header"
      "...... content ......"
      "footer footer footer";
  }
`;

/*


${themeGet("mediaQueries.vlg")} {
    grid-template-columns:
      auto minmax(auto, ${themeGet("sizes.maxWidth")})
      auto;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
      "navbar navbar navbar"
      "header header header"
      "...... content ......"
      "footer footer footer";
  }

 * */

interface Props extends GridProps, RouteComponentProps {}

const MainLayoutBase: React.FC<Props> = ({ history, ...rest }) => {
  const greyLayout =
    PAGES_WITH_GREY_LAYOUT.filter((page) =>
      history.location.pathname.includes(page)
    ).length !== 0;

  const isHomepage = history.location.pathname === "/";
  const isStoreDetail = history.location.pathname.indexOf("/store/") >= 0;
  const isStoreWindowDetail =
    history.location.pathname.indexOf("/storeWindow/") >= 0;
  const isProductDetail = history.location.pathname.indexOf("/product/") >= 0;
  const isSearch = history.location.pathname.indexOf("/search") >= 0;
  /*
    background={greyLayout ? customColors.darkGrey : "white"}
    color={greyLayout ? "white" : "black"}
*/
  const { cityContentRepository } = useRootRepositoryContext();
  const colorJson = cityContentRepository.GetAppSettingJSONEntry("_sysColors");
  let backgroundColor = "white";
  let textColor = "black";

  if (colorJson) {
    if (isStoreDetail || isStoreWindowDetail || isProductDetail) {
      backgroundColor =
        colorJson?.content?.store?.storeDetail?.background ?? backgroundColor;
      textColor = colorJson?.content?.store?.storeDetail?.color ?? textColor;
    }
    if (isSearch) {
      backgroundColor =
        colorJson?.content?.search?.background ?? backgroundColor;
      textColor = colorJson?.content?.search?.color ?? textColor;
    }
  }

  return (
    <StyledMainLayout
      background={backgroundColor}
      color={textColor}
      backgroundImage={
        isHomepage ? "url(/static/media/header-bg.0e74b99f.svg)" : ""
      }
      backgroundRepeat={isHomepage ? "no-repeat" : ""}
      backgroundPosition={isHomepage ? "center top" : ""}
      backgroundSize={isHomepage ? ["310%", "310%", "contain"] : ""}
      {...rest}
    />
  );
};

MainLayoutBase.defaultProps = {
  height: "100%",
};

export const MainLayout = compose<Props, {}>(withRouter)(MainLayoutBase);
