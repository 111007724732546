import { computed, flow, observable } from "mobx";
import { Testimonial } from "../models";
import { getLatestTestimonial, getActiveTestimonial, getTestimonialById } from "../services/index";

export class TestimonialRepository {
  @observable private _latestTestimonial?: Testimonial;
  @observable private _activeTestimonials: Testimonial[];
  @observable private _cityTestimonial?: Testimonial;


  constructor() {
    this.fetchLatestTestimonial();
    this._activeTestimonials = [];
  }

  fetchLatestTestimonial = flow(function* (this: TestimonialRepository) {
    try {     
      this._latestTestimonial = yield getLatestTestimonial();
    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  fetchActiveTestimonials = flow(function* (this: TestimonialRepository) {
    try {
      this._activeTestimonials = yield getActiveTestimonial();
    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  fetchCityTestimonial = flow(function* (this: TestimonialRepository, id: string) {
    try {
      if (id && id.length > 0) {
        this._cityTestimonial = yield getTestimonialById(id);
      }
    } catch (error) {
      console.error(error);
    }
  }).bind(this);
  

  @computed get latestTestimonial(): Testimonial| undefined{
    return this._latestTestimonial;
  }
  @computed get activeTestimonials(): Testimonial[] {
    return this._activeTestimonials;
  }

  @computed get cityTestimonial(): Testimonial | undefined {
    return this._cityTestimonial;
  }
}
