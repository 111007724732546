import { Text, Link } from "@chakra-ui/react";
import {
  ContentArea, ButtonLink
} from "app/shared";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";
import { StaticPageTitle } from "app/staticPages/StaticPageTitle";
import React, { useState } from "react";
import i18next from "i18next";
import { compose } from "recompose";
import { observer } from "mobx-react";
import { doiUser } from "app/users/services";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useScript from "../utils/useScript";

interface Params {
  id: string;
  createStore: string;
}

type Props = RouteComponentProps<Params>;

const DoiBase: React.FC<Props> = ({ match }) => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'DOI', section: 'DOI', pubdate: '', author: '', url: '/DOI', object_type: 'DOI' }}); } ");

  var id = match.params.id;
  var createStore = undefined;
  if (id.indexOf('&') > 0) {
    id = match.params.id.split('&')[0];
    createStore = match.params.id.split('&')[1];
  }

  const [successfully, setsuccessfully] = useState(false);
  const [loaded, setloaded] = useState(false);

  if (id != undefined) {
    doiUser(id)
      .then(() => {
        setloaded(true);
        setsuccessfully(true);
      })
      //.then(() => successfully = true)
      .catch((r) => {
        setloaded(true);
        console.log(r)
      });

    return (
      <ContentArea>
        {!loaded ? (
          <StaticPageTitle mb={"96px"}>{i18next.t("users:doi.titleWait")}</StaticPageTitle>
        ) : (
            successfully ? (
              <>
                <StaticPageTitle mb={"96px"}>{i18next.t("users:doi.title")}</StaticPageTitle>
                <StaticPageTextBlock>
                  <Text fontWeight="bold">
                    {i18next.t("users:doi.message")}
                  </Text>
                </StaticPageTextBlock>
              </>
            ) : (
                <>
                  <StaticPageTitle mb={"96px"}>{i18next.t("users:doi.titleErrror")}</StaticPageTitle>
                  <StaticPageTextBlock>
                    <Text fontWeight="bold">
                      {i18next.t("users:doi.messageErrror")}
                    </Text>
                  </StaticPageTextBlock>
                </>
              )
          )}
        {(successfully && createStore != undefined ? (
          <ButtonLink
            width="300px"
            to={`/newStore`}
            color="white"
            bg="brandRed.300"
            borderRadius={0}
            _hover={{ bg: "brandRed.100" }}
            _focus={{ textDecoration: "none" }}>
            {i18next.t("users:doi.linkCreateStore")}
          </ButtonLink>
        ) :
          (
            <ButtonLink
              width="300px"
              to={`/`}
              color="white"
              bg="brandRed.300"
              borderRadius={0}
              _hover={{ bg: "brandRed.100" }}
              _focus={{ textDecoration: "none" }}>
              {i18next.t("users:doi.linkHome")}
            </ButtonLink>
          ))
        }
      </ContentArea >
    );
  } else {
    return (
      <ContentArea>
        <StaticPageTitle mb={"96px"}>{i18next.t("users:doi.titleErrror")}</StaticPageTitle>
        <StaticPageTextBlock>
          <Text fontWeight="bold">
            {i18next.t("users:doi.messageErrror")}
          </Text>

          <ButtonLink
            width="300px"
            to={`/`}
            color="white"
            bg="brandRed.300"
            borderRadius={0}
            _hover={{ bg: "brandRed.100" }}
            _focus={{ textDecoration: "none" }}>
            {i18next.t("users:doi.linkHome")}
          </ButtonLink>)
        </StaticPageTextBlock>
      </ContentArea>
    );
  }

};

export const Doi = compose<Props, {}>(withRouter, observer)(DoiBase);
