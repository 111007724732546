import {
  CreateUserFormViewModel,
  CreateUserFormSchema,
} from "./UserFormSchema";
import React, { useState } from "react";
import { FormikProps, Form, Formik } from "formik";
import {
  SimpleGrid,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Text,
  Link,
} from "@chakra-ui/react";
import {
  UserSelectField,
  UserFormField,
  UserRadioGroupField,
  SingleSelectionRadio,
} from "./UserFormItems";
import { StyledInput, Button, CloseButton, Heading } from "app/shared";
import i18next from "i18next";
import { PersonTitle, personTitleArray } from "app/users/models";
import { createUser } from "../services";
import { StaticPageTextBlock } from "../../staticPages/StaticPageTextBlock";
import { useMediaQueryContext } from "styles/context";


const initialState: CreateUserFormViewModel = {
  title: "",
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  phoneNumber: "",
  password: "",
  repeatPassword: "",
  createStore: false,
  acceptTermsAndConditions: false,
};

interface SubmitHandlerProps {
  setSubmitting: (isSubmitting: boolean) => void;
}

var validationMessages = "";

export const CreateUserForm: React.FC<({ setCreateStore?: boolean })> = ({ setCreateStore = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalValidation, setShowModalValidation] = useState(false);

  const submitHandler = (
    values: CreateUserFormViewModel,
    handlerProps: SubmitHandlerProps
  ) => {
    createUser(values)
      .then(() => setShowModal(true))
      .catch((r) => alert(r))
      .finally(() => handlerProps.setSubmitting(false));
  };


  const { isSmall, isSmallMedium, isMedium, isMediumLarge, isLarge, isVeryLarge } = useMediaQueryContext();

  const renderActualForm = (props: FormikProps<CreateUserFormViewModel>) => {
    const { handleSubmit } = props;

    return (
      <Form onSubmit={handleSubmit}>
        <SimpleGrid columns={2}>
          <Box>
            <UserSelectField
              fieldName="title"
              fieldType="string"
              options={personTitleArray.values}
              flexDirection={isMedium ? "column" : "row"}

            />
            <UserFormField
              fieldName="firstName"
              fieldType="string"
              Component={StyledInput}
              flexDirection={isMedium ? "column" : "row"}
            />
            <UserFormField
              fieldName="lastName"
              fieldType="string"
              Component={StyledInput}
              flexDirection={isMedium ? "column" : "row"}
            />
            <UserFormField
              fieldName="email"
              fieldType="string"
              Component={StyledInput}
              flexDirection={isMedium ? "column" : "row"}
            />
            <UserFormField
              fieldName="username"
              fieldType="string"
              Component={StyledInput}
              flexDirection={isMedium ? "column" : "row"}
            />
            <UserFormField
              fieldName="phoneNumber"
              fieldType="string"
              Component={StyledInput}
              flexDirection={isMedium ? "column" : "row"}
            />
            <UserFormField
              fieldName="password"
              fieldType="password"
              Component={StyledInput}
              flexDirection={isMedium ? "column" : "row"}
            />
            <UserFormField
              fieldName="repeatPassword"
              fieldType="password"
              Component={StyledInput}
              flexDirection={isMedium ? "column" : "row"}
            />
            <UserRadioGroupField
              fieldName="createStore"
              fieldType="boolean"
              options={[true, false]}
              defaultChecked={setCreateStore}
            />
              
            <SingleSelectionRadio fieldName="acceptTermsAndConditions" />
           
          </Box>
        </SimpleGrid>
        <Text mt="30px">
          {i18next.t(`users:form.wloInfoText`)}
        </Text>
        <Flex py={3} justifyContent="center">
          <Button
            width="200px"
            type="submit"
            onClick={(e) => {
              props.validateForm();
              validationMessages = "";

              if (!props.isValid || props.isSubmitting || !props.values.firstName || !props.values.lastName || !props.values.username || !props.values.email || !props.values.password || !props.values.acceptTermsAndConditions) {
                e.preventDefault();

                if (!props.values.firstName)
                  validationMessages += "<b>Vorname:</b> " + i18next.t(`users:form.validation.requiredFirstname`) + '<br />';
                if (!props.values.lastName)
                  validationMessages += "<b>Name:</b> " + i18next.t(`users:form.validation.requiredLastname`) + '<br />';
                if (!props.values.username)
                  validationMessages += "<b>Benutzername:</b> " + i18next.t(`users:form.validation.requiredUsername`) + '<br />';
                if (!props.values.email)
                  validationMessages += "<b>E-Mail:</b> " + i18next.t(`users:form.validation.requiredEmail`) + '<br />';
                if (!props.values.password)
                  validationMessages += "<b>Passwort:</b> " + i18next.t(`users:form.validation.requiredPassword`) + '<br />';
                if (!props.values.acceptTermsAndConditions)
                  validationMessages += "<b>Zustimmung:</b> " + i18next.t(`users:form.validation.requiredAcceptTermsAndConditions`) + '<br />';

                if (validationMessages != '') {
                  setShowModalValidation(true);
                }
              }

            }}>
            {i18next.t(`users:form.submit.label`)}
          </Button>
        </Flex>
      </Form>
    );
  };

  return (
    <>
      {showModalValidation && <UserValidationMessage setShowModalValidation={setShowModalValidation} />}
      {showModal && <AccountCreatedMessage />}
      {!showModal ? (
        <Formik
          initialValues={initialState}
          validationSchema={CreateUserFormSchema}
          onSubmit={submitHandler}>
          {(props) => renderActualForm(props)}
        </Formik>) : (
          <StaticPageTextBlock>
            <Text fontWeight="bold">
              {i18next.t("users:form.submit.waitForMail")}
            </Text>
          </StaticPageTextBlock>
        )}
    </>
  );
};


interface ModalValidationProps {
  setShowModalValidation: (value: boolean) => void
}

const UserValidationMessage: React.FC<ModalValidationProps> = ({ setShowModalValidation }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isOpen) {
    onOpen();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg="white" maxW={["800px"]}>
        <ModalHeader>
          <Heading>{i18next.t("users:form.popup.titleUserValidation")}</Heading>
        </ModalHeader>
        <CloseButton
          onClick={() => {
            onClose();
            setShowModalValidation(false);
          }}
          iconSize={"13px"}
          position="absolute"
          top={0}
          right={1}
          iconColor="brandRed.300"
        />
        <ModalBody mb={["20px"]}>
          <div dangerouslySetInnerHTML={{ __html: validationMessages }} />
          <br />
          <br />
          <Box alignContent="center" textAlign="center" width="250px">
            <Button
              onClick={(e) => { onClose(); e.preventDefault(); setShowModalValidation(false); }}
              color="white"
              fontWeight="bold">
              {i18next.t("users:form.popup.backToEdit")}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const AccountCreatedMessage: React.FC = () => {
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isOpen && firstRun) {
    onOpen();
    setFirstRun(false);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="white" maxW={["800px"]}>
        <ModalHeader>
          <Heading>{i18next.t("users:form.popup.title")}</Heading>
        </ModalHeader>
        <CloseButton
          onClick={onClose}
          iconSize={"13px"}
          position="absolute"
          top={0}
          right={1}
          iconColor="brandRed.300"
        />
        <ModalBody mb={["80px"]}>
          <Text fontSize="lg">{i18next.t("users:form.popup.text")}</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
