import { CategoryDTO, SubCategory } from "../models";
import { Chance } from "chance";

const generateSubCategories = (n: number) => {
  const chance = new Chance();
  const result = [];
  for (let i = 0; i < n; i++) {
    result.push({
      id: chance.guid(),
      name: chance.tv(),
    });
  }
  return result;
};

export class MockCategoryFactory {
  private static db = [
    {
      metaCategoryName: "Fashion",
      categoryName: "Bekleidung, Schuhe und Accessoires",
      categoryId: "",      
      subCategories: generateSubCategories(8),
    },
    {
      metaCategoryName: "Fashion",
      categoryName: "Dienst-leistungen",
      categoryId: "",
      subCategories: generateSubCategories(2),
    },
    {
      metaCategoryName: "Fashion",
      categoryName: "Kunst & Handwerk",
      categoryId: "",
      subCategories: generateSubCategories(5),
    },
    {
      metaCategoryName: "Fashion",
      categoryName: "Sonstiges",
      categoryId: "",
      subCategories: generateSubCategories(4),
    },
    {
      metaCategoryName: "Fashion",
      categoryName: "Bücher & Zeitschriften",
      categoryId: "",
      subCategories: generateSubCategories(3),
    },
    {
      metaCategoryName: "Fashion",
      categoryName: "Kinder",
      categoryId: "",
      subCategories: generateSubCategories(6),
    },
    {
      metaCategoryName: "Fashion",
      categoryName: "Schmuck, Uhren & Geschenke",
      categoryId: "",
      subCategories: generateSubCategories(1),
    },
    {
      metaCategoryName: "Fashion",
      categoryName: "Stoffmasken",
      categoryId: "",
      subCategories: generateSubCategories(2),
    },
    {
      metaCategoryName: "Rund ums Essen",
      categoryName: "Dienst-leistungen",
      categoryId: "",
      subCategories: generateSubCategories(7),
    },
    {
      metaCategoryName: "Rund ums wohnen",
      categoryName: "Dienst-leistungen",
      categoryId: "",
      subCategories: generateSubCategories(5),
    },
    {
      metaCategoryName: "Rund ums wohnen",
      categoryName: "Haus & Garten",
      categoryId: "",
      subCategories: generateSubCategories(7),
    },
    {
      metaCategoryName: "Rund ums wohnen",
      categoryName: "Kunst & Handwerk",
      categoryId: "",
      subCategories: generateSubCategories(2),
    },
    {
      metaCategoryName: "Rund ums wohnen",
      categoryName: "Sonstiges",
      categoryId: "",
      subCategories: generateSubCategories(3),
    },
    {
      metaCategoryName: "Rund ums wohnen",
      categoryName: "Haustiere",
      categoryId: "",
      subCategories: generateSubCategories(1),
    },
    {
      metaCategoryName: "Rund ums wohnen",
      categoryName: "Schmuck, Uhren & Geschenke",
      categoryId: "",
      subCategories: generateSubCategories(9),
    },
    {
      metaCategoryName: "Büro & Business",
      categoryName: "Dienst-leistungen",
      categoryId: "",
      subCategories: generateSubCategories(6),
    },
    {
      metaCategoryName: "Unterhaltung & Kultur",
      categoryName: "Dienst-leistungen",
      categoryId: "",
      subCategories: generateSubCategories(7),
    },
    {
      metaCategoryName: "Andere",
      categoryName: "Dienst-leistungen",
      categoryId: "",
      subCategories: generateSubCategories(2),
    },
  ];

  static allMetaCategories(): CategoryDTO[] {
    return [];
    //return [];
  }
}
