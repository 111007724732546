import { Image, ImageProps } from "@chakra-ui/react";
import React from "react";
import { ButtonLink } from "../buttonLink";
import logoGrey from "./static/logo-grey.svg";
import logo from "./static/logo.svg";
import { useRootRepositoryContext } from "rootRepository";

interface Props extends ImageProps {
  width: any;
  onClick?: () => void;
  isInDrawerMenu?: boolean;
  isInNavBar?: boolean;
}

export const SiteImageButton: React.FC<Props> = ({
  width,
  onClick,
  isInDrawerMenu,
  isInNavBar,
  ...rest
}) => {
  const { cityContentRepository } = useRootRepositoryContext();
  const isHomepage = window.location.pathname === "/";
  const imageUrl = isInDrawerMenu
    ? cityContentRepository.GetAppSettingEntry("000-B000-NavMenu-Logo")
    : cityContentRepository.GetAppSettingEntry("000-B000-Logo");

  return (
    <ButtonLink
      to="/"
      onClick={onClick}
      variant="unstyled"
      width={width ?? ["150px", "300px", "384px"]}
      height={[70, 70, 90]}
      display="table"
      _focus={{ textDecoration: "none" }}
    >
      {(!isHomepage || !isInNavBar) && (
        <Image
          height={[70, 70, 90]}
          width="100%"
          src={imageUrl}
          alt="site-logo"
          paddingBottom="5px"
          {...(rest as any)}
        />
      )}
    </ButtonLink>
  );
};
