import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import i18next from "i18next";
import {
  Box,
  Flex,
  Wrap,
  WrapItem,
  Center,
  Square,
  Circle,
  Checkbox,
  Image,
  Text,
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";

import {
  StoreWindowFormSchema,
  StoreWindowFormViewModel,
} from "./StoreWindowInfoSchema";
import {
  StoreWindowInputField,
  StoreWindowCheckboxField,
  ImageUploaderField,
} from "./StoreWindowFormFields";

import { useMediaQueryContext } from "styles/context";
import {
  Button,
  StyledInput,
  ValidationPopup,
  CreatingPopup,
  CreatedPopup,
  NeedLoginPopup,
  Section,
  Popup,
} from "app/shared";

import { createStoreWindow, updateStoreWindow } from "../services";
import { ProductForTable } from "app/products/models";
import { useRootRepositoryContext } from "../../../rootRepository";
import { CloseIcon, ImageIcon } from "styles/icons";
import { getOwnedProducts } from "app/products/services";
import environment from "configurations";

const MAX_PRODUCTS_IN_STOREWINDOW = 5;

let validationMessages = "";

interface SubmitHandlerProps {
  setSubmitting: (isSubmitting: boolean) => void;
}

interface Props {
  initialState: StoreWindowFormViewModel;
  userId: string;
  storeId?: string;
  storeWindowId?: string;
}

export const BaseStoreWindowForm: React.FC<Props> = (storeWindowFormProps) => {
  const [showModalCreation, setShowModalCreation] = useState(false);
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [showModalCreated, setShowModalCreated] = useState(false);
  const [showNeedLoginMessage, setShowNeedLoginMessage] = useState(false);

  const { userRepository } = useRootRepositoryContext();

  const { isSmallMedium } = useMediaQueryContext();

  const submitHandler = (
    values: StoreWindowFormViewModel,
    handlerProps: SubmitHandlerProps
  ) => {
    setShowModalCreation(true);

    if (storeWindowFormProps.storeWindowId == undefined) {
      if (storeWindowFormProps.storeId)
        values.storeId = storeWindowFormProps.storeId;
      createStoreWindow(values)
        .then(() => {
          setShowModalCreation(false);
          setShowModalCreated(true);
        })
        .catch((err: any) => {
          console.error(err.message);
          setShowModalCreation(false);
          if (err?.status === 401 || err?.response?.status === 401) {
            setShowNeedLoginMessage(true);
            userRepository.logout();
          }
        })
        .finally(() => handlerProps.setSubmitting(false));
    } else {
      updateStoreWindow(values)
        .then(() => {
          setShowModalCreation(false);
          setShowModalCreated(true);
        })
        .catch((err: any) => {
          console.error(err.message);
          setShowModalCreation(false);
          if (err?.status === 401 || err?.response?.status === 401) {
            setShowNeedLoginMessage(true);
            userRepository.logout();
          }
        })
        .finally(() => handlerProps.setSubmitting(false));
    }
  };

  const renderActualForm = (props: FormikProps<StoreWindowFormViewModel>) => {
    const { values, handleSubmit } = props;
    const products = props.values.products as ProductForTable[];

    const AddProductBtn = () => (
      <Popup
        trigger={(showPopup) => (
          <Button onClick={showPopup}>
            {i18next.t("storeWindows:form.addProducts")}
          </Button>
        )}
        content={(onClose) => (
          <AddProductsDialog
            userId={storeWindowFormProps.userId}
            storeWindowProducts={products}
            onAdd={(newProducts) => {
              props.setFieldValue("products", products.concat(newProducts));
              props.setFieldTouched("products", true);
              onClose();
            }}
            onAbort={() => {
              onClose();
            }}
          />
        )}
        contentMaxWidth={["600px"]}
      />
    );

    return (
      <Form onSubmit={handleSubmit}>
        <Box py={3}>
          <Text fontSize="xl" fontWeight="bold">
            {i18next.t(`storeWindows:form.storeWindowInfoTitle`)}
          </Text>
          <Flex
            justifyContent="flexStart"
            flexDirection={"column"}
            width={"100%"}
          >
            <StoreWindowCheckboxField fieldName="visible" fieldType="boolean" />

            <StoreWindowInputField
              fieldName="name"
              fieldType="string"
              Component={StyledInput}
              flexDirection={isSmallMedium ? "column" : "row"}
            />

            <ImageUploaderField fieldName="photo" parentForm={props} />
          </Flex>
          <Section
            title={i18next.t(`storeWindows:form.productsInfoTitle`)}
            my={10}
            button={<AddProductBtn />}
          >
            <Wrap spacing={5}>
              {products.map((product, idx) => {
                return (
                  <WrapItem key={idx}>
                    <Center>
                      <ProductBox
                        product={product}
                        onDelete={() => {
                          props.setFieldValue(
                            "products",
                            products.filter(
                              (element) => element.id !== product.id
                            )
                          );
                          props.setFieldTouched("products", true);
                        }}
                      />
                    </Center>
                  </WrapItem>
                );
              })}
            </Wrap>
          </Section>
        </Box>

        <Flex py={3} justifyContent="center">
          <Button
            width="200px"
            type="submit"
            onClick={(e) => {
              props.validateForm();

              validationMessages = "";

              if (!props.isValid || props.isSubmitting) {
                e.preventDefault();

                if (!props.values.name)
                  validationMessages +=
                    "<b>Name:</b> " +
                    i18next.t(`storeWindows:form.validation.requiredName`) +
                    "<br />";
                if (!props.values.photo)
                  validationMessages +=
                    "<b>Fotos:</b> " +
                    i18next.t(`storeWindows:form.validation.requiredImage`) +
                    "<br />";
              }

              if (validationMessages != "") {
                setShowModalValidation(true);
              }
            }}
          >
            {i18next.t(`storeWindows:form.submit.label`)}
          </Button>
        </Flex>
      </Form>
    );
  };

  return (
    <>
      {showModalValidation && (
        <ValidationPopup
          setShowModalValidation={setShowModalValidation}
          validationMessages={validationMessages}
        />
      )}
      {showModalCreation && <CreatingPopup />}
      {showModalCreated && <CreatedPopup />}
      {showNeedLoginMessage && <NeedLoginPopup />}

      <Box>
        <Formik
          initialValues={storeWindowFormProps.initialState}
          validationSchema={StoreWindowFormSchema}
          onSubmit={submitHandler}
        >
          {(props) => renderActualForm(props)}
        </Formik>
      </Box>
    </>
  );
};

interface ProductBoxProps {
  product: ProductForTable;
  onDelete: () => void;
}

const ProductBox: React.FC<ProductBoxProps> = ({ product, onDelete }) => (
  <Square size="250px" position="relative" boxShadow="lg">
    <Image
      objectFit="contain"
      width="100%"
      height="100%"
      src={environment.site + product.photo?.url}
    />
    <Circle
      position="absolute"
      top={5}
      right={6}
      boxSize={6}
      bg="grey"
      _hover={{ cursor: "pointer", bg: "darkGrey" }}
    >
      <CloseIcon color="white" boxSize={3} onClick={onDelete} />
    </Circle>
    <Box position="absolute" bottom={0} width="100%" bg="white" alignSelf="end">
      <Text px={4} py={2} fontWeight="bold" fontSize="md" color="darkGrey">
        {product.name}
      </Text>
    </Box>
  </Square>
);

interface AddProductsDialogProps {
  userId: string;
  storeWindowProducts: ProductForTable[];
  onAdd: (products: ProductForTable[]) => void;
  onAbort: () => void;
}

const AddProductsDialog: React.FC<AddProductsDialogProps> = ({
  userId,
  storeWindowProducts,
  onAdd,
  onAbort,
}) => {
  const [allProducts, setallProducts] = useState<ProductForTable[]>([]);
  const [filteredProducts, setfilteredProducts] = useState<ProductForTable[]>(
    []
  );
  const [selectedProducts, setselectedProducts] = useState<ProductForTable[]>(
    []
  );

  const maxProductsToAdd =
    MAX_PRODUCTS_IN_STOREWINDOW - storeWindowProducts.length;

  useEffect(() => {
    getOwnedProducts(userId).then((response) => {
      setallProducts(response);
    });
  }, [userId]);

  const getFilteredProducts = () => {
    return allProducts.filter(
      (product) =>
        !storeWindowProducts.some(
          (element, index, array) => element.id == product.id
        )
    );
  };

  useEffect(() => {
    setfilteredProducts(getFilteredProducts());
  }, [allProducts]);

  return (
    <Flex direction="column" mb={1}>
      <Heading>
        {i18next.t("storeWindows:form.addProductsDialog.title")}
      </Heading>
      <Box maxHeight="250px" overflowY="auto">
        <Table width="100%">
          <Tbody>
            {filteredProducts.map((item) => {
              return (
                <Tr key={item.id}>
                  <Td width="100px">
                    {item.photo ? (
                      <Image
                        objectFit="cover"
                        borderRadius="50%"
                        boxSize="50px"
                        src={environment.site + item.photo.url}
                        boxShadow="sm"
                      />
                    ) : (
                      <Circle boxSize="50px" bg="lightGrey" boxShadow="sm">
                        <ImageIcon boxSize="20px" />
                      </Circle>
                    )}
                  </Td>
                  <Td>{item.name}</Td>
                  <Td width="50px">
                    <Checkbox
                      onChange={(e) => {
                        e.target.checked
                          ? setselectedProducts(selectedProducts.concat(item))
                          : setselectedProducts(
                              selectedProducts.filter(
                                (product) => product.id !== item.id
                              )
                            );
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      {selectedProducts.length > maxProductsToAdd && (
        <Box
          mt={2}
          color="brandRed.300"
        >{`Maximal ${MAX_PRODUCTS_IN_STOREWINDOW} Produkte pro Schaufenster! Sie dürfen ${maxProductsToAdd} Produkte hinzufügen, haben aber ${selectedProducts.length} Produkte ausgewählt.`}</Box>
      )}
      <Flex mt={4}>
        <Button onClick={onAbort} width="100px">
          {i18next.t("storeWindows:form.addProductsDialog.abort")}
        </Button>
        <Button
          onClick={() => {
            if (selectedProducts.length <= maxProductsToAdd) {
              onAdd(selectedProducts);
            }
          }}
          ml="auto"
          width="100px"
        >
          {i18next.t("storeWindows:form.addProductsDialog.add")}
        </Button>
      </Flex>
    </Flex>
  );
};
