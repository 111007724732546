import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  RadioGroup,
  CheckboxProps,
} from "@chakra-ui/react";
import {
  DEFAULT_LABEL_WIDTH,
  FieldInternalProps,
  GenericInput,
} from "app/shared/forms/GenericInput";
import { GenericCheckbox } from "app/shared/forms/GenericCheckbox";
import { StyledInput } from "app/shared/forms/StyledComponents";
import { GoogleSearchBox } from "app/shared/maps/GoogleSearchBox";
import { Coords } from "app/shared/maps/models";
import { Field } from "formik";
import i18next from "i18next";
import React, { useState, useEffect } from "react";
import { LOCAL_DELIVERY, NATIONAL_DELIVERY } from "app/shared/models";
import { StyledRadio } from "app/shared/forms/StyledRadio";

export interface FieldProps {
  fieldName: string;
  fieldType: string;
  Component: any;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
  flexDirection?: string;
}

export const StoreFormField: React.FC<FieldProps> = (props: FieldProps) => {
  return <GenericInput i18nextPrefix="stores" {...props} />;
};

export interface CheckboxFieldProps extends CheckboxProps {
  fieldName: string;
  fieldType: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
}

export const StoreCheckboxField: React.FC<CheckboxFieldProps> = (props) => {
  return <GenericCheckbox i18nextPrefix="stores" {...props} />;
};

export interface TimeFieldProps {
  fieldName: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
  flexDirection?: string;
}

export const StoreTimeField: React.FC<TimeFieldProps> = (
  props: TimeFieldProps
) => {
  const { fieldName, hideLabel, flexDirection } = props;
  const disabled = props.disabled || false;
  const labelWidthPx = props.labelWidthPx || DEFAULT_LABEL_WIDTH;

  return (
    <Flex
      mt={3}
      flexDirection={
        flexDirection ? (flexDirection === "row" ? "row" : "column") : "row"
      }
      mb={flexDirection ? (flexDirection === "row" ? "0" : "6") : "0"}
    >
      {!hideLabel && (
        <Box width={labelWidthPx}>
          <FormLabel htmlFor={fieldName}>
            {i18next.t(`stores:form.fields.openingHours.${fieldName}`)}
          </FormLabel>
        </Box>
      )}
      <Box flex="1">
        <Flex>
          <FormLabel>
            {i18next.t(`stores:form.fields.openingHours.from`)}
          </FormLabel>
          <Field
            className="block"
            name={"openingHours." + fieldName + ".firstStart"}
            type="input"
          >
            {(renderProps: FieldInternalProps) => {
              const errors =
                renderProps.form.errors["openingHours"]?.[fieldName]
                  ?.firstStart;
              const touched =
                renderProps.form.touched["openingHours"]?.[fieldName]
                  ?.firstStart;

              return (
                <FormControl isInvalid={errors && touched}>
                  <StyledInput
                    type="input"
                    {...renderProps.field}
                    id={"openingHours." + fieldName + ".firstStart"}
                    disabled={disabled}
                    fontSize="lg"
                    px={3}
                    py={2}
                  />
                  {errors && touched ? (
                    <Box color="brandRed.300">{errors}</Box>
                  ) : null}
                </FormControl>
              );
            }}
          </Field>

          <FormLabel ml={2}>
            {i18next.t(`stores:form.fields.openingHours.to`)}
          </FormLabel>

          <Field
            className="block"
            name={"openingHours." + fieldName + ".firstEnd"}
            type="input"
          >
            {(renderProps: FieldInternalProps) => {
              const errors =
                renderProps.form.errors["openingHours"]?.[fieldName]?.firstEnd;
              const touched =
                renderProps.form.touched["openingHours"]?.[fieldName]?.firstEnd;

              return (
                <FormControl isInvalid={errors && touched}>
                  <StyledInput
                    type="input"
                    {...renderProps.field}
                    id={"openingHours." + fieldName + ".firstEnd"}
                    disabled={disabled}
                    fontSize="lg"
                    px={3}
                    py={2}
                  />
                  {errors && touched ? (
                    <Box color="brandRed.300">{errors}</Box>
                  ) : null}
                </FormControl>
              );
            }}
          </Field>
          <FormLabel ml={2}>
            {i18next.t(`stores:form.fields.openingHours.clock`)}
          </FormLabel>
        </Flex>
        <Flex mt={2}>
          <FormLabel>
            {i18next.t(`stores:form.fields.openingHours.from`)}
          </FormLabel>
          <Field
            className="block"
            name={"openingHours." + fieldName + ".secondStart"}
            type="input"
          >
            {(renderProps: FieldInternalProps) => {
              const errors =
                renderProps.form.errors["openingHours"]?.[fieldName]
                  ?.secondStart;
              const touched =
                renderProps.form.touched["openingHours"]?.[fieldName]
                  ?.secondStart;

              return (
                <FormControl isInvalid={errors && touched}>
                  <StyledInput
                    type="input"
                    {...renderProps.field}
                    id={"openingHours." + fieldName + ".secondStart"}
                    disabled={disabled}
                    fontSize="lg"
                    px={3}
                    py={2}
                  />
                  {errors && touched ? (
                    <Box color="brandRed.300">{errors}</Box>
                  ) : null}
                </FormControl>
              );
            }}
          </Field>

          <FormLabel ml={2}>
            {i18next.t(`stores:form.fields.openingHours.to`)}
          </FormLabel>

          <Field
            className="block"
            name={"openingHours." + fieldName + ".secondEnd"}
            type="input"
          >
            {(renderProps: FieldInternalProps) => {
              const errors =
                renderProps.form.errors["openingHours"]?.[fieldName]?.secondEnd;
              const touched =
                renderProps.form.touched["openingHours"]?.[fieldName]
                  ?.secondEnd;

              return (
                <FormControl isInvalid={errors && touched}>
                  <StyledInput
                    type="input"
                    {...renderProps.field}
                    id={"openingHours." + fieldName + ".secondEnd"}
                    disabled={disabled}
                    fontSize="lg"
                    px={3}
                    py={2}
                  />
                  {errors && touched ? (
                    <Box color="brandRed.300">{errors}</Box>
                  ) : null}
                </FormControl>
              );
            }}
          </Field>
          <FormLabel ml={2}>
            {i18next.t(`stores:form.fields.openingHours.clock`)}
          </FormLabel>
        </Flex>
      </Box>
    </Flex>
  );
};

export interface GooglePlacesSearchFieldProps {
  fieldName: string;
  error?: string;
  initialValue?: string;
  touched?: boolean;
  onChange: (coords: Coords, place: any) => void;
  googleMapsApi: any;
  flexDirection?: string;
}

export const GooglePlacesSearchField: React.FC<GooglePlacesSearchFieldProps> = (
  props
) => {
  return (
    <FormControl isInvalid={(props.error && props.touched) || false}>
      <Flex
        mt={3}
        mb={
          props.flexDirection
            ? props.flexDirection === "row"
              ? "0"
              : "6"
            : "0"
        }
        flexDirection={
          props.flexDirection
            ? props.flexDirection === "row"
              ? "row"
              : "column"
            : "row"
        }
      >
        <Box width={DEFAULT_LABEL_WIDTH}>
          <FormLabel htmlFor={props.fieldName}>
            {i18next.t(`stores:form.fields.${props.fieldName}.label`)}
          </FormLabel>
        </Box>
        <Box flex="1">
          <GoogleSearchBox
            initialValue={props.initialValue}
            onChange={props.onChange}
            maps={props.googleMapsApi}
          />
        </Box>
      </Flex>
    </FormControl>
  );
};

interface DeliveryTypeProps {
  deliveryTypeFieldName: string;
  localDeliveryRadiusKmFieldName: string;
  isLocalDelivery?: boolean;
  disabled?: boolean;
}
export const DeliveryTypeAndRadiusField: React.FC<DeliveryTypeProps> = (
  props
) => {
  const [localDelivery, setLocalDelivery] = useState<boolean>(false);
  const { deliveryTypeFieldName, localDeliveryRadiusKmFieldName } = props;
  const disabled = props.disabled ?? false;

  useEffect(() => {
    setLocalDelivery(props.isLocalDelivery ?? false);
  }, [props.isLocalDelivery]);

  return (
    <Flex px={3} py={2} alignItems="center">
      <Box pr={4}>
        <Field className="block" name={deliveryTypeFieldName} type="string">
          {(renderProps: FieldInternalProps) => (
            <FormControl
              isInvalid={
                renderProps.form.errors[deliveryTypeFieldName] &&
                renderProps.form.touched[deliveryTypeFieldName]
              }
            >
              <RadioGroup
                isInline
                onChange={(e) => {
                  setLocalDelivery(e === LOCAL_DELIVERY);
                  renderProps.form.setFieldValue(deliveryTypeFieldName, e);
                  renderProps.form.setFieldTouched(deliveryTypeFieldName, true);
                }}
                value={renderProps.field.value}
              >
                <StyledRadio
                  value={NATIONAL_DELIVERY}
                  isDisabled={disabled}
                  pr={8}
                >
                  {i18next.t(
                    `stores:form.fields.${deliveryTypeFieldName}.options.${NATIONAL_DELIVERY}`
                  )}
                </StyledRadio>
                <StyledRadio value={LOCAL_DELIVERY} isDisabled={disabled}>
                  {i18next.t(
                    `stores:form.fields.${deliveryTypeFieldName}.options.${LOCAL_DELIVERY}`
                  )}
                </StyledRadio>
              </RadioGroup>
              <FormErrorMessage>
                {renderProps.form.errors[deliveryTypeFieldName]}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Box>
      <Box>
        <Field
          className="block"
          name={localDeliveryRadiusKmFieldName}
          type="number"
        >
          {(renderProps: FieldInternalProps) => (
            <FormControl
              isInvalid={
                renderProps.form.errors[localDeliveryRadiusKmFieldName] &&
                renderProps.form.touched[localDeliveryRadiusKmFieldName]
              }
            >
              <Flex alignItems="center">
                <Box width="64px">
                  <StyledInput
                    {...renderProps.field}
                    id={deliveryTypeFieldName}
                    placeholder={i18next.t(
                      `stores:form.fields.${localDeliveryRadiusKmFieldName}.placeholder`
                    )}
                    disabled={disabled || !localDelivery}
                    fontSize="lg"
                    px={3}
                    py={2}
                  />
                </Box>
                <Box width="100px" ml={1}>
                  <FormLabel htmlFor={localDeliveryRadiusKmFieldName}>
                    {i18next.t(
                      `stores:form.fields.${localDeliveryRadiusKmFieldName}.label`
                    )}
                  </FormLabel>
                </Box>
              </Flex>
            </FormControl>
          )}
        </Field>
      </Box>
    </Flex>
  );
};
