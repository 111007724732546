import { StarIcon } from "@chakra-ui/icons";
import { BoxProps, Box, Stack } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { MAX_RATING, Ratings } from "./constants";

interface Props extends BoxProps {
  rating: Ratings;
  activeColor: string;
  inactiveColor: string;
  setRating?: (rating: Ratings) => void;
  favCount?: string
}

const buildStarComponents = (props: Props): ReactNode[] => {
  const componentArray: ReactNode[] = [];
  const { rating, activeColor, inactiveColor, setRating, favCount } = props;

  const curFavCount = favCount ? favCount : "0";

  for (let i = 0; i < MAX_RATING; i++) {
    componentArray.push(
      <Box
        key={`star_${i}`}
        onClick={setRating ? () => setRating((i + 1) as any) : undefined}
        _hover={setRating ? { cursor: "pointer" } : undefined}>
        <StarIcon color={i < +rating.toString().replace(',','.') ? activeColor : inactiveColor} />
      </Box>
    );
  }
  //componentArray.push(
  //  <Box key={ "count" } color="black">
  //    {"(" + curFavCount  + ")" }
  //  </Box>
  //);

  return componentArray;
};

export const Rating: React.FC<Props> = ({
  rating,
  activeColor,
  inactiveColor,
  setRating,
  //favCount
  ...rest
}) => (
  <Stack direction="row" spacing={1} {...rest}>
      {buildStarComponents({ rating, activeColor, inactiveColor, setRating }) }
      
  </Stack>
);
