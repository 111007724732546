import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import { compose } from "recompose";
import { Stack, Skeleton, Button, Box } from "@chakra-ui/react";
import { model2ViewModel } from "./StoreWindowInfoSchema";
import { BaseStoreWindowForm } from "./BaseStoreWindowForm";
import { useStoreWindowRepositoryContext } from "../repositories";

interface Props {
  userId: string;
  storeWindowId: string;
}

const EditStoreWindowFormBase: React.FC<Props> = ({
  userId,
  storeWindowId,
}) => {
  const { storeWindowRepository } = useStoreWindowRepositoryContext();

  useEffect(() => {
    autorun(() => storeWindowRepository.fetchStoreWindow(storeWindowId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={20} direction="column">
      {storeWindowRepository.storeWindow.id ? (
        <Box>
          <Button variant="unstyled" width="215px" marginBottom="25px">
            <Link
              to={`/editStore/${storeWindowRepository.storeWindow.storeId}`}
            >
              zurück zum Geschäft
            </Link>
          </Button>
          <BaseStoreWindowForm
            userId={userId}
            initialState={model2ViewModel(storeWindowRepository.storeWindow)}
            storeWindowId={storeWindowRepository.storeWindow.id}
          />
        </Box>
      ) : (
        <Skeleton height="20px" my="10px" />
      )}
    </Stack>
  );
};

export const EditStoreWindowForm = compose<Props, Props>(observer)(
  EditStoreWindowFormBase
);
