import { Category, SubCategory } from "app/categories/models";
import { Coords } from "app/shared/maps/models";
import queryString from "query-string";
import { SearchParams } from "../models";

export const createSearchUrl = (
  categoryId?: string,
  subCategories?: SubCategory[],
  location?: Coords,
  zoom?: number,
  searchTerm?: string,
  googleSearch?: string,
  radiusKm?: number,
  instance?: string
): string => {
  const params: SearchParams = {};

  if (categoryId && categoryId !== "") {
    params.category = categoryId;
  }

  if (subCategories && subCategories.length) {
    params.subCategories = subCategories.map((subCategory) => subCategory.id);
  }

  if (location && location.lat !== undefined && location.lng !== undefined) {
    params.lat = location.lat;
    params.lng = location.lng;
    if (zoom ) {
      params.zoomLevel = zoom;
    }
  }

  if (radiusKm !== undefined) {
    params.radiusKm = radiusKm;
  }

  if (searchTerm != undefined && searchTerm.length > 0) {
    params.searchTerm = searchTerm;
  }
  if (googleSearch != undefined && googleSearch.length > 0) {
    params.googleSearch = googleSearch;
  }

  if (instance != undefined && instance.length > 0) {
    params.instance = instance;
  }

  return `/search?${queryString.stringify(params, { arrayFormat: "comma" })}`;
};

export const getSubCategoriesForParam = (
  category: string,
  categories: Category[]
): SubCategory[] =>
  categories.find((c) => c.id === category)?.subCategories ?? [];
