import { Box, BoxProps, Text, Stack, Icon } from "@chakra-ui/react";
import React, { useState } from "react";
import { CategoryCarousel } from "./CategoryCarousel";
import { SubCategoryPicker } from "./SubCategoryPicker";
import { useMediaQueryContext } from "styles/context";
import { useSearchRepositoryContext } from "../../search/repositories";
import { useRootRepositoryContext } from "rootRepository";
import { Select } from "app/shared";
import i18next from "i18next";
import { isArray } from "util";
import { createSearchUrl, getSubCategoriesForParam } from "../../search/utils";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";

interface Props extends BoxProps {
  selectedCategory: string;
  setSelectedCategory: (
    category: string,
    oldCatValue?: string,
    index?: number
  ) => void;
  selectedSubCategories: string[];
  showSelectAllSubcategories?: boolean;
  setSelectedSubCategories: (subCategories: string[]) => void;
  autoSelectSubCategories: boolean;
  hideOnSmall: boolean;
  index: number;
}

export const CategoryMultiSelect: React.FC<Props> = ({
  index,
  selectedCategory,
  setSelectedCategory,
  showSelectAllSubcategories,
  selectedSubCategories,
  setSelectedSubCategories,
  autoSelectSubCategories,
  width,
  hideOnSmall,
  ...rest
}) => {
  const { searchRepository } = useSearchRepositoryContext();
  const { categoryRepository } = useRootRepositoryContext();

  const [categoryId, setCategory] = useState<string>(selectedCategory);
  const [canUseSelectAllSubCategories, setCanUseSelectAllSubCategories] =
    useState<boolean>(false);
  const [canUseUnselectAllSubCategories, setCanUseUnselectAllSubCategories] =
    useState<boolean>(true);

  const { isSmall, isSmallMedium } = useMediaQueryContext();

  const [subCategoryId, setSubCategory] = useState<string>("");
  const [selectSubCategoryValue, setSelectSubCategoryValue] = useState<any>(-1);
  const [searchOptionsSubCategories, setSearchOptionsSubCategories] =
    useState<any>([]);

  const searchOptions = categoryRepository.categories
    .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
    .map((category) => ({
      value: category.id,
      label: category.categoryName,
    }));

  const defaultOption = { value: "", label: "Subkategorie wählen?" };

  const handleOnCategoryRemoved = () => {
    setSelectedCategory(
      "",
      categoryId && categoryId.length > 0 ? categoryId : selectedCategory,
      index
    );
    setCategory("");

    const subCategoriesIds: string[] =
      categoryRepository.categories
        .find((c) => c.id === categoryId)
        ?.subCategories.map((subCategory) => subCategory.id) ?? [];
    let subCatsToReomove = "";
    for (let i = 0; i < subCategoriesIds.length; i++) {
      subCatsToReomove += subCategoriesIds[i] + ";";
    }

    setSelectedSubCategories(
      subCategoriesIds.filter(
        (subCategoryId) => subCatsToReomove.indexOf(subCategoryId) === -1
      )
    );

    setCanUseSelectAllSubCategories(false);
    setCanUseUnselectAllSubCategories(true);

    // setzen der Subkategorie in mobilder Ansicht
    setSelectSubCategoryValue(null);

    searchRepository?.setCurrentPage(1);
    searchRepository?.fetchStoresForPage();
  };

  const handleSelectAllSubCategories = () => {
    const subCategoriesIds: string[] =
      categoryRepository.categories
        .find((c) => c.id === categoryId)
        ?.subCategories.map((subCategory) => subCategory.id) ?? [];
    setSelectedSubCategories(subCategoriesIds);
    setCanUseSelectAllSubCategories(false);
    setCanUseUnselectAllSubCategories(true);

    searchRepository?.setCurrentPage(1);
    searchRepository?.fetchStoresForPage();
  };

  const handleUnselectAllSubCategories = () => {
    setSelectedSubCategories([]);
    setCanUseSelectAllSubCategories(true);
    setCanUseUnselectAllSubCategories(false);

    searchRepository?.setCurrentPage(1);
    searchRepository?.fetchStoresForPage();
  };

  const handleOnCategorySelected = (categoryId: string) => {
    setCategory(categoryId);
    setSelectedCategory(categoryId, "", index);

    const subCategoriesIds: string[] = autoSelectSubCategories
      ? categoryRepository.categories
          .find((c) => c.id === categoryId)
          ?.subCategories.map((subCategory) => subCategory.id) ?? []
      : [];

    setSelectedSubCategories(subCategoriesIds);
    // Laden der Subkategorien, mit ausgewähltem Defaultwert
    loadSubCategories(categoryId, defaultOption);

    searchRepository?.setCurrentPage(1);
    searchRepository?.fetchStoresForPage();
  };

  // Lädt die passenden Subkategorien zur Kategorie (Dropdown in mobiler Ansicht).
  const loadSubCategories = (
    selectedCategoryId: any,
    selectedSubCatId: any
  ) => {
    const s = getSubCategoriesForParam(
      selectedCategoryId,
      categoryRepository.categories
    )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((category) => ({
        value: category.id,
        label: category.name,
      }));
    setSearchOptionsSubCategories(s);

    setSelectSubCategoryValue(selectedSubCatId);
  };

  // normale Funktionalität beim Wechsel der Kategorie muss erweitern werden,
  // damit Auswahl(Subkategorien) in mobiler Ansicht auch aktualisiert wird
  const extendOnCategorySelected = (
    category: string,
    oldCatValue?: string,
    index?: number
  ) => {
    setSelectedCategory(category, oldCatValue, index);
    loadSubCategories(category, defaultOption);
  };

  // Laden der Subkategorien (für Dropdown in mobiler Asicht),
  // aber nur wenn initial noch keine Kategorie ausgewählt
  // und eine Kategorie bestimmt wurde
  // Wenn keine Subkategorie gewählt ist, wird die Standard-Option angezeigt
  if (
    selectSubCategoryValue == -1 &&
    selectedCategory &&
    categoryRepository.categories.length > 0 &&
    selectedSubCategories
  ) {
    const selCat = categoryRepository.categories?.filter(
      (o) => o.id === selectedCategory
    )[0];
    if (selCat) {
      if (selectedSubCategories.length === 1) {
        const selSubCat = selCat.subCategories.filter(
          (sc) => sc.id === selectedSubCategories[0]
        )[0];
        const option = { value: selSubCat.id, label: selSubCat.name };
        loadSubCategories(selectedCategory, option);
      } else {
        loadSubCategories(selectedCategory, defaultOption);
      }
    }
  }

  return (
    <Box w={width ?? "600px"} {...rest}>
      {!hideOnSmall || !isSmallMedium ? (
        selectedCategory ? (
          <Box>
            {showSelectAllSubcategories != null &&
              showSelectAllSubcategories != undefined &&
              showSelectAllSubcategories == true && (
                <Stack
                  mb="15px"
                  direction="row"
                  pl={[0, 0, 0, 12, 12]}
                  justifyContent="flex-end"
                >
                  <Stack
                    direction="row"
                    onClick={handleSelectAllSubCategories}
                    cursor="pointer"
                  >
                    <Box height="18px" mr="2px">
                      <AddIcon
                        color={
                          canUseSelectAllSubCategories
                            ? "brandRed.300"
                            : "mdGrey"
                        }
                        boxSize={"18px"}
                      />
                    </Box>
                    <Text
                      textAlign="center"
                      color={
                        canUseSelectAllSubCategories ? "brandRed.300" : "mdGrey"
                      }
                      fontSize="1xl"
                      mr="20px"
                      fontWeight="bold"
                    >
                      {i18next.t(
                        "search:searchInputsGroup.selectSubCategoriesAll"
                      )}
                    </Text>
                  </Stack>
                  <Stack
                    direction="row"
                    ml="20px"
                    onClick={handleUnselectAllSubCategories}
                    cursor="pointer"
                  >
                    <Box height="18px" mr="2px">
                      <CloseIcon
                        color={
                          canUseUnselectAllSubCategories
                            ? "brandRed.300"
                            : "mdGrey"
                        }
                        boxSize={"18px"}
                      />
                    </Box>
                    <Text
                      textAlign="center"
                      color={
                        canUseUnselectAllSubCategories
                          ? "brandRed.300"
                          : "mdGrey"
                      }
                      fontSize="1xl"
                      fontWeight="bold"
                    >
                      {i18next.t(
                        "search:searchInputsGroup.selectSubCategoriesNone"
                      )}
                    </Text>
                  </Stack>
                </Stack>
              )}
            <SubCategoryPicker
              category={selectedCategory}
              selectedSubCategories={selectedSubCategories}
              onCategoryRemoved={handleOnCategoryRemoved}
              onSubCategorySelected={(subCategories: string[]) => {
                setSelectedSubCategories(subCategories);
                const cat = categoryRepository.categories.find(
                  (c) => c.id === categoryId
                );
                if (cat) {
                  setCanUseSelectAllSubCategories(
                    subCategories.length < cat.subCategories.length
                  );
                  setCanUseUnselectAllSubCategories(subCategories.length > 0);

                  if (subCategories.length === 1) {
                    setSelectSubCategoryValue({
                      value: subCategories[0],
                      label: cat.subCategories.filter(
                        (sc) => sc.id === subCategories[0]
                      )[0].name,
                    });
                  } else {
                    setSelectSubCategoryValue(null);
                  }
                }
              }}
            />
          </Box>
        ) : (
          <CategoryCarousel
            index={index}
            onCategorySelected={extendOnCategorySelected}
            onSubCategorySelected={setSelectedSubCategories}
            autoSelectSubCategories={autoSelectSubCategories}
            onCategorySelectForDropdown={setCategory}
          />
        )
      ) : (
        <Box width={["100%", "100%", "100%", "100%", "100%"]} mb={[0, 0, 4]}>
          <Select
            onChange={(options) => {
              handleOnCategorySelected(options.value);
            }}
            options={[
              { value: "", label: "Alle Kategorien" },
              ...searchOptions,
            ]}
            value={searchOptions.filter((o) => o.value === categoryId)}
            placeholder={i18next.t(
              "search:searchInputsGroup.selectPlaceholder"
            )}
          />
          <Box height="0.5rem"></Box>
          <Select
            disabled={categoryId && searchOptionsSubCategories ? false : true}
            onChange={(options) => {
              setSubCategory(options.value);
              setSelectSubCategoryValue(options);
              setSelectedSubCategories([options.value]);
              searchRepository?.setCurrentPage(1);
              searchRepository?.fetchStoresForPage();
            }}
            value={selectSubCategoryValue}
            options={[
              {
                value: "",
                label: i18next.t("search:searchInputsGroup.selectPlaceholder2"),
              },
              ...searchOptionsSubCategories,
            ]}
            width={80}
            placeholder={i18next.t(
              "search:searchInputsGroup.selectPlaceholder2"
            )}
          />
        </Box>
      )}
    </Box>
  );
};
