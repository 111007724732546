import { Flex, Stack, BoxProps } from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { LoginMenu } from "../loginMenu";
import { DrawerMenuLink } from "./DrawerMenuLink";
//import { useRootRepositoryContext } from "../../../../rootRepository";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { compose } from "recompose";
import { useRootRepositoryContext } from "rootRepository";
import { LoginIcon } from "styles/icons";

interface ExternalProps extends BoxProps {
  onClose?: () => void;
}

interface Props extends ExternalProps, RouteComponentProps {}

const MobileDrawerMenuContentBase: React.FC<Props> = ({ history, onClose }) => {
  const { userRepository } = useRootRepositoryContext();
  const { cityContentRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  const visiblePageList =
    cityContentRepository.GetAppSettingEntry("_sysBurgerNavItems");
  const navContentInfo: any = {
    forStore: false,
    login: false,
    storeSearch: false,
    storeCreate: false,
    forUsers: false,
    about: false,
    user: false,
    logout: false,
    news: false,
    contact: false,
  };

  if (visiblePageList) {
    const linkList: [] = visiblePageList.split(";");
    for (let i = 0; i < linkList.length; i++) {
      const value = (linkList[i] as string).toString().trim();
      if (navContentInfo[value] !== undefined) {
        navContentInfo[value] = true;
      }
    }
  }

  return (
    <Stack
      direction="column"
      py={8}
      justifyContent="center"
      alignItems="space-between"
      maxWidth="250px"
    >
      {navContentInfo?.login &&
        (user ? (
          <DrawerMenuLink
            href="/user"
            name={i18next.t("shared:navbar.burgerDrawer.user")}
            onClick={onClose}
            iconVisible={true}
          />
        ) : (
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <LoginMenu color="navbar.drawer.color" onClose={onClose} />
          </Flex>
        ))}
      {navContentInfo?.storeSearch && (
        <DrawerMenuLink
          href="/search"
          name={i18next.t("shared:navbar.burgerDrawer.storeSearch")}
          onClick={onClose}
          iconVisible={true}
        />
      )}
      {navContentInfo?.storeCreate && (
        <DrawerMenuLink
          href="/newUserAndStore"
          name={i18next.t("shared:navbar.burgerDrawer.storeCreate")}
          onClick={onClose}
          iconVisible={true}
        />
      )}
      {navContentInfo?.forStore && (
        <DrawerMenuLink
          href="/fur-geschafte"
          name={i18next.t("shared:navbar.burgerDrawer.forStore")}
          onClick={onClose}
          iconVisible={true}
        />
      )}
      {navContentInfo?.forUsers && (
        <DrawerMenuLink
          href="/fur-nutzer"
          name={i18next.t("shared:navbar.burgerDrawer.forUsers")}
          onClick={onClose}
          iconVisible={true}
        />
      )}
      {navContentInfo?.about && (
        <DrawerMenuLink
          href="/about"
          name={i18next.t("shared:navbar.burgerDrawer.about")}
          onClick={onClose}
          iconVisible={true}
        />
      )}
      {navContentInfo?.news && (
        <DrawerMenuLink
          href="/news"
          name={i18next.t("shared:navbar.burgerDrawer.news")}
          onClick={onClose}
          iconVisible={true}
        />
      )}
      {navContentInfo?.contact && (
        <DrawerMenuLink
          href="/kontakt"
          name={i18next.t("shared:navbar.burgerDrawer.contact")}
          onClick={onClose}
          iconVisible={true}
        />
      )}

      {navContentInfo?.logout && user && (
        <DrawerMenuLink
          onClick={() => {
            userRepository.logout();
            if (onClose) onClose();
            history.push("/");
          }}
          name={i18next.t("shared:navbar.burgerDrawer.logout")}
          iconVisible={true}
        />
      )}
    </Stack>
  );
};

export const MobileDrawerMenuContent = compose<Props, ExternalProps>(
  withRouter,
  observer
)(MobileDrawerMenuContentBase);
