import { Text, Box, Link } from "@chakra-ui/react";
import { ContentArea, Headline, SocialInfo } from "../app/shared";
import { StaticPageTextBlock } from "../app/staticPages/StaticPageTextBlock";
import React from "react";
import i18next from "i18next";
import useScript from "../utils/useScript";

const ImpressumPage: React.FC = () => {

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'Impressum', section: 'Impressum', pubdate: '', author: '', url: '/Impressum', object_type: 'Impressum' }}); } ");

  return (
    <ContentArea>
      <Headline as="h1">{i18next.t("impressum:title")}</Headline>
      <Box mb="20px">
        <Box>
          <Text fontSize="md" color="darkGrey" fontWeight="bold">
            {i18next.t("impressum:section0.line1")}
          </Text>
        </Box>
      </Box>
      <Box mb="20px">
        <Box mb="15px">
          <Text fontSize="lg" color="darkGrey" fontWeight="bold" >
            {i18next.t("impressum:section1.title")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section1.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section1.line2")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section1.line3")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section1.line4")}
          </Text>
        </Box>
        <Box>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section1.line5")}
          </Text>
          <Text fontSize="md" color="darkGrey" fontWeight="bold">
            {i18next.t("impressum:section1.line6")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section1.line7")}
          </Text>
        </Box>
      </Box>
      <Box mb="20px">
        <Box mb="20px">
          <Text fontSize="lg" color="darkGrey" fontWeight="bold" >
            {i18next.t("impressum:section2.title")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section2.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section2.line2")}
            <Link fontSize="md" color="darkGrey" href="mailTo:info@wir-liefern.org">
              {i18next.t("impressum:section2.mail")}
            </Link>
          </Text>
        </Box>
      </Box>
      <Box mb="20px">
        <Box mb="20px">
          <Text fontSize="lg" color="darkGrey" fontWeight="bold" >
            {i18next.t("impressum:section3.title")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section3.line1")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section3.line2")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section3.line3")}
          </Text>
        </Box>
      </Box>
      <Box mb="20px">
        <Box mb="20px">
          <Text fontSize="lg" color="darkGrey" fontWeight="bold" >
            {i18next.t("impressum:section4.title")}
          </Text>
          <StaticPageTextBlock fontSize="md" color="darkGrey" mb="15px">
            {i18next.t("impressum:section4.line1")}
            <Link fontSize="md" color="darkGrey" href="https://ec.europa.eu/consumers/odr" isExternal>
              {i18next.t("impressum:section4.linkText1")}
            </Link>
            {i18next.t("impressum:section4.line1Rest")}
          </StaticPageTextBlock>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section4.line2")}
          </Text>

        </Box>
      </Box>
      <Box mb="20px">
        <Box mb="20px">
          <Text fontSize="lg" color="darkGrey" fontWeight="bold" >
            {i18next.t("impressum:section5.title")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section5.line1")}
          </Text>
        </Box>
      </Box>
      <Box mb="20px">
        <Box mb="20px">
          <Text fontSize="lg" color="darkGrey" fontWeight="bold" >
            {i18next.t("impressum:section6.title")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section6.line1")}
          </Text>
        </Box>
      </Box>
      <Box mb="20px">
        <Box mb="20px">
          <Text fontSize="lg" color="darkGrey" fontWeight="bold" >
            {i18next.t("impressum:section7.title")}
          </Text>
          <Link fontSize="md" href="https://www.freepik.com/free-photos-vectors/design" color="brandRed.300" fontWeight="bold" isExternal>
            {i18next.t("impressum:section7.linkText1")}
          </Link>

        </Box>
      </Box>
      <Box mb="20px">
        <Box mb="20px">
          <Text fontSize="lg" color="darkGrey" fontWeight="bold" >
            {i18next.t("impressum:section8.title")}
          </Text>
          <Text fontSize="md" color="darkGrey">
            {i18next.t("impressum:section8.line1")}
            <Link href="https://www.digital-bauhaus.de/" color="brandRed.300" fontWeight="bold" isExternal>
              {i18next.t("impressum:section8.linkText1")}
            </Link>

          </Text>
        </Box>
      </Box>
      <SocialInfo header={"shared:socialInfo.header"} />
    </ContentArea>
  )
};

export default ImpressumPage;
