import { Flex, Text } from "@chakra-ui/react";
import { PaginatedStores, StoreForList } from "app/stores/models";
import { observer } from "mobx-react";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { compose } from "recompose";
import { useSearchRepositoryContext } from "../repositories";
import { MapResults } from "./MapResults";
import { StoresSearchResults } from "./StoresSearchResults";
import { useMediaQueryContext } from "styles/context";
import { Sort } from "../searchHeader/Sort";

interface Props {
  maps: any;
  setGoogleMapsApi: (maps: any) => void;
}

const SearchResultsBase: React.FC<Props> = ({ maps, setGoogleMapsApi }) => {
  const { searchRepository } = useSearchRepositoryContext();
  const { lat, lng, zoom, fetchStoresForPage } = searchRepository;
  const [searchResultsPage, setSearchResultsPage] = useState<PaginatedStores>();
  const [hoveredStore, setHoveredStore] = useState<StoreForList | undefined>(
    undefined
  );

  const {
    category,
    subCategories,
    radiusKm,
    sort,
    setCategory,
    setSubCategories,
    setLat,
    setLng,
    setRadiusKm,
    setSort,
    paginatedStores
  } = searchRepository;

  const { isSmallMedium } = useMediaQueryContext();

  useEffect(() => {
    fetchStoresForPage().then(() => {
      setSearchResultsPage(searchRepository.paginatedStores);
    });

  }, []);

  const onCardMouseEnter = (store: StoreForList) => {
    setHoveredStore(store);
  };

  const onCardMouseLeave = () => {
    setHoveredStore(undefined);
  };


  return (

    <Flex
      direction={[
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "row",
      ]}
      justifyContent="space-between">
      
      <StoresSearchResults
        searchResultsPage={searchResultsPage}
        onCardMouseEnter={onCardMouseEnter}
        onCardMouseLeave={onCardMouseLeave}
      />
      
        <MapResults
          centerLat={lat}
          centerLng={lng}
          zoom={zoom}
          stores={searchRepository.storesForMap ?? []}
          setGoogleMapsApi={setGoogleMapsApi}
          hoveredStore={hoveredStore}
          hideMap={isSmallMedium}
        />
    </Flex>
  );
};

export const SearchResults = compose<Props, Props>(observer)(SearchResultsBase);
