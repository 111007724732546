import React from "react";
import {
  CheckboxProps,
  Box,
  Text,
  FormControl,
  Input,
  Button,
  Center,
  AspectRatio,
  Image,
  Circle,
} from "@chakra-ui/react";
import { Field } from "formik";
import i18next from "i18next";
import { GenericInput } from "app/shared/forms/GenericInput";
import { GenericCheckbox } from "app/shared/forms/GenericCheckbox";
import { ImageUploaderProps } from "app/shared/forms/imageUploader";
import {
  FieldInternalProps,
  DEFAULT_LABEL_WIDTH,
} from "app/shared/forms/GenericInput";
import { AddIcon } from "@chakra-ui/icons";
import { MixedPhoto } from "app/shared/models";
import { DEFAULT_RATIO } from "app/shared/constants";
import { isPhoto } from "app/shared/utils/PhotoTypeChecker";
import environment from "configurations";
import { CloseIcon } from "styles/icons";

interface InputFieldProps {
  fieldName: string;
  fieldType: string;
  Component: any;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
  flexDirection?: string;
}

export const StoreWindowInputField: React.FC<InputFieldProps> = (
  props: InputFieldProps
) => {
  return <GenericInput i18nextPrefix="storeWindows" {...props} />;
};

interface CheckboxFieldProps extends CheckboxProps {
  fieldName: string;
  fieldType: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
}

export const StoreWindowCheckboxField: React.FC<CheckboxFieldProps> = (
  props
) => {
  return <GenericCheckbox i18nextPrefix="storeWindows" {...props} />;
};

export const ImageUploaderField: React.FC<ImageUploaderProps> = (props) => {
  const { fieldName, parentForm } = props;
  const photo = parentForm.values.photo as MixedPhoto;
  const url = !photo
    ? ""
    : isPhoto(photo)
    ? environment.site + photo.url
    : URL.createObjectURL(photo);
  let hiddenInput: HTMLInputElement | undefined = undefined;

  const onPictureUpload = (e: any) => {
    const newFile = e.currentTarget.files[0] as File;
    if (newFile !== undefined) {
      parentForm.setFieldTouched(fieldName, true);
      parentForm.setFieldValue(fieldName, newFile);
    }
  };

  const onPictureDelete = () => {
    parentForm.setFieldTouched(fieldName, true);
    parentForm.setFieldValue(fieldName, null);
  };

  return (
    <Box mt={3}>
      <Box d="flex" flexDirection="row">
        <Text width={DEFAULT_LABEL_WIDTH}>
          {i18next.t(`storeWindows:form.fields.${fieldName}.label`)}
        </Text>
        <Box>
          <Field className="block" name={fieldName} type="file">
            {(renderProps: FieldInternalProps) => (
              <FormControl
                isInvalid={
                  renderProps.form.errors[fieldName] &&
                  renderProps.form.touched[fieldName]
                }
              >
                <AspectRatio width="300px" ratio={DEFAULT_RATIO}>
                  {url ? (
                    <Box position="relative" _hover={{ cursor: "pointer" }}>
                      <Image
                        objectFit="contain"
                        width="100%"
                        height="100%"
                        src={url}
                        onClick={(e) => (
                          e.preventDefault(), hiddenInput && hiddenInput.click()
                        )}
                      />
                      <Circle
                        position="absolute"
                        top={5}
                        right={6}
                        boxSize={6}
                        bg="grey"
                        _hover={{ cursor: "pointer", bg: "darkGrey" }}
                      >
                        <CloseIcon
                          color="white"
                          boxSize={3}
                          onClick={onPictureDelete}
                        />
                      </Circle>
                    </Box>
                  ) : (
                    <Button
                      bg="lightestGrey"
                      p={2}
                      alignItems="stretch"
                      justifyContent="stretch"
                      onClick={(e) => (
                        e.preventDefault(), hiddenInput && hiddenInput.click()
                      )}
                    >
                      <Center
                        bg="transparent"
                        border={2}
                        borderColor="Grey"
                        borderStyle="dashed"
                        width="100%"
                        height="100%"
                      >
                        <AddIcon color="Grey" boxSize={10} />
                      </Center>
                    </Button>
                  )}
                </AspectRatio>

                {parentForm?.errors[fieldName] &&
                parentForm.touched[fieldName] ? (
                  <Box color="brandRed.300">{parentForm.errors[fieldName]}</Box>
                ) : null}

                <Input
                  accept="image/png, image/jpeg, image/png"
                  hidden
                  type="file"
                  id={fieldName}
                  onChange={onPictureUpload}
                  ref={(el: HTMLInputElement) => (hiddenInput = el)}
                />
              </FormControl>
            )}
          </Field>
        </Box>
      </Box>
    </Box>
  );
};
