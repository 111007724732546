import React from "react";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Modal } from "../modal";
import { useRootRepositoryContext } from "rootRepository";
//import "./galleryView.css";

interface Items {
  original: string;
  thumbnail: string;
}

interface Props {
  items: Items[];
  open: boolean;
  onClose: () => void;
  index?: number;
  thumbnailsTop?: boolean;
}



export const GalleryView: React.FC<Props> = ({
  items,
  open,
  onClose,
  index,
  thumbnailsTop
}) => {
  const { cityContentRepository } = useRootRepositoryContext();
  let colorJson: any = cityContentRepository.colorJSON ?? {};
  let galleryColor = colorJson?.content?.store?.storeDetail?.storePhotos?.gallery?.color ?? "#eb5757";

  const style = `<style>
              .image-gallery-icon:hover {
                color: ${galleryColor};
              }
              .image-gallery-bullet.active,
              .image-gallery-bullet:hover {
                background: ${galleryColor} !important;
              }

              .image-gallery-thumbnail-image {
                object-fit: cover;
                height: 60px;
              }

              .image-gallery-thumbnail.active,
              .image-gallery-thumbnail:hover,
              .image-gallery-thumbnail:focus {
                outline: none;
                border: 4px solid ${galleryColor};
              }
            </style>`;

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <style dangerouslySetInnerHTML={{ __html: style }}></style>

        <ReactImageGallery
          showBullets={true}
          showFullscreenButton={false}
          items={items}
          startIndex={index}
          thumbnailPosition={thumbnailsTop ? "top" : "bottom"}
        />
      </>
    </Modal>
  )
};
