import { BoxProps, Heading } from "@chakra-ui/react";
import React from "react";

interface IHeading {
  /**
   * The size of the Heading.
   */
  size?: "2xl" | "xl" | "lg" | "md" | "sm" | "xs";
}

export const Headline: React.FC<BoxProps> = ({ children, ...rest }) => (
 
  <Heading
    fontWeight="bold"
    fontSize={["3xl", "4xl", "7xl"]}
    color="content.text.headline"
    lineHeight={["base", "base", "base", "tall", "taller"]}
    {...rest}
    size='lg'
  >
    {children}
  </Heading>
 
);
