import React, { useContext } from "react";
import StoreWindowRepository from "./StoreWindowRepository";

interface RepositoryContextProps {
  storeWindowRepository: StoreWindowRepository;
}

export const StoreWindowRepositoryContext =
  React.createContext<RepositoryContextProps>({} as RepositoryContextProps);

export const useStoreWindowRepositoryContext = () =>
  useContext(StoreWindowRepositoryContext);
