import React from "react";

import { ProductRepositoryContext } from "./context";
import ProductRepository from "./ProductRepository";

interface Props {
  children: React.ReactNode;
}

export const ProductRepositoryProvider: React.FC<Props> = ({ children }) => {
  const productRepository = new ProductRepository();

  return (
    <ProductRepositoryContext.Provider value={{ productRepository }}>
      {children}
    </ProductRepositoryContext.Provider>
  );
};
