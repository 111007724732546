import axios from "../../../network";

import { StoreWindow, StoreWindowForList } from "../models/index";
import { StoreId } from "app/stores/models";
import { StoreWindowFormViewModel } from "../forms/StoreWindowInfoSchema";
import { Photo } from "app/shared/models";
import { ProductForTable } from "app/products/models";

export const getStoreWindowsFromStore = async (
  storeId: StoreId
): Promise<StoreWindowForList[]> => {
  return new Promise<StoreWindowForList[]>((resolve, reject) => {
    axios
      .get("/StoreWindows/SearchMyStoreWindows?storeId=" + storeId)
      .then(function (response) {
        try {
          const storeWindowData: StoreWindowForList[] = [];

          for (let i = 0; i < response.data.length; i++) {
            storeWindowData.push(
              getStoreWindowForListFromData(response.data[i])
            );
          }
          resolve(storeWindowData);
        } catch (r) {
          console.log("Error loading/parsing Store Window data");
        }
      })
      .catch(() => {
        console.log("Error loading/parsing Store Window data");
      });
  });
};

function getStoreWindowForListFromData(data: any): StoreWindowForList {
  return {
    id: data.id ?? "",
    photo: { id: data.photoId, url: data.photoUrl },
    name: data.name ?? "",
    visible: data.visible,
    storeId: data.storeId ?? "",
    countProducts: data.countProducts,
  };
}

export const getStoreWindow = async (
  storeWindowId: string
): Promise<StoreWindow> => {
  return new Promise<StoreWindow>((resolve, reject) => {
    axios
      .get("/StoreWindows/StoreWindow/" + storeWindowId)
      .then(function (response) {
        try {
          const storeWindow: StoreWindow = getStoreWindowFromData(
            response.data
          );
          resolve(storeWindow);
        } catch (r) {
          console.log("Error loading/parsing Store Window data");
        }
      })
      .catch(() => {
        console.log("Error loading/parsing Store Window data");
      });
  });
};

function getStoreWindowFromData(data: any): StoreWindow {
  const products = getProductsFromData(data);

  return {
    id: data.id ?? "",
    photo: { id: data.photoId, url: data.photoUrl },
    name: data.name ?? "",
    visible: data.visible,
    storeId: data.storeId ?? "",
    products: products,
  };
}

function getProductsFromData(data: any): ProductForTable[] {
  if (data === undefined || data === null) {
    return [];
  }

  if (data.products === undefined || data.products === null) {
    return [];
  }

  const products: ProductForTable[] = [];
  for (let i = 0; i < data.products.length; i++) {
    const product: ProductForTable = {
      id: data.products[i].id ?? "",
      photo: {
        id: data.products[i].mainPhotoId,
        url: data.products[i].mainPhotoUrl,
      },
      name: data.products[i].name ?? "",
      price:
        data.products[i].price.toFixed(2).toString().replace(".", ",") ?? 0,
      deliveryOptions: {
        canDeliver: data.products[i].canDeliver ?? false,
        canPickup: data.products[i].canPickup ?? false,
        deliveryDescription: data.products[i].deliveryDescription ?? "",
        deliveryType: data.products[i].deliveryType ?? undefined,
        localDeliveryRadiusKm: data.products[i].localDeliveryRadiusKm,
        pickupDescription: data.products[i].pickupDescription,
      },
      stock: data.stock ?? "",
    };
    products.push(product);
  }

  return products;
}

export const createStoreWindow = async (
  values: StoreWindowFormViewModel
): Promise<boolean> => {
  const { photo, products, ...jsonPayload } = values;

  return new Promise((resolve, reject) => {
    const data = new FormData();

    data.append("picture", photo as File);
    data.append("storeWindowInfo", JSON.stringify(jsonPayload));

    axios
      .post(`/StoreWindows/AddMyStoreWindow`, data)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response?.data?.Code == 999) {
          reject(response?.data?.Message);
        } else if (
          response?.status == 401 ||
          (response as any).response?.status == 401
        ) {
          reject(response);
        } else {
          updateStoreWindowProducts(response.data, products);
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const updateStoreWindow = async (
  values: StoreWindowFormViewModel
): Promise<boolean> => {
  const { photo, products, ...jsonPayload } = values;

  return new Promise((resolve, reject) => {
    const data = new FormData();

    data.append("storeWindowInfo", JSON.stringify(jsonPayload));
    if (photo instanceof File) {
      data.append("newPicture", photo as File);
    } else {
      data.append("picture", JSON.stringify(photo as Photo));
    }

    axios
      .post(`/StoreWindows/UpdateMyStoreWindow`, data)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response?.data?.Code == 999) {
          reject(response?.data?.Message);
        } else if (
          response?.status == 401 ||
          (response as any).response?.status == 401
        ) {
          reject(response);
        } else {
          updateStoreWindowProducts(jsonPayload.id, products);
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

const updateStoreWindowProducts = async (
  storeWindowId: string,
  products: ProductForTable[]
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const data = new FormData();

    data.append(
      "storeWindowProducts",
      JSON.stringify({ id: storeWindowId, products: products.map((p) => p.id) })
    );

    axios
      .post(`/StoreWindows/UpdateProductsForStoreWindow`, data)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response?.data?.Code == 999) {
          reject(response?.data?.Message);
        } else if (
          response?.status == 401 ||
          (response as any).response?.status == 401
        ) {
          reject(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const deleteStoreWindow = async (
  storeWindowId: string
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios
      .post("/StoreWindows/DeleteStoreWindow?id=" + storeWindowId)
      .then(function (response) {
        try {
          resolve(true);
        } catch (r) {
          console.log("Error loading/parsing Store Window data");
          reject(r);
        }
      })
      .catch(() => {
        console.log("Error loading/parsing Store Window data");
      });
  });
};
