import { Flex } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { compose } from "recompose";
import { useRootRepositoryContext } from "rootRepository";
import { Category } from "./Category";
import { CategorySearchController } from "./CategorySearchController";
import { CategorySearchHeader } from "./CategorySearchHeader";
import { useMediaQueryContext } from "styles/context";

const CategorySearchBase: React.FC = () => {
  const [selectedMetaCategories, setSelectedMetaCategories] = useState<
    string[]
  >([]);
  const { categoryRepository } = useRootRepositoryContext();
  const { isSmall, isSmallMedium } = useMediaQueryContext();

  return (
    <Flex
      direction="column"
      pr={[0, 0, 0, 0, "60px"]}
      alignItems={["center", "center", "center", "center", "unset"]}>
      <CategorySearchHeader />
      {
        !isSmallMedium ? (
          <CategorySearchController
            value={selectedMetaCategories}
            onChange={setSelectedMetaCategories}>
            {
              categoryRepository.categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName))
                .map((category, index) => {
                  let subCats = category.subCategories;
                  let count = 0;
                  for (let i = 0; i < subCats.length; i++) {
                    count += subCats[i].count;
                  }
                  if (count > 0) {
                    return (
                      <Category key={index} categoryId={category.id} categoryName={category.categoryName} />
                    )
                  }
                })
            }
          </CategorySearchController>
        )
          : (
            <></>
          )
      }

    </Flex>
  );
};

export const CategorySearch = compose<{}, {}>(observer)(CategorySearchBase);
