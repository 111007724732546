import { Text, Link } from "@chakra-ui/react";
import {
  ContentArea,
  Headline,
} from "app/shared";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";
import { NewsRepositoryProvider } from "app/news/repositories";
import { NewsResult } from "app/news/newsResult";
import React from "react";
import i18next from "i18next";
import useScript from "../utils/useScript";



export const News: React.FC = () => {

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'news', section: 'news', pubdate: '', author: '', url: '/news', object_type: 'news' }}); } ");

  return (
    <ContentArea>
      <Headline as="h1">{i18next.t("news:news.headline")}</Headline>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("news:news.introText1")}
          <Link href="https://www.facebook.com/wirliefernorg/" color="brandRed.300" fontWeight="bold">
            Facebook
          </Link>
          <span>, </span>  
          <Link href="https://twitter.com/wirliefernorg" target="_blank" color="brandRed.300" fontWeight="bold">
            Twitter
          </Link>
          {i18next.t("news:news.introText2")}
          <Link href="https://www.instagram.com/wir.liefern/" color="brandRed.300" fontWeight="bold">
            Instagram
        </Link>
          {i18next.t("news:news.introText3")}
          <Link href="https://www.youtube.com/channel/UCm8rJaHGldIY28zWd2HoEsQ" color="brandRed.300" fontWeight="bold">
            YouTube-Channel
        </Link>
        .
      </Text>
      </StaticPageTextBlock>
      <NewsRepositoryProvider >
        <NewsResult></NewsResult>
      </NewsRepositoryProvider>

    </ContentArea>
  )
};
