import { Text, Link } from "@chakra-ui/react";
import {
  ContentArea,

  SocialInfo,
  Headline,
} from "app/shared";
import { PhotoAndTextBlock } from "app/staticPages/PhotoAndTextBlock";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";
import { StaticPageTitle } from "app/staticPages/StaticPageTitle";
import React from "react";
import i18next from "i18next";
import useScript from "../utils/useScript";

const ForUsersDelivery = "images/ForUsersDelivery.svg";

export const ForUsers: React.FC = () => {

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'fur-nutzer', section: 'fur-nutzer', pubdate: '', author: '', url: '/fur-nutzer', object_type: 'fur-nutzer' }}); } ");

  return (
    <ContentArea>
      <Headline as="h1">{i18next.t("forUsers:title")}</Headline>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("forUsers:introText1")}
        </Text>
      </StaticPageTextBlock>
      <PhotoAndTextBlock
        imgSrc={ForUsersDelivery}
        imgWidth="1200px"
        imgPosition="right"
        my={12}>
        <StaticPageTextBlock>
          <Text fontWeight="bold">
            {i18next.t("forUsers:section1.title")}
          </Text>
          {i18next.t("forUsers:section1.line1")}
          <br />
          <Link href="/search" color="brandRed.300" fontWeight="bold">
            {i18next.t("forUsers:section1.line2")}
          </Link>
        </StaticPageTextBlock>
      </PhotoAndTextBlock>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("forUsers:section2.title")}
        </Text>
        {i18next.t("forUsers:section2.line1")}
        <br />
        <Link href="/search" color="brandRed.300" fontWeight="bold">
          {i18next.t("forUsers:section2.line2")}
        </Link>
        <br />
        {i18next.t("forUsers:section2.line3")}
        <br />
        <Link href="/search" color="brandRed.300" fontWeight="bold">
          {i18next.t("forUsers:section2.line4")}
        </Link>
      </StaticPageTextBlock>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("forUsers:section3.title")}
        </Text>
        {i18next.t("forUsers:section3.line1")}
      </StaticPageTextBlock>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("forUsers:section4.title")}
        </Text>
        {i18next.t("forUsers:section4.line1")}
        <br />
        <Link href="/search" color="brandRed.300" fontWeight="bold">
          {i18next.t("forUsers:section4.line2")}
        </Link>
      </StaticPageTextBlock>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("forUsers:section5.title")}
        </Text>
        {i18next.t("forUsers:section5.line1")}
      </StaticPageTextBlock>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("forUsers:section6.title")}
        </Text>
        {i18next.t("forUsers:section6.line1")}
        <br />
        <Link href="/newUser" color="brandRed.300" fontWeight="bold">
          {i18next.t("forUsers:section6.line2")}
        </Link>
      </StaticPageTextBlock>
      <SocialInfo header={"shared:socialInfo.header"} py={20} />
    </ContentArea>
  )
};
