import { Grid } from "@chakra-ui/react";
import { CategorySearch, SearchBox } from "app/search";
import { SplitStage } from "app/shared/splitStage";
import { InfoCard } from "app/info/InfoCard";

import {
  BenefitsGrid,
  CampaignSlide,
  ContentArea,
  HeaderBackground,
  InfoGrid,
  SocialInfo,
  WhiteSpace,
} from "app/shared";
import { FormattedEmbeddedVideo } from "app/shared/formattedEmbeddedVideo";
import React, { useState, useEffect } from "react";
import { useMediaQueryContext } from "styles/context";
import useScript from "../utils/useScript";
import { NewsRepositoryProvider } from "app/news/repositories";
import { NewsResult } from "app/news/newsResult";
import { useRootRepositoryContext } from "rootRepository";

import { Testimonial } from "app/testimonials/models";

const HomePage: React.FC = () => {
  useScript.useScriptViaString(
    "if (typeof upScore == 'function') { upScore({data: {object_id: 'HomePage', section: 'HomePage', pubdate: '', author: '', url: '/', object_type: 'HomePage' }}); } "
  );

  const { testimonialRepository, cityContentRepository } =
    useRootRepositoryContext();
  const { isLarge, isVeryLarge } = useMediaQueryContext();

  const [testimonial, setTestimonial] = useState(
    testimonialRepository.cityTestimonial
  );
  const [testimonialIsSet, setTestimonialIsSet] = useState(false);
  const [content, setContent] = useState([] as any);

  useEffect(() => {
    if (
      cityContentRepository &&
      cityContentRepository.cityConfig &&
      !testimonial
    ) {
      testimonialRepository
        .fetchCityTestimonial(
          cityContentRepository.GetAppSettingEntry("100-B060-Vid001")
        )
        .then(() => {
          setTestimonial(testimonialRepository.cityTestimonial);
        });
    }
  }, [cityContentRepository, testimonial, testimonialRepository]);

  const Render_100_B010 = (order: string) => {
    if (
      cityContentRepository &&
      cityContentRepository.cityData &&
      cityContentRepository.cityConfig
    ) {
      const headlineText =
        cityContentRepository.ParseCityContent("100-B010-H1");
      const iconSrc = cityContentRepository.GetAppSettingEntry("100-B010-Logo");
      const imgSrc =
        cityContentRepository.GetAppSettingEntry("100-B010-img001");
      return (
        <SplitStage
          key={order}
          imgSrc={imgSrc}
          headline={headlineText}
          iconSrc={iconSrc}
          buttonText="Nach Geschäften suchen!"
          buttonLink="/search"
        />
      );
    }
    return <></>;
  };

  const RenderInfoCard = (backendKey: string, order: string) => {
    if (
      cityContentRepository &&
      cityContentRepository.cityData &&
      cityContentRepository.cityConfig
    ) {
      const headlineText = cityContentRepository.ParseCityContent(
        backendKey + "-H2"
      );
      const text = cityContentRepository.ParseCityContent(
        backendKey + "-Txt01"
      );
      const imgSrc = cityContentRepository.GetAppSettingEntry(
        backendKey + "-img001"
      );

      const list = cityContentRepository.ParseCityContent(
        backendKey + "-Link01"
      );
      let listArray = [];
      if (list.length > 0) {
        listArray = JSON.parse(list);
      }

      return (
        <InfoCard
          key={order}
          id={"id_" + order}
          headline={headlineText}
          imgPosition={"left"}
          text={text}
          imgSrc={imgSrc}
          linklist={listArray}
        />
      );
    }

    return <></>;
  };

  const RenderNewsList = (order: string) => {
    return (
      <NewsRepositoryProvider key={order}>
        <NewsResult></NewsResult>
      </NewsRepositoryProvider>
    );
  };

  const RenderCityTestimonial = (order: string) => {
    if (testimonial) {
      return (
        <FormattedEmbeddedVideo
          key={order}
          header={testimonial.headline ?? ""}
          src={testimonial.linkVideo ?? ""}
          videoWidth={["250px", "500px", "500px", "700px", "900px"]}
          desc={testimonial.text ?? ""}
          textLink={testimonial.linkText ?? ""}
          btnText={"shared:favoriteShopVideo.moreShops"}
          pb={[6, 6, 0]}
          btnLink={"store/" + testimonial.fk_stores}
        />
      );
    }
    return <></>;
  };

  const getHomepageContent = () => {
    let mainContentLoaded = false;
    let tmpContent = [];

    if (content.length == 0) {
      if (content.length == 0) {
        tmpContent.push(Render_100_B010("1"));
        tmpContent.push(RenderInfoCard("100-B020", "2"));
        tmpContent.push(<CategorySearch key="3" />);
        tmpContent.push(<CampaignSlide key="4" />);
        tmpContent.push(RenderInfoCard("100-B040", "5"));
        tmpContent.push(RenderNewsList("6"));
        mainContentLoaded = true;
      } else {
        tmpContent = content;
      }
      setContent(tmpContent);
    }

    if (!testimonialIsSet && testimonial) {
      if (!mainContentLoaded) {
        tmpContent = content;
      }
      tmpContent.push(RenderCityTestimonial("7"));
      setTestimonialIsSet(true);
      setContent(tmpContent);
    }
    return content;
  };
  return (
    <>
      <ContentArea>
        <Grid
          templateColumns="repeat(1, 1fr)"
          color="black"
          gap={[6, 10, 10, 16, 20]}
        >
          {getHomepageContent()}
        </Grid>
      </ContentArea>
    </>
  );
};

export default HomePage;
