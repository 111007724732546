import { ChakraProvider } from "@chakra-ui/react";
import { Footer, FooterArea, MainLayout, Navbar, NavbarArea } from "app/shared";
import React, { useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { theme } from "styles";
import { MediaQueryProvider } from "styles/context";
import { About } from "./About";
import CreateStorePage from "./CreateStorePage";
import { CreateUserAndStorePage } from "./CreateUserAndStorePage";
import CreateUserPage from "./CreateUserPage";
import CreateProductPage from "./CreateProductPage";
import CreateStoreWindowPage from "./CreateStoreWindowPage";
import EditStorePage from "./EditStorePage";
import EditProductPage from "./EditProductPage";
import EditStoreWindowPage from "./EditStoreWindowPage";
import { EditUserPage } from "./EditUserPage";
import HomePage from "./HomePage";
import NotFoundPage from "./NotFoundPage";
import ImpressumPage from "./ImpressumPage";
import PrivacyPage from "./PrivacyPage";
import { Profile } from "./Profile";
import { Search } from "./Search";
import { StoreDetails } from "./StoreDetails";
import { StoreWindowDetails } from "./StoreWindowDetails";
import { ProductDetails } from "./ProductDetails";
import { ForStores } from "./ForStores";
import { ForUsers } from "./ForUsers";
import { ContactPage } from "./ContactPage";
import { Doi } from "./DOI";
import { PasswordReset } from "./PasswordReset";
import { News } from "./News";
import { NewsDetails } from "./NewsDetails";
import { Presse } from "./Presse";
import { PresseDetails } from "./PresseDetails";
import { Partner } from "./Partner";
import { PartnerDetails } from "./PartnerDetails";
import { TestimonialsPage } from "./TestimonialsPage";

import { useRootRepositoryContext } from "rootRepository";

import ScrollToTop from "app/_dbh/scrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const reload = () => window.location.reload();

const App: React.FC = () => {
  const { cityContentRepository } = useRootRepositoryContext();
  const [redraw, setRedraw] = useState(false);

  const colorTheme = theme;

  cityContentRepository.fetchCityConfigContent().then(() => {
    setRedraw(true);
  });

  /* Theme-Colors && Scrollbar-Colors*/
  let scrollBarTrack = "rgba(0,0,0,0.2)";
  let scrollBarThumb = "#eb5757";
  let scrollBarHover = "#555";

  if (cityContentRepository.colorJSON) {
    colorTheme.colors = {
      ...colorTheme.colors,
      ...cityContentRepository.colorJSON,
    };

    const colorJson =
      cityContentRepository.GetAppSettingJSONEntry("_sysColors");
    if (colorJson?.content?.scrollbar?.track?.length > 0) {
      scrollBarTrack = colorJson?.content?.scrollbar?.track;
    }
    if (colorJson?.content?.scrollbar?.thumb?.length > 0) {
      scrollBarThumb = colorJson?.content?.scrollbar?.thumb;
    }
    if (colorJson?.content?.scrollbar?.hover?.length > 0) {
      scrollBarHover = colorJson?.content?.scrollbar?.hover;
    }
  }

  /* Icon-Colors */
  // if (cityContentRepository.iconsAreSet) {
  //   let icons = cityContentRepository.customIcons;
  //   colorTheme.icons = {
  //     ...colorTheme.icons,
  //     ...icons,
  //   };
  // }

  /* Page-Erlaubnisse */
  let pageSettingsString = "";
  if (cityContentRepository.cityConfig) {
    pageSettingsString = cityContentRepository.GetAppSettingEntry("_sysPages");
  }

  const pageIsEnables = (value: string) => {
    return pageSettingsString.indexOf(value) >= 0;
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `              
              ::-webkit-scrollbar {
                width: 8px;
                height: 8px;
              }
              ::-webkit-scrollbar-track {
                background: ${scrollBarTrack};
              }
              ::-webkit-scrollbar-thumb {
                background: ${scrollBarThumb};
              }
              ::-webkit-scrollbar-thumb:hover {
                background: ${scrollBarHover};
              }`,
        }}
      ></style>
      {cityContentRepository.isLoaded && (
        <ChakraProvider theme={colorTheme}>
          <MediaQueryProvider>
            <BrowserRouter>
              <ScrollToTop />
              <MainLayout>
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  limit={1}
                  rtl={false}
                />
                <NavbarArea>
                  <Navbar />
                </NavbarArea>
                <Switch>
                  {/* Public routes */}
                  <Route exact path="/" component={HomePage} />

                  {pageIsEnables("About:") && (
                    <Route exact path="/about" component={About} />
                  )}
                  {pageIsEnables("ForStores;") && (
                    <Route exact path="/fur-geschafte" component={ForStores} />
                  )}
                  {pageIsEnables("ForUsers;") && (
                    <Route exact path="/fur-nutzer" component={ForUsers} />
                  )}
                  {pageIsEnables("Search;") && (
                    <Route path="/search" component={Search} />
                  )}
                  {pageIsEnables("StoreDetails;") && (
                    <Route exact path="/store/:id" component={StoreDetails} />
                  )}
                  {/* TODO ML pageIsEnables*/}
                  {pageIsEnables("StoreDetails;") && (
                    <Route
                      exact
                      path="/storeWindow/:id"
                      component={StoreWindowDetails}
                    />
                  )}
                  {/* TODO ML pageIsEnables*/}
                  {pageIsEnables("StoreDetails;") && (
                    <Route
                      exact
                      path="/product/:storeWindowId/:productId"
                      component={ProductDetails}
                    />
                  )}
                  {pageIsEnables("Doi;") && (
                    <Route exact path="/doi/:id" component={Doi} />
                  )}
                  {pageIsEnables("PasswordReset;") && (
                    <Route
                      exact
                      path="/passwort-zuruecksetzen/:id"
                      component={PasswordReset}
                    />
                  )}
                  {pageIsEnables("PasswordReset;") && (
                    <Route
                      exact
                      path="/passwort-zuruecksetzen"
                      component={PasswordReset}
                    />
                  )}
                  {pageIsEnables("NewUser;") && (
                    <Route exact path="/newUser" component={CreateUserPage} />
                  )}
                  {pageIsEnables("ContactPage;") && (
                    <Route exact path="/kontakt" component={ContactPage} />
                  )}
                  {pageIsEnables("News;") && (
                    <Route exact path="/news" component={News} />
                  )}
                  {pageIsEnables("NewsDetails;") && (
                    <Route
                      exact
                      path="/newsDetail/:id"
                      component={NewsDetails}
                    />
                  )}
                  {pageIsEnables("Presse;") && (
                    <Route exact path="/presse" component={Presse} />
                  )}
                  {pageIsEnables("PresseDetails;") && (
                    <Route
                      exact
                      path="/presseDetail/:id"
                      component={PresseDetails}
                    />
                  )}
                  {pageIsEnables("Partner;") && (
                    <Route exact path="/partner" component={Partner} />
                  )}
                  {pageIsEnables("PartnerDetails;") && (
                    <Route
                      exact
                      path="/partnerDetail/:id"
                      component={PartnerDetails}
                    />
                  )}
                  {pageIsEnables("TestimonialsPage;") && (
                    <Route
                      exact
                      path="/lieblingslaeden"
                      component={TestimonialsPage}
                    />
                  )}

                  <Route
                    exact
                    path="/newUserAndStore"
                    component={CreateUserAndStorePage}
                  />

                  {/*Footer-Pages*/}
                  <Route exact path="/Impressum" component={ImpressumPage} />
                  <Route
                    exact
                    path="/Datenschutzerklaerung"
                    component={PrivacyPage}
                  />

                  {/* Routes requiring login */}
                  <Route
                    exact
                    path="/editStore/:id"
                    component={EditStorePage}
                  />
                  <Route exact path="/newStore" component={CreateStorePage} />
                  <Route
                    exact
                    path="/editProduct/:id"
                    component={EditProductPage}
                  />
                  <Route
                    exact
                    path="/newProduct"
                    component={CreateProductPage}
                  />
                  <Route
                    exact
                    path="/editStoreWindow/:id"
                    component={EditStoreWindowPage}
                  />
                  <Route
                    exact
                    path="/newStoreWindow/:storeId"
                    component={CreateStoreWindowPage}
                  />
                  <Route path="/user" component={Profile} />
                  <Route path="/editUser" component={EditUserPage} />

                  <Route path="/crashtest-c4f17a4b.html" onEnter={reload} />

                  {/* Default redirect to the 404 page */}
                  <Route path="/404" component={NotFoundPage} />
                  <Redirect from="*" to="/404" />
                </Switch>

                <FooterArea>
                  <Footer />
                </FooterArea>
              </MainLayout>
            </BrowserRouter>
          </MediaQueryProvider>
        </ChakraProvider>
      )}
    </>
  );
};

export default App;
