import React from "react";
import { Box, AspectRatio, Image } from "@chakra-ui/react";

import { ProductForCampaign } from "app/products/models";
import { DEFAULT_RATIO } from "app/shared/constants";
import environment from "configurations";
import { TextLink } from "app/shared";

interface ProductCardProps {
  product: ProductForCampaign;
}

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  return (
    <Box minWidth={300}>
      <AspectRatio ratio={DEFAULT_RATIO}>
        <TextLink
          to={`/product/${product.mostRecentStoreWindowId}/${product.id}`}
        >
          <Image
            objectFit="contain"
            width="100%"
            height="100%"
            src={environment.site + product.photo?.url}
          />
        </TextLink>
      </AspectRatio>
      <Box p={4} bg="white">
        <TextLink
          to={`/product/${product.mostRecentStoreWindowId}/${product.id}`}
          fontSize="md"
          fontWeight="bold"
        >
          {product.name}
        </TextLink>
      </Box>
    </Box>
  );
};
