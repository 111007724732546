import React, { createContext, useContext, useMemo } from "react";
import useMedia from "use-media";
import { breakpoints } from "./theme";

interface Props {
  children: React.ReactNode;
}

export interface MediaQueryContextProps {
  isSmall: boolean;
  isSmallMedium: boolean;
  isMedium: boolean;
  isMediumLarge: boolean;
  isLarge: boolean;
  isVeryLarge: boolean;
}

export const MediaQueryContext = createContext<MediaQueryContextProps>({
  isSmall: false,
  isSmallMedium: false,
  isMedium: false,
  isMediumLarge: false,
  isLarge: false,
  isVeryLarge:false,
});

export const MediaQueryProvider: React.FC<Props> = ({ children }) => {
  const mediaQueries = {
    small: `(max-width: ${breakpoints.sm})`,
    smallMedium: `(max-width: ${breakpoints.smmd})`,
    medium: `(max-width: ${breakpoints.md})`,
    mediumLarge: `(max-width: ${breakpoints.mdlg})`,
    large: `(max-width: ${breakpoints.lg})`,
    veryLarge: `(max-width: ${breakpoints.xl})`,
  };

  const isSmall = useMedia(mediaQueries.small);
  const isSmallMedium = useMedia(mediaQueries.smallMedium);
  const isMedium = useMedia(mediaQueries.medium);
  const isMediumLarge = useMedia(mediaQueries.mediumLarge);
  const isLarge = useMedia(mediaQueries.large);
  const isVeryLarge = useMedia(mediaQueries.veryLarge);

  const value = useMemo(
    () => ({ isSmall, isSmallMedium, isMedium, isMediumLarge, isLarge, isVeryLarge }),
    [isSmall, isSmallMedium, isMedium, isMediumLarge, isLarge, isVeryLarge]
  );

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQueryContext = () => useContext(MediaQueryContext);
