import { Stack, Text, Button } from "@chakra-ui/react";
import { ContentArea } from "app/shared";
import { ProfileInfo, ProfileTabs } from "app/users";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { useRootRepositoryContext } from "rootRepository";
import { User } from "app/users/models";
import i18next from "i18next";
import useScript from "../utils/useScript";

const EditProfile: React.FC<{
  user: User;
  numberStores: number;
  numberProducts: number;
  numberComments: number;
  numberFavorites: number;
}> = ({
  user,
  numberStores,
  numberProducts,
  numberComments,
  numberFavorites,
}) => (
  <Stack spacing={20} direction="column">
    <ProfileInfo editDisabled={false} user={user} />
    <ProfileTabs
      user={user}
      numberStores={numberStores}
      numberProducts={numberProducts}
      numberComments={numberComments}
      numberFavorites={numberFavorites}
    />
  </Stack>
);

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const ProfileBase: React.FC<Props> = ({ match }) => {
  useScript.useScriptViaString(
    "if (typeof upScore == 'function') { upScore({data: {object_id: 'user', section: 'user', pubdate: '', author: '', url: '/user', object_type: 'user' }}); } "
  );

  const id = match.params.id;
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  const [userState, setUserState] = useState(userRepository.user);
  const [numberStores, setNumberStores] = useState(user?.ownedStores);
  const [numberProducts, setNumberProducts] = useState(user?.ownedProducts);
  const [numberComments, setNumberComments] = useState(user?.ownedComments);
  const [numberFavorites, setNumberFavorites] = useState(user?.ownedFavorites);
  useEffect(() => {
    //const initialNumberStores = user.ownedStores;
    //const initialNumberCommetns = user.ownedComments;
    //const initialNumberFavorites = user.ownedFavorites;

    //if (initialNumberStores && user.ownedStores) {
    //  setNumberStores(user.ownedStores);
    //}
    //if (initialNumberCommetns && user.ownedComments) {
    //  setNumberComments(user.ownedComments);
    //}
    //if (initialNumberFavorites && user.ownedFavorites) {
    //  setNumberFavorites(user.ownedFavorites);
    //}
    setNumberStores(userState?.ownedStores);
    setNumberProducts(userState?.ownedProducts);
    setNumberComments(userState?.ownedComments);
    setNumberFavorites(userState?.ownedFavorites);
  }, [user, userState]);

  return (
    <ContentArea>
      {user ? (
        <EditProfile
          user={user}
          numberStores={numberStores ?? 0}
          numberProducts={numberProducts ?? 0}
          numberComments={numberComments ?? 0}
          numberFavorites={numberFavorites ?? 0}
        />
      ) : (
        <Text>{i18next.t("shared:loginRequired")}</Text>
      )}
    </ContentArea>
  );
};

export const Profile = compose<Props, {}>(withRouter, observer)(ProfileBase);
