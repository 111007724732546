import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
  Center,
  AspectRatio,
} from "@chakra-ui/react";
import i18next from "i18next";
import { TextLink, Button, ButtonLink, Heading, Popup } from "app/shared";
import { DEFAULT_RATIO } from "app/shared/constants";
import { StoreId } from "../models";
import { StoreWindowForList } from "../../storeWindows/models";
import {
  getStoreWindowsFromStore,
  deleteStoreWindow,
} from "../../storeWindows/services";
import { DotsIcon } from "styles/icons";
import { AddIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { getOwnedProducts } from "app/products/services";
import { useRootRepositoryContext } from "rootRepository";
import { ProductForTable } from "app/products/models";
import { useHistory } from "react-router-dom";
import environment from "configurations";

const MAX_STOREWINDOWS = 1;

interface Props {
  storeId: StoreId;
}

export const StoreWindowGrid: React.FC<Props> = ({ storeId }) => {
  const [loading, setLoading] = useState(true);
  const [storeWindows, setStoreWindows] = useState<StoreWindowForList[]>([]);

  useEffect(() => {
    getStoreWindowsFromStore(storeId).then((response) => {
      setStoreWindows(response);
      setLoading(false);
    });
  }, [storeId]);

  const onDelete = (itemId: string) => {
    setStoreWindows(
      storeWindows.filter(function (value, index, arr) {
        return value.id != itemId;
      })
    );
  };

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={8} py={3}>
      {storeWindows?.map((item, index) => (
        <Box key={`item_${index}`}>
          <StoreWindowCard storeWindow={item} onDelete={onDelete} />
        </Box>
      ))}
      {storeWindows.length < MAX_STOREWINDOWS && (
        <EmptyImageBox storeId={storeId} />
      )}
    </Grid>
  );
};

interface StoreWindowCardProps {
  storeWindow: StoreWindowForList;
  onDelete: (id: string) => void;
}

const StoreWindowCard: React.FC<StoreWindowCardProps> = ({
  storeWindow,
  onDelete,
}) => {
  const onPopupDeleteClose = (itemId: string) => {
    onDelete(itemId);
  };

  return (
    <Flex direction="column">
      <Flex direction="row-reverse">
        <Popup
          trigger={(showPopup) => (
            <Menu>
              <MenuButton m={1} color="darkestGrey">
                <DotsIcon boxSize={6} />
              </MenuButton>
              <MenuList borderRadius="0" py="0">
                <MenuItem
                  color="content.button.color"
                  bg="content.button.background"
                  height={10}
                  _hover={{ bg: "content.button.hover" }}
                  _focus={{ textDecoration: "none" }}
                  onClick={showPopup}
                >
                  {i18next.t("stores:storeWindowGrid.delete")}
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          content={(onClose) => (
            <DeleteStoreWindowConfirmDialog
              closePopup={() => {
                onClose();
                onPopupDeleteClose(storeWindow.id);
              }}
              storeWindowId={storeWindow.id}
            />
          )}
          contentMaxWidth={["800px"]}
        />
        <TextLink
          to={`/storeWindow/${storeWindow.id}`}
          color="darkGrey"
          fontWeight="bold"
          m="1"
        >
          {i18next.t("stores:storeWindowGrid.showPage")}
        </TextLink>
        <TextLink
          to={`/editStoreWindow/${storeWindow.id}`}
          color="darkGrey"
          fontWeight="bold"
          m="1"
        >
          {i18next.t("stores:storeWindowGrid.edit")}
        </TextLink>
      </Flex>
      <AspectRatio ratio={DEFAULT_RATIO}>
        <Box position="relative" background="darkGrey" rounded="5px">
          <Image
            src={environment.site + storeWindow.photo?.url}
            objectFit="contain"
            width="100%"
            height="100%"
            rounded="5px"
            opacity={0.8}
          />
          {storeWindow.visible ? (
            <ViewIcon
              position="absolute"
              top="20px"
              left="25px"
              color="lightestGrey"
              boxSize="30px"
            />
          ) : (
            <ViewOffIcon
              position="absolute"
              top="20px"
              left="25px"
              color="lightestGrey"
              boxSize="30px"
            />
          )}

          <Text
            position="absolute"
            bottom="20px"
            left="25px"
            fontSize="3xl"
            fontWeight="bold"
            color="white"
          >
            {storeWindow.name}
          </Text>
        </Box>
      </AspectRatio>
    </Flex>
  );
};

interface DeleteStoreWindowConfirmationDialogProps {
  storeWindowId: string;
  closePopup: () => void;
}

const DeleteStoreWindowConfirmDialog: React.FC<
  DeleteStoreWindowConfirmationDialogProps
> = ({ storeWindowId, closePopup }) => {
  return (
    <Box mb={1}>
      <Heading>{i18next.t("stores:storeWindowGrid.deletePopup.title")}</Heading>
      <Text fontSize="lg" color="darkGrey">
        {i18next.t("stores:storeWindowGrid.deletePopup.message")}
      </Text>
      <Button
        w="200px"
        mt={1}
        onClick={() => {
          deleteStoreWindow(storeWindowId).then(() => {
            closePopup();
          });
        }}
      >
        {i18next.t("stores:storeWindowGrid.deletePopup.button")}
      </Button>
    </Box>
  );
};

interface EmptyImageBoxProps {
  storeId: string;
}

const EmptyImageBox: React.FC<EmptyImageBoxProps> = ({ storeId }) => {
  const [products, setProducts] = useState<ProductForTable[]>([]);

  const history = useHistory();
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  useEffect(() => {
    if (user) {
      getOwnedProducts(user.id).then((response) => {
        setProducts(response);
      });
    }
  }, [user]);

  return (
    <Popup
      trigger={(showPopup) => (
        <AspectRatio ratio={DEFAULT_RATIO}>
          <Button
            onClick={() =>
              products && products.length > 0
                ? history.push("/newStoreWindow/" + storeId)
                : showPopup()
            }
            bg="lightestGrey"
            mt="30px"
            p="10px"
            borderRadius="5px"
            alignItems="stretch"
            justifyContent="stretch"
          >
            <Center
              bg="transparent"
              borderRadius="5px"
              border="2px"
              borderColor="Grey"
              borderStyle="dashed"
              width="100%"
              height="100%"
            >
              <AddIcon color="Grey" boxSize="50px" />
            </Center>
          </Button>
        </AspectRatio>
      )}
      content={() => <NoProductsDialog />}
      contentMaxWidth={["800px"]}
    />
  );
};

const NoProductsDialog = () => {
  return (
    <Box mb={1}>
      <Heading>
        {i18next.t("stores:storeWindowGrid.noProductsPopup.title")}
      </Heading>
      <Text fontSize="lg" color="darkGrey">
        {i18next.t("stores:storeWindowGrid.noProductsPopup.message")}
      </Text>
      <ButtonLink
        to="/newProduct"
        w="200px"
        mt={1}
        color="white"
        bg="brandRed.300"
        borderRadius={0}
        _hover={{ bg: "brandRed.100" }}
        _focus={{ textDecoration: "none" }}
      >
        {i18next.t("stores:storeWindowGrid.noProductsPopup.button")}
      </ButtonLink>
    </Box>
  );
};
