import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { Flex, Box, Text, Icon } from "@chakra-ui/react";
import React from "react";
import { PlusIcon } from "styles/icons";
import { useSearchRepositoryContext } from "../../search/repositories";

interface Props {
  name: string;
  id: string;
  selectedSubCategories: string[];
  selected: boolean;
  onSubCategorySelected: (subCategories: string[]) => void;
}

export const SubCategoryBox: React.FC<Props> = ({
  name,
  id,
  selected,
  selectedSubCategories,
  onSubCategorySelected,
}) => {
  const { searchRepository } = useSearchRepositoryContext();

  const handleOnSubCategorySelected = () => {
    let subCategrories: string[];
    if (selected) {
      subCategrories = selectedSubCategories.filter(
        (subCategoryId) => subCategoryId !== id
      );
    } else {
      subCategrories = [...selectedSubCategories, id];
    }
    onSubCategorySelected(subCategrories);
    searchRepository?.setSubCategories(subCategrories);
    searchRepository?.fetchStoresForPage();
  };

  //backgroundImage={selected ? "url('/images/ic_x.svg')" : "url('/images/ic_off.svg')"}
  return (
    <Box
      _hover={{ cursor: "pointer" }}
      bg={
        selected
          ? "content.category.subCategory.background"
          : "content.category.subCategory.inactive.background"
      }
      backgroundPosition={"right 12px center"}
      backgroundRepeat="no-repeat"
      color={
        selected
          ? "content.category.subCategory.color"
          : "content.category.subCategory.inactive.color"
      }
      border={selected ? undefined : "1px solid"}
      borderColor="lightestBlack"
      onClick={handleOnSubCategorySelected}
      height="27px"
      p="6px 35px 5px 20px"
      m="0 5px 5px 0"
      width={["100%", "100%", "100%", "auto", "auto"]}
      maxWidth={["135px", "175px", "175px", "none", "none"]}
      display="block"
      //width="118px"
      //overflow="hidden"
      boxShadow="lg"
    >
      <Flex
        height="100%"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <Text fontSize="xs" fontWeight="bold" isTruncated>
          {name}
        </Text>
        {selected ? (
          <CloseIcon position="absolute" right="-24px" boxSize={3} />
        ) : (
          <AddIcon position="absolute" right="-24px" boxSize={3} />
        )}
      </Flex>
    </Box>
  );
};
