import { computed, flow, observable } from "mobx";
//import { Testimonial } from "../models";
import { getCityConfig, getCityContent, getCityKeyFromConfig } from "../services/index";
import { customIcons } from "./icons";
import { Coords } from "app/shared/maps/models";

export class CityContentRepository {
  @observable private _cityKey: string;
  @observable private _cityData: any;
  @observable private _cityConfig: any;
  @observable private _cityColorJson?: any;
  @observable private _cityIconConfigJson?: any;

  @observable private _coords: Coords;
  @observable private _zoom: number;


  @observable private _isLoaded: boolean;

  private _iconsModified = false;
  private _customIcons = {};

  constructor() {
    this._cityKey = "";
    this._cityData = {};
    this._cityConfig = {};
    this._isLoaded = false;

    this._zoom = 6;
    this._coords = {
      lat: parseFloat(51.158352.toString()),
      lng: parseFloat(10.441210.toString()),
    };
  }

  fetchCityConfigContent = flow(function* (this: CityContentRepository) {
    try {
      this._cityKey = yield getCityKeyFromConfig();
      this._cityConfig = yield getCityConfig(this._cityKey);
      this._cityData = yield getCityContent(this._cityKey);
      this._cityColorJson = JSON.parse(this.GetAppSettingEntry("_sysColors"));
      this._cityIconConfigJson = JSON.parse(this.GetAppSettingEntry("_sysIcons"));

      let mapSettings = this.GetAppSettingEntry("_sysMapSettings");
      if (mapSettings && mapSettings.length > 0) {
        let jsonMapSettings = JSON.parse(mapSettings);
        if (jsonMapSettings.zoom && !isNaN(+jsonMapSettings.zoom)) {
          this._zoom = +jsonMapSettings.zoom;
        }
        if (jsonMapSettings?.coordinates?.lat && jsonMapSettings?.coordinates?.long) {
          this._coords = {
            lat: parseFloat(jsonMapSettings.coordinates.lat.toString()),
            lng: parseFloat(jsonMapSettings.coordinates.long.toString()),
          }
        }
      }

      this._isLoaded = true;
    } catch (error) {
      console.error(error);
    }
  }).bind(this);


  public GetAppSettingEntry(appSettingName: string): any {
    let value = this._cityConfig?.appSettings?.find((x: { appSetting: string; }) => x.appSetting == appSettingName)?.appSettingValue;
    return value;
  }

  public GetAppSettingJSONEntry(appSettingName: any): any {
    let value = this._cityConfig?.appSettings?.find((x: { appSetting: string; }) => x.appSetting == appSettingName)?.appSettingValue;
    let jsonValue = null;
    try {
      jsonValue = JSON.parse(value);
    } catch{ }
    return jsonValue;
  }

  public GetCityContentEntry(elementId: string): any {
    let data: any = this._cityData;
    if (data) {
      let value = data.contentElemente?.find((elements: ContentElements) => elements.contentElementID === elementId)?.contentElementValue;
      if (value) {
        return value;
      }
    }
    return "";
  }

  public ParseCityContent(elementId: string, cleared: boolean = false): string {
    let parsedText = "";
    let tmp = document.createElement("DIV");
    tmp.innerHTML = this.GetCityContentEntry(elementId);
    parsedText = tmp.innerHTML;
    if (cleared) {
      parsedText = tmp.textContent || tmp.innerText || "";
    }
    return parsedText;
  }



  @computed get cityConfig(): object | undefined {
    return this._cityConfig;
  }
  @computed get colorJSON(): object | undefined {
    return this._cityColorJson;
  }

  @computed get cityData(): any | undefined {
    return this._cityData;
  }

  @computed get isLoaded(): boolean {
    return this._isLoaded;
  }

  @computed get cityKey(): string {
    return this._cityKey;
  }

  @computed get iconsAreSet(): boolean {
    return this._cityIconConfigJson;
  }

  @computed get cityDefaultCoordinats(): Coords {
    return this._coords;
  }
  @computed get cityDefaultZoom(): number {
    return this._zoom;
  }

  public get customIcons(): any {
    if (this._iconsModified) {
      return this._customIcons;
    }
    else {
      this._customIcons = customIcons(this._cityIconConfigJson);
      return this._customIcons;
    }
  }
}

interface ContentElements {
  contentElement: string;
  contentElementID: string;
  contentElementValue: string;
}
