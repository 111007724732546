import React, { useContext } from "react";
import ProductRepository from "./ProductRepository";

interface RepositoryContextProps {
  productRepository: ProductRepository;
}

export const ProductRepositoryContext =
  React.createContext<RepositoryContextProps>({} as RepositoryContextProps);

export const useProductRepositoryContext = () =>
  useContext(ProductRepositoryContext);
