import React from "react";
import { Box, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import i18next from "i18next";
import { Field } from "formik";
import { StyledCheckbox } from "app/shared";
import { FieldInternalProps } from "app/shared/forms/GenericInput";

export const DEFAULT_LABEL_WIDTH = "220px";

export interface GenericCheckboxProps {
  fieldName: string;
  fieldType: string;
  i18nextPrefix: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
}

export const GenericCheckbox: React.FC<GenericCheckboxProps> = (
  props: GenericCheckboxProps
) => {
  const { fieldName, fieldType, hideLabel, i18nextPrefix } = props;
  const disabled = props.disabled || false;
  const labelWidthPx = props.labelWidthPx || DEFAULT_LABEL_WIDTH;
  return (
    <Field
      className="block"
      name={fieldName}
      type={fieldType}
      placeholder={fieldName}
    >
      {(renderProps: FieldInternalProps) => (
        <FormControl
          isInvalid={
            renderProps.form.errors[fieldName] &&
            renderProps.form.touched[fieldName]
          }
        >
          <Flex mt={3}>
            <Box width={labelWidthPx}>
              {!hideLabel && (
                <FormLabel htmlFor={fieldName}>
                  {i18next.t(`${i18nextPrefix}:form.fields.${fieldName}.label`)}
                </FormLabel>
              )}
            </Box>
            <Box flex="1">
              <StyledCheckbox
                id={fieldName}
                defaultIsChecked={renderProps.field.value ?? false}
                isDisabled={disabled}
                fontSize="lg"
                px={3}
                py={2}
                onChange={(e) => {
                  renderProps.form.setFieldValue(fieldName, e.target.checked);
                  renderProps.form.setFieldTouched(fieldName, true);
                }}
              />
            </Box>
          </Flex>
        </FormControl>
      )}
    </Field>
  );
};
