import {
  BoxProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { User } from "../models";
import { OwnedStoresGrid } from "./OwnedStoresGrid";
import { OwnedProductsGrid } from "./OwnedProductsGrid";
import { OwnedCommentsGrid } from "./OwnedCommentsGrid";
import { OwnedFavoritesGrid } from "./OwnedFavoritesGrid";

interface Props extends BoxProps {
  user: User;
  numberStores: number;
  numberProducts: number;
  numberComments: number;
  numberFavorites: number;
}

export const ProfileTabs: React.FC<Props> = ({
  user,
  numberStores,
  numberProducts,
  numberComments,
  numberFavorites,
}) => {
  return (
    <Tabs variant="enclosed" colorScheme="red">
      <TabList>
        <Tab
          bg="content.profile.tabs.inactive.background"
          color="content.profile.tabs.inactive.color"
          fontWeight="bold"
          borderRadius={0}
          border="1px solid"
          borderColor="grey"
          borderBottom="0px"
          mr={2}
          _selected={{
            bg: "content.profile.tabs.active.background",
            color: "content.profile.tabs.active.color",
          }}
          _focus={undefined}
        >
          {i18next.t("users:profile.profileTabs.stores", {
            value: numberStores,
          })}
        </Tab>
        <Tab
          bg="brandRed.300"
          color="white"
          fontWeight="bold"
          borderRadius={0}
          border="1px solid"
          borderColor="grey"
          borderBottom="0px"
          mr={2}
          _selected={{ bg: "white", color: "lightBlack" }}
          _focus={undefined}
        >
          {i18next.t("users:profile.profileTabs.products", {
            value: numberProducts,
          })}
        </Tab>
        <Tab
          bg="content.profile.tabs.inactive.background"
          color="content.profile.tabs.inactive.color"
          fontWeight="bold"
          borderRadius={0}
          border="1px solid"
          borderColor="grey"
          borderBottom="0px"
          mr={2}
          _selected={{
            bg: "content.profile.tabs.active.background",
            color: "content.profile.tabs.active.color",
          }}
          _focus={undefined}
        >
          {i18next.t("users:profile.profileTabs.comments", {
            value: numberComments,
          })}
        </Tab>
        <Tab
          bg="content.profile.tabs.inactive.background"
          color="content.profile.tabs.inactive.color"
          fontWeight="bold"
          borderRadius={0}
          border="1px solid"
          borderColor="grey"
          borderBottom="0px"
          mr={2}
          _selected={{
            bg: "content.profile.tabs.active.background",
            color: "content.profile.tabs.active.color",
          }}
          _focus={undefined}
        >
          {i18next.t("users:profile.profileTabs.favorites", {
            value: numberFavorites,
          })}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel borderColor="grey">
          <OwnedStoresGrid userId={user.id} />
        </TabPanel>
        <TabPanel borderColor="grey">
          <OwnedProductsGrid userId={user.id} />
        </TabPanel>
        <TabPanel borderColor="grey">
          <OwnedCommentsGrid userId={user.id} />
        </TabPanel>
        <TabPanel borderColor="grey">
          <OwnedFavoritesGrid userId={user.id} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
