import { BoxProps, Flex, Text } from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { useMediaQueryContext } from "styles/context";

export const SearchInfo: React.FC<BoxProps> = (props) => {
  const { isSmallMedium } = useMediaQueryContext();

  return (
    <Flex
      color="white"
      w={["100%", "100%", "100%", "100%", "45%"]}
      zIndex={0}
      py={4}
      flexDirection="column"
      alignItems={["center", "center", "center", "center", "flex-start"]}
      {...props}>
      <Text
        fontWeight="bold"
        fontSize={["4xl", "4xl", "5xl", "5xl", "6xl"]}
        wordBreak="break-word"
        textAlign={["center", "center", "center", "center", "right"]}
        lineHeight="base">
        {i18next.t("search:searchBox.info")}
      </Text>
    </Flex>
  );
};
