import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { ButtonLink } from "..";

interface Props {
  fetchId: string;
  fetchItems: (params: any) => Promise<any>;
  fetchMoreItems?: (id: string) => Promise<any>;
  header?: string;
  loadMore: string;
  columnWidth?: string;
  numColumns: number;
  view: (item: any) => ReactNode;
  emptyStateView: () => ReactNode;
  hideBottomButton?: boolean;
}

export const LayoutGrid: React.FC<Props> = ({
  fetchId,
  fetchItems,
  fetchMoreItems,
  header,
  loadMore,
  columnWidth,
  numColumns,
  view,
  emptyStateView,
  hideBottomButton

}) => {
  const [items, setItems] = useState<any[]>([]);
  const [maxDisplayedItems, setMaxDisplayedItems] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchItems({ userId: fetchId }).then((response) => {
      setMaxDisplayedItems(response.perPage);
      setItems(response.data);
      setTotalResults(response.totalResults);
      setLoading(false);
    });
  }, [fetchId, fetchItems]);

  const handleLoadMoreClick = () => {
    if (fetchMoreItems) {
      setLoading(true);
      fetchMoreItems(fetchId).then((response) => {
        setMaxDisplayedItems(maxDisplayedItems + response.perPage);
        setItems([...items, response.data]);
        setTotalResults(response.totalResults);
        setLoading(false);
      });
    }
  };

  return items != undefined && items.length !== 0 ? (
    <Box py={9}>
      {header && (
        <Text fontSize="5xl" fontWeight="bold" pb={8}>
          {header}
        </Text>
      )}
      <Grid
        templateColumns={`repeat(${numColumns ?? 0}, ${columnWidth ?? "auto"})`}
        
        gap={8}>
        {items?.map(
          (item, index) =>
            index < maxDisplayedItems && (
              <Box key={`item_${index}`}>{view(item)}</Box>
            )
        )}
      </Grid>
      {(hideBottomButton === undefined || !hideBottomButton) && (
        <Flex
          direction="column"
          alignItems="center"
          borderTop="4px solid"
          borderColor="brandRed.300"
          mt={10}>
          <ButtonLink
            to={`/newStore`}
            w="15%"
            minWidth="190px"
            color="white"
            borderRadius="0"
            bg="brandRed.300"
            onClick={loading ? undefined : handleLoadMoreClick}>
            {loadMore}
          </ButtonLink>
        </Flex>
      )}
    </Box>
  ) : (
    <Box>{emptyStateView()}</Box>
  );
};


/*
templateRows={`repeat(${Math.ceil(
          maxDisplayedItems / numColumns
        )}, auto)`}

*/
