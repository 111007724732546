import { StoreAddComment, StoreCommentsGrid } from "app/comments";
import { CommentRepositoryProvider } from "app/comments/repositories";
import { ContentArea } from "app/shared";
import { StoreInfo } from "app/stores";
import { StoreRepositoryProvider } from "app/stores/repositories";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import useScript from "../utils/useScript";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

/**
 * If the user is logged in but not owner:
 *  - bg = grey
 *  - has add comment section
 *
 * If the user isn't logged in or is the owner:
 *  - bg = grey
 *  - doesnt have the add comment section
 */
const StoreDetailsBase: React.FC<Props> = ({ match }) => {
  const id = match.params.id;

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: '" + id + "', section: 'store', pubdate: '', author: '', url: '/store', object_type: 'store' }}); } ");

  return (
    <ContentArea color="content.store.storeDetail.color" bg="content.store.storeDetail.background">
      <StoreRepositoryProvider>
        <StoreInfo storeId={id} />
      </StoreRepositoryProvider>
      <CommentRepositoryProvider>
        <StoreCommentsGrid storeId={id} />
        <StoreAddComment storeId={id} />
      </CommentRepositoryProvider>
    </ContentArea>
  );
};

export const StoreDetails = compose<Props, {}>(withRouter)(StoreDetailsBase);
