import { Store, StoreSubCategory } from "../../models";

import { Chance } from "chance";
import { Ratings } from "app/shared/rating/constants";
import { Photo, LOCAL_DELIVERY, NATIONAL_DELIVERY } from "app/shared/models";
import { MockCategoryFactory } from "app/categories/services/MockCategoryFactory";
//import { useRootRepositoryContext } from "rootRepository";
/**
 * Used for development purposes until the API is ready
 */
export class MockStoreFactory {
  static newStore(overrides?: Partial<Store>): Store {
    const chance = new Chance();
    const canDeliver = overrides?.deliveryOptions?.canDeliver ?? chance.bool();
    const deliveryType =
      overrides?.deliveryOptions?.deliveryType ??
      chance.pickone([LOCAL_DELIVERY, NATIONAL_DELIVERY]);
    const canPickup = overrides?.deliveryOptions?.canPickup ?? chance.bool();
    const openingHours = overrides?.openingHours;

    return {
      id: overrides?.id ?? chance.guid(),
      name: overrides?.name ?? chance.company(),
      rating:
        overrides?.rating ?? (chance.integer({ max: 5, min: 0 }) as Ratings),
      openingHours: {
        monday: {
          firstStart: openingHours?.monday.firstStart ?? chance.string(),
          firstEnd: openingHours?.monday.firstEnd ?? chance.string(),
          secondStart: openingHours?.monday.secondStart ?? chance.string(),
          secondEnd: openingHours?.monday.secondEnd ?? chance.string(),
        },
        tuesday: {
          firstStart: openingHours?.tuesday.firstStart ?? chance.string(),
          firstEnd: openingHours?.tuesday.firstEnd ?? chance.string(),
          secondStart: openingHours?.tuesday.secondStart ?? chance.string(),
          secondEnd: openingHours?.tuesday.secondEnd ?? chance.string(),
        },
        wednesday: {
          firstStart: openingHours?.wednesday.firstStart ?? chance.string(),
          firstEnd: openingHours?.wednesday.firstEnd ?? chance.string(),
          secondStart: openingHours?.wednesday.secondStart ?? chance.string(),
          secondEnd: openingHours?.wednesday.secondEnd ?? chance.string(),
        },
        thursday: {
          firstStart: openingHours?.thursday.firstStart ?? chance.string(),
          firstEnd: openingHours?.thursday.firstEnd ?? chance.string(),
          secondStart: openingHours?.thursday.secondStart ?? chance.string(),
          secondEnd: openingHours?.thursday.secondEnd ?? chance.string(),
        },
        friday: {
          firstStart: openingHours?.friday.firstStart ?? chance.string(),
          firstEnd: openingHours?.friday.firstEnd ?? chance.string(),
          secondStart: openingHours?.friday.secondStart ?? chance.string(),
          secondEnd: openingHours?.friday.secondEnd ?? chance.string(),
        },
        saturday: {
          firstStart: openingHours?.saturday.firstStart ?? chance.string(),
          firstEnd: openingHours?.saturday.firstEnd ?? chance.string(),
          secondStart: openingHours?.saturday.secondStart ?? chance.string(),
          secondEnd: openingHours?.saturday.secondEnd ?? chance.string(),
        },
        sunday: {
          firstStart: openingHours?.sunday.firstStart ?? chance.string(),
          firstEnd: openingHours?.sunday.firstEnd ?? chance.string(),
          secondStart: openingHours?.sunday.secondStart ?? chance.string(),
          secondEnd: openingHours?.sunday.secondEnd ?? chance.string(),
        },
      },
      address: {
        streetNumber: "",
        street: overrides?.address?.street ?? chance.street(),
        zipCode: overrides?.address?.zipCode ?? chance.zip(),
        city: overrides?.address?.city ?? chance.city(),
        country: overrides?.address?.country ?? chance.country(),
      },
      typeOfStore: overrides?.typeOfStore ?? chance.string(),
      deliveryOptions: {
        canDeliver: canDeliver,
        deliveryDescription: canDeliver
          ? overrides?.deliveryOptions?.deliveryDescription ?? chance.string()
          : undefined,
        deliveryType: canDeliver ? deliveryType : undefined,
        localDeliveryRadiusKm:
          canDeliver && deliveryType === LOCAL_DELIVERY
            ? overrides?.deliveryOptions?.localDeliveryRadiusKm ??
              chance.integer({ min: 0, max: 100 })
            : undefined,
        canPickup: canPickup,
        pickupDescription: canPickup
          ? overrides?.deliveryOptions?.pickupDescription ?? chance.string()
          : undefined,
      },
      coordinates: {
        lat:
          overrides?.coordinates?.lat ??
          chance.latitude({ min: 52.396763, max: 52.664932 }),
        lng:
          overrides?.coordinates?.lng ??
          chance.longitude({ min: 13.132843, max: 13.694933 }),
      },
      categories:
        overrides?.categories ?? MockStoreFactory.randomSubCategories(),
      description: overrides?.description ?? chance.paragraph({ length: 255 }),
      contacts: {
        telephone: overrides?.contacts?.telephone ?? chance.phone(),
        websiteUrl: overrides?.contacts?.websiteUrl ?? chance.url(),
        email: overrides?.contacts?.email ?? chance.email(),
      },
      facebookUrl: overrides?.facebookUrl ?? chance.url(),
      twitterUrl: overrides?.twitterUrl ?? chance.url(),
      mainPhotoUrl: "",
      photos: overrides?.photos ?? MockStoreFactory.randomPhotos(5),
      favorite: overrides?.favorite ?? chance.bool(),
      numComments:
        overrides?.numComments ?? chance.integer({ min: 0, max: 100 }),
      numContacts:
        overrides?.numContacts ?? chance.integer({ min: 0, max: 100 }),
      numViews: overrides?.numViews ?? chance.integer({ min: 0, max: 1000000 }),
      numFavorites:
        overrides?.numFavorites ?? chance.integer({ min: 0, max: 1000 }),
      city: "",
      country: "",
      street: "",
      streetNumber: "",
      zipCode: "",
      distance: 0,
      videoUrl: "",
    };
  }

  static getStores(n: number): Store[] {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(MockStoreFactory.newStore());
    }
    return result;
  }

  private static randomPhotos(n: number): Photo[] {
    const chance = new Chance();
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push({
        id: chance.guid(),
        url: chance.pickone(MockStoreFactory.photoUrls),
      });
    }
    return result;
  }

  private static photoUrls = [
    "https://www.einhell.pt/fileadmin/corporate-media/blog/guide/How-to-store-garden-tools-during-winter/blog-guide-store_garden_tools-vorschau.jpg",
    "https://i.pinimg.com/originals/a4/d5/24/a4d524cf904b330d1047b9006c892e46.jpg",
    "https://previews.123rf.com/images/limonzest/limonzest1206/limonzest120600262/14181837-variety-of-colorful-potted-flowers-at-garden-store-green-house.jpg",
    "https://i.pinimg.com/originals/30/d9/fa/30d9faf4aa3203b546d6cfa54418e65f.jpg",
    "https://www.pnrdesign.com/wp-content/uploads/2016/03/green-garden-stock-space-display.jpg",
    "https://thumbs.dreamstime.com/z/garden-shop-seedlings-juniper-bushes-pots-store-nursery-various-green-spruce-plants-gardening-174324323.jpg",
    "https://www.thebalancecareers.com/thmb/R3f9MLIZr9TLhgnPkyd8EpD4YeI=/2121x1414/filters:fill(auto,1)/pet_store-509173668-5ad27f86c0647100380c4f0d.jpg",
  ];

  private static randomSubCategories(): StoreSubCategory[] {
    const chance = new Chance();
    //const { categoryRepository } = useRootRepositoryContext();
    const allCategories = MockCategoryFactory.allMetaCategories();
    //const allCategories = categoryRepository.categories;
    const chosenCategory = chance.pickone(allCategories);

    const maxIdx = Math.max(
      chance.integer({ min: 0, max: chosenCategory.subCategories.length }),
      1
    ); // Choose at least one;
    const result = chosenCategory.subCategories
      .slice(0, maxIdx)
      .map((subCat) => ({
        id: subCat.id,
        name: subCat.name,
        parentCategory: chosenCategory.categoryName,
      }));
    return result;
  }
}
