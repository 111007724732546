import { Box } from "@chakra-ui/react";
import React from "react";

interface Props {
  selectable?: boolean;
  opacity?: number;
  top?: number | string;
  left?: number | string;
}

export const RightTriangle: React.FC<Props> = ({
  selectable,
  top,
  left,
  opacity,
}) => (
    <Box
      position="absolute"
      top={top ?? 196}
      left={left ?? 50}
      width={0}
      height={0}
      borderStyle="solid"
      borderWidth="19.5px 0 19.5px 28px;"
      borderColor={`transparent transparent transparent #EB5757`}

      borderTop={"19.5px solid transparent"}
      borderRight={"0 solid transparent"}
      borderBottom={"19.5px solid transparent"}
      borderLeft={"28px solid transparent"}
      borderLeftColor={"brandRed.300"}

      opacity={opacity ?? selectable ? 1 : 0.15}
      _hover={{ cursor: selectable ? "pointer" : undefined }}
    />
  );
