import React from "react";
import { compose } from "recompose";
import i18next from "i18next";
import { Text } from "@chakra-ui/react";
import { useRootRepositoryContext } from "rootRepository";
import { ContentArea } from "app/shared";
import CreateStoreWindowForm from "app/storeWindows/forms/CreateStoreWindowForm";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Params {
  storeId: string;
}

type Props = RouteComponentProps<Params>;

const CreateProductPageBase: React.FC<Props> = ({ match }) => {
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;
  const storeId = match.params.storeId;

  return (
    <ContentArea>
      {user ? (
        <CreateStoreWindowForm userId={user.id} storeId={storeId} />
      ) : (
        <Text>{i18next.t("shared:loginRequired")}</Text>
      )}
    </ContentArea>
  );
};

export default compose<Props, {}>(withRouter)(CreateProductPageBase);
