import { Grid, GridProps, PositionProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";

const NavbarArea = styled(Grid)<GridProps & PositionProps>`
  position: sticky;
  height: ${themeGet("sizes.navbarHeight.sm")};
  ${themeGet("mediaQueries.md")} {
    height: ${themeGet("sizes.navbarHeight.lg")};
  }
  z-index: 2;
`;

NavbarArea.defaultProps = {
  gridArea: "navbar",
  backgroundColor: "navbar.background",
  top: 0,
};

export default NavbarArea;
