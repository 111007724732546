import { Flex, Stack, Box } from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { Divider } from "../divider";
import { TextLink } from "../textLink";
import { ButtonLink } from "../buttonLink";
import { Image } from "@chakra-ui/react";
import logo from "./static/logo.svg";

import { useRootRepositoryContext } from "rootRepository";

export const Footer: React.FC = () => {
  const { cityContentRepository } = useRootRepositoryContext();

  let instanzLogoUrl = cityContentRepository.GetAppSettingEntry("000-Footer-LogoInstanz");
  let footerLinksArray:any = [];

  let footerLinks = cityContentRepository.GetAppSettingEntry("_sysFooterLinks");
  if (footerLinks && footerLinks.length > 0) {
    footerLinksArray = JSON.parse(footerLinks)?.footerLinks;
  }

  const linkCount = footerLinksArray?.length ?? 0;

  return (
    <Flex width={"100%"} maxWidth={["maxWidth"]} mx="auto" flexDirection={["column", "column", "row"]} justifyContent={["flex-start", "flex-start", "space-between"]} pl={"10px"} pr={"10px"}>
      <Stack py={6} direction="column" flexWrap="wrap" px={[6, 6, 0]} justifyContent="flex-end">
        <Stack direction="row" justifyContent={"flex-start"}>
          <ButtonLink
            to="/"
            variant="unstyled"
            width={"180px"}

            display="table"
            _focus={{ textDecoration: "none" }}>
            {instanzLogoUrl && instanzLogoUrl.length > 0 && (
              <Image
                height="auto"
                width="100%"
                src={instanzLogoUrl}
                alt="site-logo"
                paddingBottom="5px"
              />
            )}
          </ButtonLink>
        </Stack>
        <Stack py={0} direction="row" flexWrap="wrap" px={0} >
          {
            footerLinksArray.map((item: any, index: number) => (
              < Box display="flex" margin="0" key={"fl_" + index}>
                <TextLink to={item.url} fontWeight="bold" color="footer.color" fontSize="lg" >
                  {item.text}
                </TextLink>
                {(index < (linkCount - 1)) && (
                  <Divider color="footer.color" ml="0.5rem" />
                )}
              </Box>
            )
            )
          }

        </Stack>
      </Stack>

      <Stack pt={[0, 0, 6]} pb={"6"} direction="column" flexWrap="wrap" px={[6, 6, 0]} justifyContent="flex-end">
        <Stack direction="row" justifyContent={["flex-start", "flex-start", "flex-end"]}>
          <ButtonLink
            to="/"
            variant="unstyled"
            width={"180px"}

            display="table"
            _focus={{ textDecoration: "none" }}>

            <Image
              height="auto"
              width="100%"
              src={logo}
              alt="site-logo"
              paddingBottom="5px"
            />
          </ButtonLink>
        </Stack>
        <Stack py={0} direction="row" flexWrap="wrap" px={0} >
          <TextLink to="/Datenschutzerklaerung" fontWeight="bold" color="footer.color" fontSize="lg">
            {i18next.t("shared:footer.privacyPolicy")}
          </TextLink>
          <Divider color="footer.color" />
          <TextLink to="/Impressum" fontWeight="bold" color="footer.color" fontSize="lg">
            {i18next.t("shared:footer.impressum")}
          </TextLink>
        </Stack>
      </Stack>
    </Flex>
  )
};
