import { Grid, Stack, Text, Box, Flex } from "@chakra-ui/react";
import { StoreComment, UserComment, CommentId } from "app/comments/models";
import { getCommentsForUser, deleteComment } from "app/comments/services";
import { CommentView } from "app/comments/storeCommentsGrid/CommentView";
import { LayoutGrid, Popup, Button, Heading } from "app/shared";
import { StoreCard } from "app/stores";
import i18next from "i18next";
import React, { useState } from "react";
import { UserId } from "../models";
import { SearchForUserParam } from "../../search/models";
import { useRootRepositoryContext } from "../../../rootRepository";
import { CloseIcon } from "@chakra-ui/icons";

interface ViewProps {
  comment: UserComment;
}

const View: React.FC<ViewProps> = ({ comment }) => {
  const formattedComment: StoreComment = {
    commentId: comment.commentId,
    username: comment.username,
    userId: comment.userId,
    rating: comment.rating,
    comment: comment.comment,
    date: comment.date,
  };

  const [deleted, setdeleted] = useState(false);

  const onPopupDeleteClose = () => {
    setdeleted(true);
  };

  if (!deleted) {
    return (
      <Grid templateColumns="596px auto" columnGap={15}>
        <StoreCard mr={10} store={comment.store} showTriangle={true} />
        <Stack direction="column" justifyContent="space-between">
          <CommentView
            comment={formattedComment}
            ratingActiveColor="darkGrey"
            ratingInactiveColor="brandRed.300"
            commentMaxHeight="120px"
          />
          <Stack direction="row" justifyContent="flex-start" spacing={2}>
            <DeleteCommentBtn
              comment={formattedComment}
              onPopupDeleteClose={onPopupDeleteClose}
            />
            <Text fontSize="lg" color="brandRed.300" pl={2}>
              {i18next.t("users:profile.ownedCommentsGrid.delete")}
            </Text>
          </Stack>
        </Stack>
      </Grid>
    );
  } else {
    return (
      <Flex direction="column" mr={10}>
        <Text fontSize="lg" color="brandRed.300" pr={2}>
          {i18next.t("users:profile.ownedCommentsGrid.deleted")}
        </Text>
      </Flex>
    );
  }
};

const DeleteCommentBtn: React.FC<{
  comment: StoreComment;
  onPopupDeleteClose: () => void;
}> = ({ comment, onPopupDeleteClose }) => (
  <Popup
    trigger={(onClose) => (
      <Box
        bg="brandRed.300"
        px={3}
        py={1}
        _hover={{ cursor: "pointer", bg: "brandRed.100" }}
        onClick={onClose}
      >
        <CloseIcon color="white" boxSize={"13px"} />
      </Box>
    )}
    content={(onClose) => (
      <DeleteCommentConfirmDialog
        closePopup={() => {
          onClose();
          onPopupDeleteClose();
        }}
        commentId={comment.commentId}
      />
    )}
    contentMaxWidth={["800px"]}
  />
);

interface DeleteCommentConfirmDialogProps {
  commentId: CommentId;
  closePopup: () => void;
}
const DeleteCommentConfirmDialog: React.FC<DeleteCommentConfirmDialogProps> = ({
  commentId,
  closePopup,
}) => {
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;
  return (
    <Box mb={1}>
      <Heading>
        {i18next.t("users:profile.ownedCommentsGrid.deletePopup.title")}
      </Heading>
      <Text fontSize="lg" color="darkGrey">
        {i18next.t("users:profile.ownedCommentsGrid.deletePopup.message")}
      </Text>
      <Button
        w="200px"
        mt={1}
        onClick={() => {
          deleteComment(commentId).then(() => {
            if (user?.ownedComments) {
              user.ownedComments = user?.ownedComments * 1 - 1;
            }
            closePopup();
          });
        }}
      >
        {i18next.t("users:profile.ownedCommentsGrid.deletePopup.button")}
      </Button>
    </Box>
  );
};

interface Props {
  userId: UserId;
}

export const OwnedCommentsGrid: React.FC<SearchForUserParam> = ({ userId }) => (
  <LayoutGrid
    fetchId={userId}
    fetchItems={getCommentsForUser}
    loadMore={i18next.t("users:profile.ownedComments.loadMore")}
    numColumns={1}
    view={(item: UserComment) => <View comment={item} />}
    emptyStateView={() => (
      <Text fontSize="lg" color="darkGrey" fontWeight="bold" pt={20}>
        {i18next.t("users:profile.ownedCommentsGrid.emptyState")}
      </Text>
    )}
    hideBottomButton={true}
  />
);
