import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/react";
import { SiteImageButton } from "app/shared/siteImageButton";
import i18next from "i18next";
import React from "react";
import { NavbarActions } from "../navbarActions";
import { DrawerMenuLink } from "./DrawerMenuLink";
import { useRootRepositoryContext } from "rootRepository";

interface Props {
  onClose?: () => void;
}

export const DrawerMenuContent: React.FC<Props> = ({ onClose }) => {
  const { cityContentRepository } = useRootRepositoryContext();

  const visiblePageList =
    cityContentRepository.GetAppSettingEntry("_sysBurgerNavItems");
  const navText = cityContentRepository.GetCityContentEntry("000-B000-NavText");

  const navContentInfo: any = {
    forStore: false,
    login: false,
    storeSearch: false,
    storeCreate: false,
    forUsers: false,
    about: false,
    user: false,
    logout: false,
    news: false,
    contact: false,
  };

  if (visiblePageList) {
    const linkList: [] = visiblePageList.split(";");
    for (let i = 0; i < linkList.length; i++) {
      const value = (linkList[i] as string).toString().trim();
      if (navContentInfo[value] !== undefined) {
        navContentInfo[value] = true;
      }
    }
  }

  return (
    <Stack py={2} direction="column" maxW="800px">
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={10}
      >
        <SiteImageButton
          onClick={onClose}
          width="200px"
          isInDrawerMenu={true}
        />
        <NavbarActions color="navbar.drawer.color" onClose={onClose} />
      </Flex>

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" py={2}>
          {navContentInfo?.forStore && (
            <DrawerMenuLink
              href="/fur-geschafte"
              name={i18next.t("shared:navbar.burgerDrawer.forStore")}
              onClick={onClose}
              iconVisible={false}
            />
          )}
          {navContentInfo?.forUsers && (
            <DrawerMenuLink
              href="/fur-nutzer"
              name={i18next.t("shared:navbar.burgerDrawer.forUsers")}
              onClick={onClose}
              iconVisible={false}
            />
          )}
          {navContentInfo?.about && (
            <DrawerMenuLink
              href="/about"
              name={i18next.t("shared:navbar.burgerDrawer.about")}
              onClick={onClose}
              iconVisible={false}
            />
          )}
          {navContentInfo?.news && (
            <DrawerMenuLink
              href="/NEWS"
              name={i18next.t("shared:navbar.burgerDrawer.news")}
              onClick={onClose}
              iconVisible={false}
            />
          )}
          {navContentInfo?.contact && (
            <DrawerMenuLink
              href="/kontakt"
              name={i18next.t("shared:navbar.burgerDrawer.contact")}
              onClick={onClose}
              iconVisible={false}
            />
          )}
        </Stack>
        <Divider
          orientation="vertical"
          color="navbar.drawer.color"
          borderColor="navbar.drawer.color"
        />
        <Box width="50%" py={4}>
          <Text
            fontSize={"lg"}
            dangerouslySetInnerHTML={{ __html: navText }}
          ></Text>
        </Box>
      </Stack>
    </Stack>
  );
};
