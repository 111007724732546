import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import { SubCategory } from "app/categories/models";
import { Select } from "app/shared";
import { GoogleSearchBoxNoMap } from "app/shared/maps/GoogleSearchBoxNoMap";
import { Coords } from "app/shared/maps/models";
import i18next from "i18next";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useRootRepositoryContext } from "rootRepository";
import { createSearchUrl, getSubCategoriesForParam } from "../utils";
import { useSearchRepositoryContext } from "../repositories";
import { StyledInput } from "app/shared/forms/StyledComponents";

interface Props extends BoxProps, RouteComponentProps { }

const SearchInputsGroupBase: React.FC<Props> = ({ history, ...rest }) => {
  const { categoryRepository } = useRootRepositoryContext();
  const { searchRepository } = useSearchRepositoryContext();

  const [location, setLocation] = useState<Coords | undefined>();
  const [categoryId, setCategory] = useState<string>("");
  const [subCategoryId, setSubCategory] = useState<string>("");
  const [selectSubCategoryValue, setSelectSubCategoryValue] = useState<any>(null);
  const [searchOptionsSubCategories, setSearchOptionsSubCategories] = useState<any>([]);
  const [freeTextSearch, setFreeTextSearch] = useState<string>("");
  const [googleSearch, setGoogleSearch] = useState<string>("");

  const defaultOption = { value: "", label: "Subkategorie wählen?" };

  const doSearch = () => {
    var subCategories: SubCategory[];

    if (subCategoryId) {
      subCategories = categoryRepository.categories.find((c) => c.id === categoryId)?.subCategories.filter((a) => a.id === subCategoryId) ?? [];
    }
    else {
      subCategories = getSubCategoriesForParam(
        categoryId,
        categoryRepository.categories
      );
    }

    let zoom = 10;

    const searchUrl = createSearchUrl(categoryId, subCategories, location, zoom, freeTextSearch, googleSearch);
    history.push(searchUrl);
  };

  const searchOptions = categoryRepository.categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName)).map((category) => ({
    value: category.id,
    label: category.categoryName,
  }));

  const loadSubCategories = (selectedCategoryId: any) => {
    let s = getSubCategoriesForParam(
      selectedCategoryId,
      categoryRepository.categories).sort((a, b) => a.name.localeCompare(b.name)).map((category) => ({
        value: category.id,
        label: category.name,
      }));
    setSearchOptionsSubCategories(s);

    setSelectSubCategoryValue(defaultOption);
  };

  const handlefreeTextSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setFreeTextSearch(e.currentTarget.value);
  };

  const handleGoogleBoxInput = (value: string) => {
    setGoogleSearch(value);
    //console.log(value);
  }

  return (
    <Flex
      w={["100%", "100%", "100%", "80%", "55%"]}
      direction="column"
      zIndex={0}>
      <Box
        bg="white"
        opacity={0.9}
        boxShadow="0px 3px 20px rgba(0, 0, 0, 0.2)"
        pt={[6, 6, 12]}
        pb={[6, 6, 16]}
        px={[6, 6, "72px"]}
        height="100%"
        {...rest}>
        <Text fontSize="lg" pb={8}>
          <Text as="span" fontWeight="bold">
            {i18next.t("search:searchInputsGroup.headerBold")}
          </Text>{" "}
          {i18next.t("search:searchInputsGroup.headerNormal")}
        </Text>

        <Box height={3}></Box>
        <StyledInput
          width="100%"
          type="text"
          fontSize="lg"
          placeholder={i18next.t("search:searchInputsGroup.freeTextSearch")}
          variant="unstyled"
          px={3}
          py={2}
          bg="white"
          onKeyUp={(e: React.FormEvent<HTMLInputElement>) => handlefreeTextSearch(e)}
        />
        <Box height={3}></Box>
        <Select
          onChange={(options) => {
            setCategory(options.value);
            loadSubCategories(options.value);
          }}
          options={[{ value: "", label: "Alle Kategorien" }, ...searchOptions]}
          width={80}
          defaultValue={{ value: "", label: "Alle Kategorien" }}
          placeholder={i18next.t("search:searchInputsGroup.selectPlaceholder")}
        />
        <Box height={3}></Box>
        <Select
          disabled={categoryId && searchOptionsSubCategories ? false : true}
          onChange={(options) => {
            setSubCategory(options.value);
            setSelectSubCategoryValue(options);
          }}
          value={selectSubCategoryValue}
          options={[{ value: "", label: i18next.t("search:searchInputsGroup.selectPlaceholder2") }, ...searchOptionsSubCategories]}
          width={80}
          placeholder={i18next.t("search:searchInputsGroup.selectPlaceholder2")}
        />
        <Box height={3}></Box>
        <GoogleSearchBoxNoMap onChange={setLocation} handleChange={handleGoogleBoxInput} />
      </Box>
      <Box
        bg="brandRed.300"
        boxShadow="-5px 4px 20px rgba(0, 0, 0, 0.25)"
        py={2}
        _hover={{ cursor: "pointer" }}
        onClick={doSearch}>
        <Text textAlign="center" color="white" fontSize="3xl" fontWeight="bold">
          {i18next.t("search:searchInputsGroup.search")}
        </Text>
      </Box>
    </Flex >
  );
};

export const SearchInputsGroup = compose<Props, BoxProps>(
  withRouter,
  observer
)(SearchInputsGroupBase);


