import { Stack, Box } from "@chakra-ui/react";
import { TextLink } from "app/shared/textLink";
import i18next from "i18next";
import React from "react";
import { MenuWrapper } from "../MenuWrapper";
import { User } from "app/users/models";

interface Props {
  user: User;
  onLogout: () => void;
  onClose?: () => void;
}

export const ProfileMenuActions: React.FC<Props> = ({
  user,
  onLogout,
  onClose,
}) => {
  return (
    <MenuWrapper
      maxWidth={"220px"}
      backgroundColor="navbar.profileMenu.background"
    >
      <Stack direction="column" spacing={3}>
        <Box
          px={1}
          py={2}
          borderBottom="1px solid"
          borderColor="navbar.profileMenu.text.color"
        >
          <TextLink
            px={4}
            to={`/user`}
            onClick={onClose}
            fontWeight="bold"
            color="navbar.profileMenu.text.color"
            fontSize={["sm", "md"]}
            _hover={{
              cursor: "pointer",
              color: "navbar.profileMenu.text.hover",
            }}
          >
            {i18next.t("shared:navbar.profileMenu.profile")}
          </TextLink>
        </Box>
        <Box px={1} py={2}>
          <Box
            px={4}
            onClick={() => {
              onLogout();
              if (onClose) onClose();
            }}
            fontWeight="bold"
            color="navbar.profileMenu.text.color"
            fontSize={["sm", "md"]}
            _hover={{
              cursor: "pointer",
              color: "navbar.profileMenu.text.hover",
            }}
          >
            {i18next.t("shared:navbar.profileMenu.signout")}
          </Box>
        </Box>
      </Stack>
    </MenuWrapper>
  );
};
