import React from "react";
import { CheckboxProps } from "@chakra-ui/react";
import { GenericInput } from "app/shared/forms/GenericInput";
import { GenericCheckbox } from "app/shared/forms/GenericCheckbox";
import { GenericSelect } from "app/shared/forms/GenericSelect";

interface InputFieldProps {
  fieldName: string;
  fieldType: string;
  Component: any;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
  flexDirection?: string;
}

export const ProductInputField: React.FC<InputFieldProps> = (
  props: InputFieldProps
) => {
  return <GenericInput i18nextPrefix="products" {...props} />;
};

interface CheckboxFieldProps extends CheckboxProps {
  fieldName: string;
  fieldType: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
}

export const ProductCheckboxField: React.FC<CheckboxFieldProps> = (props) => {
  return <GenericCheckbox i18nextPrefix="products" {...props} />;
};

interface SelectFieldProps {
  fieldName: string;
  fieldType: string;
  options: string[];
  disabled?: boolean;
  flexDirection?: string;
}

export const ProductSelectField: React.FC<SelectFieldProps> = (
  props: SelectFieldProps
) => <GenericSelect i18nextPrefix="products" {...props} />;
