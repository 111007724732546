import { CloseIcon } from "@chakra-ui/icons";
import { BoxProps, Box } from "@chakra-ui/react";
import React from "react";

interface Props extends BoxProps {
  iconSize?: string;
  iconColor?: string;
}

export const CloseButton: React.FC<Props> = ({
  onClick,
  iconSize,
  iconColor,
  ...rest
}) => (
    <Box _hover={{ cursor: "pointer" }} mt={1} color="white" py={1} px={2} bg="brandRed.300" onClick={onClick} {...rest}>
    <CloseIcon color={"white"} boxSize={iconSize} />
  </Box>
);
