import React from "react";
import { defaultEmptyState } from "./ProductInfoSchema";
import { CreateFormImageUploader } from "app/shared/forms/imageUploader/CreateFormImageUploader";
import { BaseProductForm } from "./BaseProductForm";

/*
  Exports a customized version of the base product form for the business creation use-case.
  */
const CreateProductForm: React.FC = () => {
  return (
    <BaseProductForm
      initialState={defaultEmptyState}
      imageUploaderComponent={CreateFormImageUploader}
    />
  );
};

export default CreateProductForm;
