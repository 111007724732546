import { BoxProps, Box, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { PlusIcon, PlusMenuIcon } from "styles/icons";

interface Props extends BoxProps {
  iconSize?: string;
  onClick?: () => void;
  isInNavBarMenu?: boolean;
}

export const AddBusinessButton: React.FC<Props> = ({
  iconSize,
  onClick,
  isInNavBarMenu,
  color,
  ml,
  mt,
  mr,
  mb,
  ...rest
}) => (
  <Box
    _hover={{ cursor: "pointer" }}
    onClick={onClick}
    ml={ml}
    mr={mr}
    mb={mb}
    mt={mt}>
    <Stack alignItems="center" direction="row">
      {isInNavBarMenu ? (
        <PlusMenuIcon boxSize={iconSize ?? undefined} />
      ) : (
        <PlusIcon boxSize={iconSize ?? undefined} />
      )}
      <Text
        color={color ?? "brandRed.300"}
        fontWeight="bold"
        fontSize="xl"
        {...rest}>
        Geschäft eintragen
      </Text>
    </Stack>
  </Box>
);
