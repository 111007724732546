import { default as Axios } from "axios";
import environment from "configurations";
import Cookies from "js-cookie";
import { IUserLoginResponseSchema } from "../app/users/forms/UserFormSchema";
import { toast } from "react-toastify";
import React from 'react';


let key;
let filePath = '../config/config.json';
var xmlhttp = new XMLHttpRequest();
xmlhttp.open("GET", filePath, false);
xmlhttp.send();
if (xmlhttp.status == 200 && xmlhttp.readyState == 4) {
  key = JSON.parse(xmlhttp.responseText).key;
}

// Create axios instances
const configuration = {
  baseURL: `${environment.api}`,
  headers: {
    "Content-Type": "application/json",
    "Instanz": `${key}`
  },
};

export const axios = Axios.create(configuration);

let toastId: any = null;
let ErrorCalled: boolean = false;

axios.interceptors.request.use(
  config => {
    var tokenString = Cookies.get(`${environment.cookieKey}`);
    if (tokenString != undefined) {
      const token: IUserLoginResponseSchema = JSON.parse(tokenString) as IUserLoginResponseSchema;

      if (token != null) {
        config.headers.Authorization = `Bearer ${token.access_token}`;
      }
    }

    return config;
  },
  err => {
    try {
      if (!ErrorCalled) {
        ErrorCalled = true;
        postError(err);

        // to report only 1 error every 3 seconds
        setTimeout(() => {
          ErrorCalled = false;
        }, 3000)
      }
    } catch (e) {

    }

    if (!toastId) {
      toastId = toast("Es ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut...", { type: "error" });
    }

    if (toastId) {
      setTimeout(() => {
        toastId = null;
        toast.clearWaitingQueue();
      }, 5000)
    }

    return Promise.reject(err);
  }
);

axios.interceptors.response.use(response => {
  return response;
}, error => {
  try {
    if (!ErrorCalled) {
      ErrorCalled = true;
      postError(error);

      // to report only 1 error every 3 seconds
      setTimeout(() => {
        ErrorCalled = false;
      }, 3000)
    }
  } catch (e) {

  }

  if (error?.response?.status === 401) {
    //place your reentry code
    console.log(error);
    if (!toastId) {
      toastId = toast("Anmeldung abgelaufen, Sie werden zur Startseite weitergeleitet...", { type: "error" });
    }

    // delete cookie if still exists
    var tokenString = Cookies.get(`${environment.cookieKey}`);
    if (tokenString != undefined) {
      Cookies.remove(`${environment.cookieKey}`);
    }

    setTimeout(() => {
      window.location.href = "/";
    }, 5000)
  }
  else if (!toastId) {
    toastId = toast("Es ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut...", { type: "error" });
  }

  if (toastId) {
    setTimeout(() => {
      toastId = null;
      toast.clearWaitingQueue();
    }, 5000)
  }

  return error;
});

export const postError = async (values: any):
  Promise<null> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Home/PostError`, values)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        console.log(errorMessage);

      }).
      finally(() => {

      });;
  });
};

export default axios;
