import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalBody,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import i18next from "i18next";
import { CloseButton, Button } from "app/shared";

interface ModalValidationProps {
  setShowModalValidation: (value: boolean) => void;
  validationMessages: string;
}

export const ValidationPopup: React.FC<ModalValidationProps> = ({
  setShowModalValidation,
  validationMessages,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isOpen) {
    onOpen();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent bg="white" maxW={["800px"]}>
        <ModalHeader>
          <Heading>{i18next.t("shared:popups.titleValidation")}</Heading>
        </ModalHeader>
        <CloseButton
          onClick={() => {
            onClose();
            setShowModalValidation(false);
          }}
          iconSize={"13px"}
          position="absolute"
          top={0}
          right={1}
          iconColor="brandRed.300"
        />
        <ModalBody mb={["20px"]}>
          <div dangerouslySetInnerHTML={{ __html: validationMessages }} />
          <br />
          <br />
          <Box alignContent="center" textAlign="center" width="250px">
            <Button
              onClick={(e) => {
                onClose();
                e.preventDefault();
                setShowModalValidation(false);
              }}
              color="white"
              fontWeight="bold"
            >
              {i18next.t("shared:popups.backToEdit")}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
