import * as Yup from "yup";
import i18next from "i18next";

import { Product } from "../models";
import { LOCAL_DELIVERY, NATIONAL_DELIVERY } from "app/shared/models";
import { filesSizeChecker, filesTypeChecker } from "app/shared/utils/FileUtils";

const TEN_MB = 10 * 1024 * 1024;
const VALID_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png"];

export const ProductFormSchema = Yup.object({
  id: Yup.string(),
  photos: Yup.array()
    .required(i18next.t(`products:form.validation.requiredImage`))
    .test(
      "checkFileSize",
      i18next.t(`products:form.validation.fileTooLarge`),
      filesSizeChecker(TEN_MB)
    )
    .test(
      "checkExtension",
      i18next.t(`products:form.validation.invalidFileType`),
      filesTypeChecker(VALID_MIME_TYPES)
    ),
  name: Yup.string()
    .min(3, i18next.t(`products:form.validation.min`))
    .max(255, i18next.t(`products:form.validation.max`))
    .required(i18next.t(`products:form.validation.requiredName`)),
  price: Yup.string()
    .required(i18next.t(`products:form.validation.requiredPrice`))
    .test(
      "maxDigitsAfterDecimal",
      i18next.t(`products:form.validation.invalidPriceFormat`),
      (price) => /^\d+(\,\d{2})?$/.test(price)
    ),
  description: Yup.string(),
  link: Yup.string().max(255, i18next.t(`products:form.validation.max`)),
  stock: Yup.string(),
  // Delivery options
  canDeliver: Yup.boolean().optional(),
  deliveryDescription: Yup.string()
    .nullable()
    .optional()
    .when("canDeliver", {
      is: true,
      then: Yup.string()
        .notRequired()
        .min(3, i18next.t(`products:form.validation.min`))
        .max(255, i18next.t(`products:form.validation.max`)),
      otherwise: Yup.string()
        .notRequired()
        .min(0, i18next.t(`products:form.validation.min`))
        .max(0, i18next.t(`products:form.validation.max`)),
    }),
  deliveryType: Yup.string()
    .optional()
    .when("canDeliver", {
      is: true,
      then: Yup.string().oneOf(
        [LOCAL_DELIVERY, NATIONAL_DELIVERY],
        "Bitte wählen Sie eine der Optionen aus"
      ),
    }),
  localDeliveryRadiusKm: Yup.number()
    .optional()
    .when(["canDeliver", "deliveryType"], {
      is: (canDeliver, deliveryType) =>
        canDeliver && deliveryType === LOCAL_DELIVERY,
      then: Yup.number()
        .min(0, i18next.t(`products:form.validation.min`))
        .required(
          i18next.t(`products:form.validation.requiredLocalDeliveryRadiusKm`)
        ),
      otherwise: Yup.number()
        .min(0, i18next.t(`products:form.validation.min`))
        .max(0, i18next.t(`products:form.validation.max`))
        .notRequired(),
    }),
  // Pickup options
  canPickup: Yup.boolean().required(),
  pickupDescription: Yup.string()
    .nullable()
    .optional()
    .when("canPickup", {
      is: true,
      then: Yup.string()
        .notRequired()
        .min(3, i18next.t(`products:form.validation.min`))
        .max(255, i18next.t(`products:form.validation.max`)),
      otherwise: Yup.string()
        .notRequired()
        .min(0, i18next.t(`products:form.validation.min`))
        .max(0, i18next.t(`products:form.validation.max`)),
    }),
  categories: Yup.array().of(Yup.string()),
  subCategories: Yup.array().of(Yup.string()),
}).defined();

export type ProductFormViewModel = Yup.InferType<typeof ProductFormSchema>;

export const model2ViewModel = (product: Product): ProductFormViewModel => ({
  id: product.id,
  photos: product.photos,
  name: product.name,
  price: product.price,
  description: product.description,
  link: product.link,
  stock: product.stock,
  canDeliver: product.deliveryOptions.canDeliver,
  deliveryDescription: product.deliveryOptions.deliveryDescription,
  deliveryType: product.deliveryOptions.deliveryType,
  localDeliveryRadiusKm: product.deliveryOptions.localDeliveryRadiusKm,
  canPickup: product.deliveryOptions.canPickup,
  pickupDescription: product.deliveryOptions.pickupDescription,
  categories: product.categories
    .map((c) => c.parentCategory)
    .filter((value, index, self) => self.indexOf(value) === index),
  subCategories: product.categories.map((it) => it.id),
});

export const defaultEmptyState: ProductFormViewModel = {
  id: "",
  photos: [],
  name: "",
  price: "",
  description: "",
  link: "",
  stock: "",
  canDeliver: false,
  deliveryDescription: undefined,
  deliveryType: undefined,
  localDeliveryRadiusKm: undefined,
  canPickup: false,
  pickupDescription: undefined,
  categories: [],
  subCategories: [],
};

export const defaultEmptyStatePreProd: ProductFormViewModel = {
  id: "",
  photos: [
    {
      id: "1",
      url: "https://image.shutterstock.com/shutterstock/photos/1938455998/display_1500/stock-vector-inflatable-toy-flamingo-d-realistic-vector-object-summer-icon-1938455998.jpg",
    },
    {
      id: "2",
      url: "https://image.shutterstock.com/shutterstock/photos/1938455998/display_1500/stock-vector-inflatable-toy-flamingo-d-realistic-vector-object-summer-icon-1938455998.jpg",
    },
  ],
  name: "TEST",
  price: "9,99",
  description: "Das ist ein Testprodukt",
  link: "https://www.test.de",
  stock: "available",
  canDeliver: false,
  deliveryDescription: undefined,
  deliveryType: undefined,
  localDeliveryRadiusKm: undefined,
  canPickup: false,
  pickupDescription: undefined,
  categories: [],
  subCategories: [],
};
