import { Box } from "@chakra-ui/react";
import React from "react";

interface Props {
  selectable?: boolean;
  top?: number | string;
  left?: number | string;
  opacity?: number;
}



export const BottomTriangle: React.FC<Props> = ({
  selectable,
  top,
  left,
  opacity,
}) => (
    <Box
      position="absolute"
      top={top ?? 196}
      left={left ?? 50}
      width={0}
      height={0}
      borderLeft={"14.86px solid transparent"}
      borderRight={"14.86px solid transparent"}
      borderBottom={"0 solid transparent"}
      borderTop={"26.04px solid"}
      borderTopColor={"brandRed.300"}
     
      opacity={opacity ?? selectable ? 1 : 0.15}
      _hover={{ cursor: selectable ? "pointer" : undefined }}
    />
  );
