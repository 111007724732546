import * as Yup from "yup";
import { OpeningHoursWeek, Store } from "../models";
import { LOCAL_DELIVERY, NATIONAL_DELIVERY } from "app/shared/models";
import { filesSizeChecker, filesTypeChecker } from "app/shared/utils/FileUtils";

import i18next from "i18next";

const TEN_MB = 10 * 1024 * 1024;
const VALID_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png"];

const OpeningHoursDayFormSchema = Yup.object({
  firstStart: Yup.string()
    .nullable()
    .test(
      "timeFormat",
      i18next.t(`stores:form.validation.invalidTimeFormat`),
      (time) =>
        !time ? true : /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)
    ),
  firstEnd: Yup.string()
    .nullable()
    .test(
      "timeFormat",
      i18next.t(`stores:form.validation.invalidTimeFormat`),
      (time) =>
        !time ? true : /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)
    ),
  secondStart: Yup.string()
    .nullable()
    .test(
      "timeFormat",
      i18next.t(`stores:form.validation.invalidTimeFormat`),
      (time) =>
        !time ? true : /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)
    ),
  secondEnd: Yup.string()
    .nullable()
    .test(
      "timeFormat",
      i18next.t(`stores:form.validation.invalidTimeFormat`),
      (time) =>
        !time ? true : /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)
    ),
})
  .required()
  .defined();

export const StoreFormSchema = Yup.object({
  id: Yup.string(),
  address: Yup.string(),
  name: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(255, i18next.t(`stores:form.validation.max`))
    .required(i18next.t(`stores:form.validation.requiredName`)),
  //addressDetails: Yup.object({
  openingHours: Yup.object<OpeningHoursWeek>({
    monday: OpeningHoursDayFormSchema,
    tuesday: OpeningHoursDayFormSchema,
    wednesday: OpeningHoursDayFormSchema,
    thursday: OpeningHoursDayFormSchema,
    friday: OpeningHoursDayFormSchema,
    saturday: OpeningHoursDayFormSchema,
    sunday: OpeningHoursDayFormSchema,
  }).required(),
  streetNumber: Yup.string()
    .min(1, i18next.t(`stores:form.validation.min`))
    .max(15, i18next.t(`stores:form.validation.max`))
    .required(i18next.t(`stores:form.validation.requiredStreetNumber`)),
  street: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(250, i18next.t(`stores:form.validation.max`))
    .required(i18next.t(`stores:form.validation.requiredStreet`)),
  zipCode: Yup.string()
    .min(4, i18next.t(`stores:form.validation.min`))
    .max(5, i18next.t(`stores:form.validation.max`))
    .required(i18next.t(`stores:form.validation.requiredZipCode`)),
  city: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(250, i18next.t(`stores:form.validation.max`))
    .required(i18next.t(`stores:form.validation.requiredCity`)),
  country: Yup.string().required(
    i18next.t(`stores:form.validation.requiredCountry`)
  ),
  //}).required(i18next.t(`stores:form.validation.requiredrequiredAddress`))
  coordinates: Yup.object({
    lat: Yup.number().min(-90).max(90).required(),
    lng: Yup.number().min(-180).max(180).required(),
  }),
  description: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .required(i18next.t(`stores:form.validation.requiredDescription`)),
  typeOfStore: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(255, i18next.t(`stores:form.validation.max`))
    .required(i18next.t(`stores:form.validation.requiredTypeOfStore`)),
  // Delivery options
  canDeliver: Yup.boolean().optional(),
  deliveryDescription: Yup.string()
    .nullable()
    .optional()
    .when("canDeliver", {
      is: true,
      then: Yup.string()
        .notRequired()
        .min(3, i18next.t(`stores:form.validation.min`))
        .max(255, i18next.t(`stores:form.validation.max`)),
      otherwise: Yup.string()
        .notRequired()
        .min(0, i18next.t(`products:form.validation.min`))
        .max(0, i18next.t(`products:form.validation.max`)),
      //.required(i18next.t(`stores:form.validation.required`))
    }),
  deliveryType: Yup.string()
    .optional()
    .when("canDeliver", {
      is: true,
      then: Yup.string().oneOf(
        [LOCAL_DELIVERY, NATIONAL_DELIVERY],
        "Bitte wählen Sie eine der Optionen aus"
      ),
    }),
  localDeliveryRadiusKm: Yup.number()
    .optional()
    .when(["canDeliver", "deliveryType"], {
      is: (canDeliver, deliveryType) =>
        canDeliver && deliveryType === LOCAL_DELIVERY,
      then: Yup.number()
        .min(0, i18next.t(`stores:form.validation.min`))
        .required(
          i18next.t(`stores:form.validation.requiredLocalDeliveryRadiusKm`)
        ),
      otherwise: Yup.number()
        .min(0, i18next.t(`stores:form.validation.min`))
        .max(0, i18next.t(`stores:form.validation.max`))
        .notRequired(),
    }),
  // Pickup options
  canPickup: Yup.boolean().required(),
  pickupDescription: Yup.string()
    .nullable()
    .optional()
    .when("canPickup", {
      is: true,
      then: Yup.string()
        .notRequired()
        .min(3, i18next.t(`stores:form.validation.min`))
        .max(255, i18next.t(`stores:form.validation.max`)),
      //.required(i18next.t(`stores:form.validation.required`))
      otherwise: Yup.string()
        .notRequired()
        .min(0, i18next.t(`stores:form.validation.min`))
        .max(0, i18next.t(`stores:form.validation.max`)),
    }),
  // Contact options
  email: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(255, i18next.t(`stores:form.validation.max`))
    .email(i18next.t(`stores:form.validation.email`))
    .required(i18next.t(`stores:form.validation.requiredEmail`)),
  phoneNumber: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(24, i18next.t(`stores:form.validation.max`))
    .required(i18next.t(`stores:form.validation.requiredPhoneNumber`)), // TODO: add regex for phone numbers
  websiteUrl: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(255, i18next.t(`stores:form.validation.max`))
    //.url(i18next.t(`stores:form.validation.url`))
    .notRequired(),
  //instaUrl: Yup.string().min(3).max(255).url().notRequired(),
  facebookUrl: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(255, i18next.t(`stores:form.validation.max`))
    //.url(i18next.t(`stores:form.validation.url`))
    .matches(
      /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/,
      i18next.t(`stores:form.validation.url`)
    )
    .matches(/facebook.com/, i18next.t(`stores:form.validation.facebookUrl`))
    .notRequired(),
  twitterUrl: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(255, i18next.t(`stores:form.validation.max`))
    //.url(i18next.t(`stores:form.validation.url`))
    .matches(
      /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/,
      i18next.t(`stores:form.validation.url`)
    )
    .matches(/twitter.com/, i18next.t(`stores:form.validation.twitterUrl`))
    .notRequired(),
  instagramUrl: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(255, i18next.t(`stores:form.validation.max`))
    //.url(i18next.t(`stores:form.validation.url`)) --> can't be used becaus protocol is needed
    .matches(
      /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/,
      i18next.t(`stores:form.validation.url`)
    )
    .matches(/instagram.com/, i18next.t(`stores:form.validation.instagramUrl`))
    .notRequired(),
  category: Yup.array().of(Yup.string()),
  //.required(i18next.t(`stores:form.validation.required`))
  subCategories: Yup.array().of(Yup.string()),
  //.required(i18next.t(`stores:form.validation.required`))
  mainPhoto: Yup.string()
    .min(3, i18next.t(`stores:form.validation.min`))
    .max(255, i18next.t(`stores:form.validation.max`))
    .notRequired(),
  photos: Yup.array()
    .required(i18next.t(`stores:form.validation.requiredPhoto`)) //TODO: figure out how to get this to the correct TS schema
    .test(
      "checkFileSize",
      i18next.t(`stores:form.validation.fileTooLarge`),
      filesSizeChecker(TEN_MB)
    )
    .test(
      "checkExtension",
      i18next.t(`stores:form.validation.invalidFileType`),
      filesTypeChecker(VALID_MIME_TYPES)
    ),
}).defined();

export type StoreFormViewModel = Yup.InferType<typeof StoreFormSchema>;

export const model2ViewModel = (store: Store): StoreFormViewModel => ({
  id: store.id,
  name: store.name,
  //addressDetails: store.address,
  openingHours: store.openingHours,
  street: store.street,
  streetNumber: store.streetNumber,
  city: store.city,
  zipCode: store.zipCode,
  country: store.country,
  address: `${store.street} ${store.zipCode} ${store.city} ${store.country}`,
  coordinates: store.coordinates,
  //lat: store.coordinates.lat,
  //lng: store.coordinates.lng,
  description: store.description,
  typeOfStore: store.typeOfStore,
  canDeliver: store.deliveryOptions.canDeliver,
  deliveryDescription: store.deliveryOptions.deliveryDescription,
  deliveryType: store.deliveryOptions.deliveryType,
  localDeliveryRadiusKm: store.deliveryOptions.localDeliveryRadiusKm,
  canPickup: store.deliveryOptions.canPickup,
  pickupDescription: store.deliveryOptions.pickupDescription,
  email: store.contacts.email,
  phoneNumber: store.contacts.telephone,
  websiteUrl: store.contacts.websiteUrl,
  facebookUrl: store.facebookUrl,
  twitterUrl: store.twitterUrl,
  instagramUrl: store.instagramUrl,
  category: store.categories
    .map((c) => c.parentCategory)
    .filter((value, index, self) => self.indexOf(value) === index),
  subCategories: store.categories.map((it) => it.id),
  photos: store.photos,
});

export const DEFAULT_OPENING_HOURS: OpeningHoursWeek = {
  monday: {
    firstStart: null,
    firstEnd: null,
    secondStart: null,
    secondEnd: null,
  },
  tuesday: {
    firstStart: null,
    firstEnd: null,
    secondStart: null,
    secondEnd: null,
  },
  wednesday: {
    firstStart: null,
    firstEnd: null,
    secondStart: null,
    secondEnd: null,
  },
  thursday: {
    firstStart: null,
    firstEnd: null,
    secondStart: null,
    secondEnd: null,
  },
  friday: {
    firstStart: null,
    firstEnd: null,
    secondStart: null,
    secondEnd: null,
  },
  saturday: {
    firstStart: null,
    firstEnd: null,
    secondStart: null,
    secondEnd: null,
  },
  sunday: {
    firstStart: null,
    firstEnd: null,
    secondStart: null,
    secondEnd: null,
  },
};

export const defaultEmptyState: StoreFormViewModel = {
  id: "",
  name: "",
  openingHours: DEFAULT_OPENING_HOURS,
  address: "",
  //addressDetails: {
  streetNumber: "",
  street: "",
  zipCode: "",
  city: "",
  country: "Deutschland",
  //},
  coordinates: {
    lat: 51.08,
    lng: 10.35,
  },
  description: "",
  typeOfStore: "",
  canDeliver: false,
  deliveryDescription: undefined,
  deliveryType: undefined,
  localDeliveryRadiusKm: undefined,
  canPickup: false,
  pickupDescription: undefined,
  email: "",
  phoneNumber: "",
  websiteUrl: undefined,
  facebookUrl: undefined,
  twitterUrl: undefined,
  instagramUrl: undefined,
  category: [],
  subCategories: [],
  photos: [],
};

export const defaultEmptyStatePreProd: StoreFormViewModel = {
  id: "",
  name: "test",
  openingHours: DEFAULT_OPENING_HOURS,
  address: "",
  //addressDetails: {
  streetNumber: "76",
  street: "Schirmerstrasse",
  zipCode: "40211",
  city: "Düsseldorf",
  country: "Deutschland",
  //},
  coordinates: {
    lat: 51.08,
    lng: 10.35,
  },
  description: "test",
  typeOfStore: "test",
  canDeliver: false,
  deliveryDescription: undefined,
  deliveryType: undefined,
  localDeliveryRadiusKm: undefined,
  canPickup: false,
  pickupDescription: undefined,
  email: "test@test.de",
  phoneNumber: "1234",
  websiteUrl: undefined,
  facebookUrl: undefined,
  twitterUrl: undefined,
  instagramUrl: undefined,
  category: [],
  subCategories: [],
  photos: [],
};
