import { defaultEmptyState } from "./StoreInfoSchema";
import { BaseStoreForm } from "./BaseStoreForm";
import React from "react";
import { CreateFormImageUploader } from "app/shared/forms/imageUploader/CreateFormImageUploader";
import { useRootRepositoryContext } from "rootRepository";

/*
Exports a customized version of the base store form for the business creation use-case.
*/
const CreateStoreForm: React.FC = () => {
  const { cityContentRepository } = useRootRepositoryContext();
  const formSettings =
    cityContentRepository.GetAppSettingEntry("_sysFormSettings");

  if (formSettings) {
    try {
      const defaultJson = JSON.parse(formSettings);
      defaultEmptyState.city = defaultJson?.defaultValues?.city ?? "";
      defaultEmptyState.zipCode = defaultJson?.defaultValues?.zipCode ?? "";
    } catch {}
  }

  return (
    <BaseStoreForm
      initialState={defaultEmptyState}
      imageUploaderComponent={CreateFormImageUploader}
    />
  );
};

export default CreateStoreForm;
