import { Radio, RadioProps } from "@chakra-ui/react";
import React from "react";
import { useRootRepositoryContext } from "rootRepository";


export const StyledRadio: React.FC<RadioProps> = (props) => {
  let { cityContentRepository } = useRootRepositoryContext();
  let colorJson: any = cityContentRepository.colorJSON ?? {};
  let color = colorJson?.content?.form?.checkbox?.color ? "content.form.checkbox.color" : "red" ;
  let textColor = colorJson?.content?.form?.checkbox?.text  ?? "black";
  let borderColor = colorJson?.content?.form?.checkbox?.border ?? "mdGrey";

  return (
    <Radio
      borderColor={borderColor}
      fontSize="lg"
      color={textColor}
      {...props}
      colorScheme={color}
    />
  )
};
