import { BoxProps, Flex, Stack, Text } from "@chakra-ui/react";
import { Button, Heading, IconWithText, Popup, Rating } from "app/shared";
import i18next from "i18next";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { compose } from "recompose";
import { useMediaQueryContext } from "styles/context";
import { useStoreRepositoryContext } from "../repositories";
import { StoreAddress } from "../storeAddress";
import { StoreOpeningHours } from "../storeOpeningHours";
import { StoreFavorite } from "../storeFavorite";
import { ContactForm } from "./ContactForm";
import { ReportForm } from "./ReportForm";
import { LoginForm } from "../../shared/navbar/loginMenu/LoginForm";
import { toggleFavorite } from "../services";
import { useRootRepositoryContext } from "rootRepository";
import { LOCAL_DELIVERY } from "app/shared/models";
import {
  FacebookStoreIcon,
  InstagramStoreIcon,
  MailToIcon,
  PhoneIcon,
  ReportIcon,
  TwitterStoreIcon,
  WebpageIcon,
} from "styles/icons";

const StoreTextInfoBase: React.FC<BoxProps> = (props) => {
  const { storeRepository } = useStoreRepositoryContext();
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;
  const {
    id,
    name,
    favorite,
    rating,
    openingHours,
    address,
    contacts,
    description,
    deliveryOptions,
    facebookUrl,
    twitterUrl,
    instagramUrl,
  } = storeRepository.store;

  const tmp = document.createElement("DIV");
  tmp.innerHTML = description;
  const clearedDescription = tmp.textContent || tmp.innerText || "";

  const [localFavorite, setLocalFavorite] = useState(favorite);
  const { isSmallMedium } = useMediaQueryContext();

  const cutLinkText = (text: string | undefined) => {
    return text && text.length > 30 ? text.substr(0, 30) + "..." : text;
  };

  let url = contacts?.websiteUrl ?? "";
  if (url.length > 0 && url.indexOf("http") < 0) {
    url = "https://" + url;
  }
  const trimmedUrl = cutLinkText(url);
  const trimmedfacebookUrl = cutLinkText(facebookUrl);
  const trimmedinstagramUrl = cutLinkText(instagramUrl);
  const trimmedtwitterUrl = cutLinkText(twitterUrl);
  //if (trimmedUrl.length > 30) {
  //  trimmedUrl = trimmedUrl.substr(0, 30) + '...';
  //}

  return (
    <Flex direction="column" {...props}>
      <Stack direction="row" spacing={4} alignItems="center">
        <Heading as="h1" color="content.store.storeDetail.headline">
          {name}
        </Heading>
        {userRepository.user && (
          <StoreFavorite
            isFavorite={localFavorite}
            onClick={() => {
              if (user?.ownedFavorites) {
                if (localFavorite) {
                  user.ownedFavorites = user?.ownedFavorites * 1 - 1;
                } else {
                  user.ownedFavorites = user?.ownedFavorites * 1 + 1;
                }
              }
              toggleFavorite(id);
              setLocalFavorite(!localFavorite);
            }}
            iconSize={isSmallMedium ? "20px" : "29px"}
          />
        )}
      </Stack>
      <Rating
        activeColor="content.store.storeDetail.stars.active"
        inactiveColor="content.store.storeDetail.stars.inactive"
        rating={rating}
      />
      <StoreAddress address={address} fontWeight="bold" pt={7} color="black" />
      <StoreOpeningHours openingHours={openingHours} py={3} />
      <Stack direction="column" spacing={1} py={1}>
        {contacts?.email && (
          <IconWithText
            icon={
              <MailToIcon
                color="content.store.storeDetail.contactIcons.color"
                boxSize="18px"
              />
            }
            isMailToLink={true}
            text={contacts.email}
            textColor="content.store.storeDetail.contactIcons.text"
          />
        )}
        {url.length > 0 && (
          <IconWithText
            icon={
              <WebpageIcon
                color="content.store.storeDetail.contactIcons.color"
                boxSize="18px"
              />
            }
            isLink={true}
            link={url}
            text={trimmedUrl ?? ""}
            textColor="content.store.storeDetail.contactIcons.text"
          />
        )}

        {contacts?.telephone && (
          <IconWithText
            icon={
              <PhoneIcon
                color="content.store.storeDetail.contactIcons.color"
                boxSize="18px"
              />
            }
            isPhoneLink={true}
            text={contacts?.telephone ?? ""}
            textColor="content.store.storeDetail.contactIcons.text"
          />
        )}
      </Stack>

      <Stack direction="column" spacing={1} py={1} mt={3}>
        {facebookUrl && (
          <IconWithText
            icon={
              <FacebookStoreIcon
                color="content.store.storeDetail.contactIcons.color"
                boxSize="18px"
              />
            }
            isLink={true}
            link={facebookUrl}
            text={trimmedfacebookUrl ?? ""}
            textColor="content.store.storeDetail.contactIcons.text"
          />
        )}
        {instagramUrl && (
          <IconWithText
            icon={
              <InstagramStoreIcon
                color="content.store.storeDetail.contactIcons.color"
                boxSize="18px"
              />
            }
            isLink={true}
            link={instagramUrl}
            text={trimmedinstagramUrl ?? ""}
            textColor="content.store.storeDetail.contactIcons.text"
          />
        )}
        {twitterUrl && (
          <IconWithText
            icon={
              <TwitterStoreIcon
                color="content.store.storeDetail.contactIcons.color"
                boxSize="18px"
              />
            }
            isLink={true}
            link={twitterUrl}
            text={trimmedtwitterUrl ?? ""}
            textColor="content.store.storeDetail.contactIcons.text"
          />
        )}
      </Stack>
      {/* DELIVERY OPTIONS */}
      <Text fontSize="lg" py={4}>
        <Text fontWeight="bold" as="span">
          {i18next.t("stores:storeTextInfo.deliveryAreaHeader")}
        </Text>
        <Text fontWeight="bold" as="span" ml={1}>
          {deliveryOptions.canDeliver
            ? i18next.t(
                `stores:storeTextInfo.deliveryType.${deliveryOptions.deliveryType}`
              )
            : i18next.t("stores:storeTextInfo.onlyPickup")}
        </Text>

        {deliveryOptions.canDeliver &&
          deliveryOptions.deliveryType === LOCAL_DELIVERY &&
          deliveryOptions.localDeliveryRadiusKm !== undefined &&
          deliveryOptions.localDeliveryRadiusKm > 0 && (
            <Text fontWeight="bold" as="span" ml={1}>
              {deliveryOptions.localDeliveryRadiusKm}{" "}
              {i18next.t(
                "stores:storeTextInfo.deliveryType.localDeliveryRadiusKm"
              )}
            </Text>
          )}
      </Text>

      {/* DESCRIPTION */}
      <Text fontSize="lg">{clearedDescription}</Text>
      <Flex direction={"row"} alignItems="center" pt={5}>
        {userRepository?.user ? (
          <Popup
            trigger={(onClose) => (
              <Button
                mr={4}
                w={["60%", "60%", "30%", "30%", "60%"]}
                onClick={onClose}
              >
                <Text fontSize="md" fontWeight="bold" lineHeight="tall">
                  {i18next.t("stores:storeTextInfo.contact")}
                </Text>
              </Button>
            )}
            content={(onClose) => <ContactForm onClose={onClose} />}
            contentMaxWidth={["100%", "100%", "80%", "80%", "1000px"]}
          />
        ) : (
          <Popup
            trigger={(onClose) => (
              <Button
                mr={4}
                w={["60%", "60%", "30%", "30%", "60%"]}
                onClick={onClose}
              >
                <Text fontSize="md" fontWeight="bold" lineHeight="tall">
                  {i18next.t("stores:storeTextInfo.contact")}
                </Text>
              </Button>
            )}
            content={(onClose) => (
              <LoginForm
                isInPopup={true}
                onLogin={() => <ContactForm onClose={onClose} />}
              />
            )}
            contentMaxWidth={["100%", "500px", "500px", "600px", "600px"]}
          />
        )}
        {userRepository?.user ? (
          <Popup
            trigger={(onClose) => (
              <Button
                width={["37px", "37px", "46px"]}
                height={"37px"}
                mr={4}
                onClick={onClose}
              >
                <ReportIcon boxSize="18px" />
              </Button>
            )}
            content={(onClose) => <ReportForm onClose={onClose} />}
            contentMaxWidth={["100%", "100%", "80%", "80%", "1000px"]}
          />
        ) : (
          <Popup
            trigger={(onClose) => (
              <Button
                width={["37px", "37px", "46px"]}
                height={"37px"}
                mr={4}
                onClick={onClose}
              >
                <ReportIcon boxSize="18px" />
              </Button>
            )}
            content={(onClose) => (
              <LoginForm
                isInPopup={true}
                onLogin={() => <ReportForm onClose={onClose} />}
              />
            )}
            contentMaxWidth={["100%", "500px", "500px", "600px", "600px"]}
          />
        )}
      </Flex>
    </Flex>
  );
};

export const StoreTextInfo = compose<{}, BoxProps>(observer)(StoreTextInfoBase);

{
  /* <Button width={["37px", "37px", "46px"]} height={"37px"} mr={4}>
  <ShareIcon boxSize="18px" />
</Button>
<Button width={["37px", "37px", "46px"]} height={"37px"} mr={4}>
  <ReportIcon boxSize="18px" />
</Button> */
}

/*
  userRepository?.user &&
          userRepository?.user?.email?.length > 0 &&
 */
