import axios from "../../../network";
//import { Testimonial } from "../models";


//export const getCityContent = async (cityKey: string): Promise<string> => {
//  return new Promise<string>((resolve, reject) => {
//    axios.
//      get("/CityEndpoint")
//      .then(function (response) {
//        try {

//          const jsonCityData: any = response.data;
//          resolve(jsonCityData);

//        } catch (r) {
//          console.log("Error loading/parsing City data");
//        }
//      }).catch((r) => {
//        console.log("Error loading/parsing City data");
//      });
//  });
//};

export const getCityKeyFromConfig = async (): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    fetch('/config/config.json')
      .then((r) => r.text())
      .then(json => {
        let key = JSON.parse(json).key;
        resolve(key);
      });
  });
};

export const getCityConfig = async (cityKey: string): Promise<any> => {
  const requestConfig = { "instance": cityKey}

  return new Promise<any>((resolve, reject) => {
    axios.
      post("/AppSettings/GetConfig", JSON.stringify(requestConfig))
      .then(function (response) {
        try {
          const jsonCityData: any = response.data;
          resolve(jsonCityData);
        } catch (r) {
          console.log("Error loading/parsing City config data");
        }
      }).catch((r) => {
        console.log("Error loading/parsing City config data");
      });
  });
};

export const getCityContent = async (cityKey: string): Promise<any> => {
  const requestConfig = { "instance": cityKey }

  return new Promise<any>((resolve, reject) => {
    axios.
      post("/AppSettings/GetContent", JSON.stringify(requestConfig))
      .then(function (response) {
        try {
          const jsonCityData: any = response.data;
          resolve(jsonCityData);

        } catch (r) {
          console.log("Error loading/parsing City content data");
        }
      }).catch((r) => {
        console.log("Error loading/parsing City content data");
      });
  });
};
