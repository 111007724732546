import { Box, BoxProps, Image, Stack, Text, Link } from "@chakra-ui/react";
import { TextLink } from "app/shared/textLink";
import React, { ReactNode } from "react";
import { Headline } from "app/shared";
import { useMediaQueryContext } from "styles/context";

import { useRootRepositoryContext } from "rootRepository";
interface Props extends BoxProps {
  imgSrc: string;
  imgSrc2: string;
  text: string;
  id: string;
  headline: string;
  date: string;
  imgPosition: "left" | "right";
}

export const NewsCard: React.FC<Props> = ({
  imgSrc,
  imgSrc2,
  imgPosition,
  text,
  id,
  headline,
  date,
  ...rest
}) => {
  const { isSmall, isSmallMedium } = useMediaQueryContext();
  const { cityContentRepository } = useRootRepositoryContext();
  const flexDirection = isSmallMedium ? "column" : imgPosition === "left" ? "row" : "row-reverse";
  const newsLink = `https://wir-liefern.org/newsDetail/${id}`;
  const withImage = imgSrc != null && imgSrc.length > 0;

  let timestampColor = "black";
  let timestampBackground = "#F5F5F5"
  let linkColor = "black";

  if (cityContentRepository.colorJSON) {    
    const colorJson = cityContentRepository.GetAppSettingJSONEntry("_sysColors");
    if (colorJson?.content?.news?.timestamp?.background?.length > 0) {
      timestampBackground = colorJson?.content?.news?.timestamp?.background;
    }
    if (colorJson?.content?.news?.timestamp?.color?.length > 0) {
      timestampColor = colorJson?.content?.news?.timestamp?.color;
    }
    if (colorJson?.content?.news?.link?.length > 0) {
      linkColor = colorJson?.content?.news?.link;
    }

  }

  return (
    <Stack direction={flexDirection} spacing={8} {...rest}>
      {withImage && (<Box minWidth={isSmall ? "100%" : "390px"} maxWidth={isSmall ? "100%" : "390px"}>
        <Image src={imgSrc} width={"100"} height={"auto"} />
        <Text>{imgSrc2}</Text>
      </Box>)}
      <Box fontSize="lg" color="content.news.text">
        <Text background={timestampBackground} color={timestampColor} display="inline" padding="0 10px">{date}</Text>
        <Headline as="h2" color="content.news.headline" fontSize={["xl", "xl", "5xl", "5xl"]} lineHeight={"1.1"}>{headline}</Headline>
        <span dangerouslySetInnerHTML={{ __html: text }} />
        <Link href={newsLink} fontWeight="bold" color={linkColor} target="_blank">{"Artikel lesen >>"}</Link>        
      </Box>
    </Stack>
  );
};
