import { Box, BoxProps, Image, Stack, Link } from "@chakra-ui/react";
import {  } from "app/shared";
/*import { Link } from 'react-router-dom';*/
import { TextLink } from "app/shared/textLink";
import React, { ReactNode } from "react";
import { Headline, Heading, Button } from "app/shared";
import { useMediaQueryContext } from "styles/context";
import { DeliveryTypeAndRadiusField } from "../stores/forms/StoreFormFields";

interface Props extends BoxProps {
  imgSrc: string;
  text: string;
  id: string;
  headline: string;
  imgPosition: "left" | "right";
  linklist?: { link: string, text: string }[];
}

export const InfoCard: React.FC<Props> = ({
  imgSrc,
  imgPosition,
  text,
  id,
  headline,
  linklist,
  ...rest
}) => {
  const { isSmall, isSmallMedium } = useMediaQueryContext();

  const flexDirection = isSmallMedium ? "column" : imgPosition === "left" ? "row" : "row-reverse";
  const withImage = imgSrc != null && imgSrc.length > 0;
  const withList = (linklist != null && linklist != undefined && linklist.length > 0)
  return (
    <Stack direction={flexDirection} spacing={8} {...rest} key={ id + "0" }>
      {(withImage || withList) && (
        <Box minWidth={isSmall ? "100%" : "390px"} maxWidth={isSmall ? "100%" : "390px"}>
          {withImage && (
            <Box display="flex" justifyContent={isSmallMedium ? "flex-start" : "flex-end"} mb={withList ? "30px" : "0"}>
              <Image src={imgSrc} width={"100"} height={"auto"} />
            </Box>
          )}
          {withList && (

            <Box
              display="flex"
              flexDirection="column"
            >
              {linklist?.map((link, index) => (
                <Box
                  mb="13px"
                  key={id + index}
                >
                  <Button
                    w="auto"
                    float={isSmallMedium ? "left" : "right"}
                  >                    
                    <a href={link.link} target="_blank" dangerouslySetInnerHTML={{ __html: link.text }}>
                    </a>
                  </Button>
                </Box>
              ))
              }
            </Box>
          )}
        </Box>)}
      <Box fontSize="lg" color="content.text.color">
        <Heading  fontSize={["3xl", "4xl", "7xl"]}  lineHeight={"1.1"} dangerouslySetInnerHTML={{ __html: headline }}></Heading>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Box>
    </Stack>
  );
};
