import React, { useEffect, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import i18next from "i18next";
import { Box, Flex, Text, SimpleGrid } from "@chakra-ui/react";

import { ProductFormSchema, ProductFormViewModel } from "./ProductInfoSchema";
import { ProductInputField, ProductCheckboxField } from "./ProductFormFields";
import { CategoryMultiSelect } from "app/categories/categoryMultiSelect";
import { DeliveryTypeAndRadiusField } from "app/stores/forms/StoreFormFields";

import { useMediaQueryContext } from "styles/context";
import {
  Button,
  StyledInput,
  StyledTextarea,
  ValidationPopup,
  CreatingPopup,
  CreatedPopup,
  NeedLoginPopup,
} from "app/shared";
import { LOCAL_DELIVERY } from "app/shared/models";
import { ImageUploaderProps } from "app/shared/forms/imageUploader";

import { createProduct, updateProduct } from "../services";
import { useRootRepositoryContext } from "../../../rootRepository";
import { CloseIcon } from "styles/icons";

let validationMessages = "";

interface SubmitHandlerProps {
  setSubmitting: (isSubmitting: boolean) => void;
}

interface Props {
  initialState: ProductFormViewModel;
  productId?: string;
  imageUploaderComponent: React.ComponentType<ImageUploaderProps>;
}

interface CategorieState {
  key: number;
  category: string;
}

export const BaseProductForm: React.FC<Props> = (productFormProps) => {
  const [showModalCreation, setShowModalCreation] = useState(false);
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [showModalCreated, setShowModalCreated] = useState(false);
  const [showNeedLoginMessage, setShowNeedLoginMessage] = useState(false);
  const [categories, setCategories] = useState<CategorieState[]>([]);
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [hasCategories, setHasCategories] = useState(false);
  const [hasSubCategories, setHasSubCategories] = useState(false);
  const [categoriesCount, setCategoriesCount] = useState(0);

  const { userRepository, categoryRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  const {
    isSmall,
    isSmallMedium,
    isMedium,
    isMediumLarge,
    isLarge,
    isVeryLarge,
  } = useMediaQueryContext();

  useEffect(() => {
    const initialState = productFormProps.initialState;
    if (productFormProps.productId != undefined) {
      setHasCategories(true);
      setHasSubCategories(true);
    }

    if (initialState && initialState.categories) {
      const initalCatState: CategorieState[] = [];
      if (initialState && initialState.categories.length > 0) {
        for (let i = 0; i < initialState.categories.length; i++) {
          initalCatState.push({ key: i, category: initialState.categories[i] });
        }
      }
      setCategories(initalCatState);
    }

    if (initialState && initialState.subCategories.length > 0) {
      setSubCategories(initialState.subCategories);
    }

    setCategoriesCount(
      initialState.categories.length > 0
        ? initialState.categories.length - 1
        : 0
    );
  }, [productFormProps.initialState, productFormProps.productId]);

  const submitHandler = (
    values: ProductFormViewModel,
    handlerProps: SubmitHandlerProps
  ) => {
    setShowModalCreation(true);

    if (productFormProps.productId == undefined) {
      createProduct(values)
        .then(() => {
          if (user?.ownedProducts) {
            user.ownedProducts = user?.ownedProducts * 1 + 1;
          }
          setShowModalCreation(false);
          setShowModalCreated(true);
        })
        .catch((err: any) => {
          console.error(err.message);
          setShowModalCreation(false);
          if (err?.status === 401 || err?.response?.status === 401) {
            setShowNeedLoginMessage(true);
            userRepository.logout();
          }
        })
        .finally(() => handlerProps.setSubmitting(false));
    } else {
      updateProduct(values)
        .then(() => {
          setShowModalCreation(false);
          setShowModalCreated(true);
        })
        .catch((err: any) => {
          console.error(err.message);
          setShowModalCreation(false);
          if (err?.status === 401 || err?.response?.status === 401) {
            setShowNeedLoginMessage(true);
            userRepository.logout();
          }
        })
        .finally(() => handlerProps.setSubmitting(false));
    }
  };

  const renderActualForm = (
    isSmall: boolean,
    isSmallMedium: boolean,
    isMedium: boolean,
    props: FormikProps<ProductFormViewModel>
  ) => {
    const { values, handleSubmit } = props;

    const CustomImageUploader = productFormProps.imageUploaderComponent;
    const isDeliveryEnabled = values.canDeliver;
    const isPickupEnabled = values.canPickup;

    const onProductSubCategoryUpdate = (subCategories: string[]) => {
      props.setFieldValue("subCategories", subCategories);
      props.setFieldTouched("subCategories", true);
      setSubCategories(subCategories);
      setHasSubCategories(
        subCategories != undefined && subCategories.length > 0
      );
      props.validateForm();
    };

    const onProductCategoryUpdate = (
      categorie: string,
      oldCatValue?: string,
      index?: number
    ) => {
      if (oldCatValue && oldCatValue.length > 0 && categorie === "") {
        // Kategorie wurde gelöscht -> aus dem State entfernen
        setCategories(categories.filter((c) => c.key !== index));
      } else if (categorie.length > 0) {
        categories.push({ key: index ?? 0, category: categorie });
        setCategories(categories);
      }

      const catsForProps: string[] = categories.map((c) => c.category);
      props.setFieldValue("categories", catsForProps);
      props.setFieldTouched("categories", true);

      setHasCategories(categorie != undefined && categories.length > 0);
      props.validateForm();
    };

    const onCategorySelectRemove = (index: number) => {
      const selectedCategory =
        categories.filter((c) => c.key === index)[0]?.category ?? "";
      if (selectedCategory && selectedCategory.length > 0) {
      }
      // subCategorien entfernen
      const subCategoriesIds: string[] =
        categoryRepository.categories
          .find((c) => c.id === selectedCategory)
          ?.subCategories.map((subCategory) => subCategory.id) ?? [];
      let subCatsToRemove = "";
      for (let i = 0; i < subCategoriesIds.length; i++) {
        subCatsToRemove += subCategoriesIds[i] + ";";
      }
      const newSubCats = subCategories.filter(
        (sc) => subCatsToRemove.indexOf(sc) === -1
      );
      setSubCategories(newSubCats);
      props.setFieldValue("subCategories", newSubCats);
      props.setFieldTouched("subCategories", true);

      // Category entfernen
      const newCats = categories.filter((c) => c.key !== index);
      setCategories(newCats);
      const catsForProps: string[] = newCats.map((c) => c.category);
      props.setFieldValue("categories", catsForProps);
      props.setFieldTouched("categories", true);

      // dafür sorgen, dass multiselect nicht mehr gerendert wird
      setCategoriesCount(categoriesCount - 1);
    };

    const drawAdditionalCatSelects = (indexes: number[]) => {
      // da beim Entfernen eines CategoryPickers die Nummerierung löchrig wird, werden vor dem "Zeichnen" der MultiSelects die Keys korrigiert
      for (let i = 0; i < categories.length; i++) {
        categories[i].key = i;
      }
      return (
        <Box>
          {indexes.map((item: number) => (
            <Box py={3} key={"additionalMultiselect_" + item}>
              <Box>
                <Button
                  width="30px"
                  mb={"5px"}
                  type="button"
                  onClick={(e) => {
                    onCategorySelectRemove(item);
                  }}
                >
                  <CloseIcon boxSize={"15px"} />
                </Button>
              </Box>
              <SimpleGrid columns={1}>
                <CategoryMultiSelect
                  index={item}
                  selectedCategory={
                    categories && categories.length > 0
                      ? categories.filter((cs) => cs.key === item)[0]?.category
                      : ""
                  }
                  selectedSubCategories={subCategories}
                  setSelectedCategory={onProductCategoryUpdate}
                  setSelectedSubCategories={onProductSubCategoryUpdate}
                  autoSelectSubCategories={false}
                  width={["300px", "450px", "600px", "800px", "1000px"]}
                  hideOnSmall={false}
                />
              </SimpleGrid>
            </Box>
          ))}
        </Box>
      );
    };

    const getAdditionalCatSelects = () => {
      const arrayCatIndexes = [];
      for (let i = 0; i < categoriesCount; i++) {
        arrayCatIndexes.push(i + 1);
      }
      return drawAdditionalCatSelects(arrayCatIndexes);
    };

    return (
      <Form onSubmit={handleSubmit}>
        <Box py={3}>
          <Text fontSize="xl" fontWeight="bold">
            {i18next.t(`products:form.productInfoTitle`)}
          </Text>
          <Flex
            justifyContent="flexStart"
            flexDirection={"column"}
            width={"100%"}
          >
            <Box width={"100%"}>
              {/* Core Product information */}
              <Box>
                <ProductInputField
                  fieldName="name"
                  fieldType="text"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <ProductInputField
                  fieldName="price"
                  fieldType="text"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                {/* PBI 10267: Verfügbarkeit wird vorerst entfernt */}
                {/* <ProductSelectField
                  fieldName="stockType"
                  fieldType="StockType"
                  options={stockTypeArray.values}
                /> */}
                <ProductInputField
                  fieldName="description"
                  fieldType="text"
                  Component={StyledTextarea}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <ProductInputField
                  fieldName="link"
                  fieldType="text"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
              </Box>
              {/* Photos - The custom image uploader is the component passed in the props */}
              <Box>
                <CustomImageUploader fieldName="photos" parentForm={props} />
              </Box>
              {/* Delivery options */}
              <Box>
                <SimpleGrid columns={2} spacing={10}>
                  <Box>
                    <ProductCheckboxField
                      fieldName="canDeliver"
                      fieldType="boolean"
                      labelWidthPx="80px"
                    />
                    <ProductInputField
                      fieldName="deliveryDescription"
                      fieldType="text"
                      Component={StyledTextarea}
                      disabled={!isDeliveryEnabled}
                      labelWidthPx="0px"
                    />
                  </Box>
                  <Box>
                    <ProductCheckboxField
                      fieldName="canPickup"
                      fieldType="boolean"
                      labelWidthPx="80px"
                    />
                    <ProductInputField
                      fieldName="pickupDescription"
                      fieldType="text"
                      Component={StyledTextarea}
                      disabled={!isPickupEnabled}
                      labelWidthPx="0px"
                    />
                  </Box>
                </SimpleGrid>
                {isDeliveryEnabled && (
                  <Box bg={"brandRed.50"} mt={3}>
                    <DeliveryTypeAndRadiusField
                      deliveryTypeFieldName="deliveryType"
                      localDeliveryRadiusKmFieldName="localDeliveryRadiusKm"
                      disabled={!isDeliveryEnabled}
                      isLocalDelivery={values.deliveryType === LOCAL_DELIVERY}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Flex>
        </Box>

        {/* Category selection */}
        {/* <Box py={3}>
          <Text fontSize="xl" fontWeight="bold">
            {i18next.t(`products:form.categoryInfoTitle`)}
          </Text>
          <SimpleGrid columns={1}>
            <CategoryMultiSelect
              index={0}
              selectedCategory={
                categories && categories.length > 0
                  ? categories.filter((cs) => cs.key === 0)[0]?.category
                  : ""
              }
              selectedSubCategories={subCategories}
              setSelectedCategory={onProductCategoryUpdate}
              setSelectedSubCategories={onProductSubCategoryUpdate}
              autoSelectSubCategories={false}
              width={["300px", "450px", "600px", "800px", "1000px"]}
              hideOnSmall={false}
            />
          </SimpleGrid>
        </Box>

        {getAdditionalCatSelects()} */}

        <Box>
          <Text mt="20px" fontSize="12px">
            {i18next.t(`products:form.mandatoryText`)}
          </Text>
        </Box>

        {/* <Box>
          <Flex
            direction="column"
            alignItems="center"
            borderTop="4px solid"
            borderColor="brandRed.300"
            mt={10}>
            <Button
              width="245px"
              type="button"
              onClick={(e) => {
                setCategoriesCount(categoriesCount + 1);
              }}>
              {i18next.t(`products:form.addCat.add`)}
            </Button>
          </Flex>
        </Box> */}

        <Flex py={3} justifyContent="center">
          <Button
            width="200px"
            type="submit"
            onClick={(e) => {
              props.validateForm();

              validationMessages = "";

              if (
                !props.isValid ||
                props.isSubmitting
                // || !hasCategories ||
                // !hasSubCategories
              ) {
                e.preventDefault();

                if (!props.values.name)
                  validationMessages +=
                    "<b>Name:</b> " +
                    i18next.t(`products:form.validation.requiredName`) +
                    "<br />";
                if (!props.values.price)
                  validationMessages +=
                    "<b>Preis:</b> " +
                    i18next.t(`products:form.validation.requiredPrice`) +
                    "<br />";
                else if (!/^\d+(\,\d{2})?$/.test(props.values.price))
                  validationMessages +=
                    "<b>Preis:</b> " +
                    i18next.t(`products:form.validation.invalidPriceFormat`) +
                    "<br />";
                if (!props.values.photos || props.values.photos.length == 0)
                  validationMessages +=
                    "<b>Fotos:</b> " +
                    i18next.t(`products:form.validation.requiredImage`) +
                    "<br />";
                else if (
                  props?.errors?.photos != null &&
                  props?.errors?.photos != undefined &&
                  props.errors.photos.length > 0
                )
                  validationMessages +=
                    "<b>Fotos:</b> " + props.errors.photos + "<br />";
                // if (!hasCategories) {
                //   validationMessages +=
                //     '<b>Kategorie:</b> ' +
                //     'Bitte Geben Sie eine Kategorie an.<br />';
                // } else if (!hasSubCategories) {
                //   validationMessages +=
                //     '<b>Unterkategorie:</b> ' +
                //     'Bitte Geben Sie eine Unterkategorie an.<br />';
                // }
              }

              if (validationMessages != "") {
                setShowModalValidation(true);
              }
            }}
          >
            {i18next.t(`products:form.submit.label`)}
          </Button>
        </Flex>
      </Form>
    );
  };

  return (
    <>
      {showModalValidation && (
        <ValidationPopup
          setShowModalValidation={setShowModalValidation}
          validationMessages={validationMessages}
        />
      )}
      {showModalCreation && <CreatingPopup />}
      {showModalCreated && <CreatedPopup />}
      {showNeedLoginMessage && <NeedLoginPopup />}

      <Box>
        <Formik
          initialValues={productFormProps.initialState}
          validationSchema={ProductFormSchema}
          onSubmit={submitHandler}
        >
          {(props) => renderActualForm(isSmall, isSmallMedium, isMedium, props)}
        </Formik>
      </Box>
    </>
  );
};
