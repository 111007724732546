import { StoreId } from "app/stores/models";
import { UserId } from "app/users/models/index";
import {
  PaginatedComments,
  PaginatedUserComments,
  RateAndCommentDTO,
  StoreComment,
  CommentId,
  UserComment,
} from "../models/index";
import { MockCommentBackendService } from "./mock/MockCommentBackendService";
import environment from "configurations";
import axios from "network";
// TODO: replace the mock backend service
export const getCommentsForStore = async (storeId: StoreId): Promise<PaginatedComments> => {

  return new Promise<PaginatedComments>((resolve, reject) => {

    axios.
      get("/Comments/SearchCommentsOfStore/" + storeId)
      .then(function (response) {
        try {
          let data = response.data;
          let comments: StoreComment[] = data;
          const paginatedComments: PaginatedComments = {
            totalResults: comments.length,
            currentPage: 1,
            perPage: 4,
            nextPageUrl: "",
            prevPageUrl: "",
            urlTemplate: "",
            data: comments,
          };
          resolve(paginatedComments);
        } catch (r) {
          console.log("Error loading/parsing CommentsList data");
          //reject(categoriesDTO);
        }
      }).catch(() => {
        console.log("Error loading/parsing CommentsList data");
        //reject(categoriesDTO);
      });
  });
};

export const getMoreComments = async (
  storeId: StoreId
): Promise<PaginatedComments> => {
  return MockCommentBackendService.createMockedMoreComments(storeId);
};

export const getCommentsForUser = async (
  userId: UserId
): Promise<PaginatedUserComments> => {
  return new Promise<PaginatedUserComments>((resolve, reject) => {
    axios
      .get("/Comments/SearchMyComments")
      .then(function (response) {
        try {

          let comments: UserComment[] = response.data;

          const paginatedComments: PaginatedUserComments = {
            totalResults: comments.length,
            currentPage: 1,
            perPage: 4,
            nextPageUrl: "",
            prevPageUrl: "",
            urlTemplate: "",
            data: comments,
          };

          resolve(paginatedComments);
        } catch (r) {
          console.log("Error loading/parsing CommentsList data");
          //reject(categoriesDTO);
        }
      }).catch(() => {
        console.log("Error loading/parsing CommentsList data");
        //reject(categoriesDTO);
      });
  });
};

export const rateAndCommentStore = async (
  storeId: StoreId,
  userId: UserId,
  rateAndCommentDto: RateAndCommentDTO
): Promise<StoreComment> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Comments/AddCommentToStore`, { StoreId: storeId, UserId: userId, Text: rateAndCommentDto.comment, Voting: rateAndCommentDto.rating })
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const commentForStoreExists = async (storeId: StoreId): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/Comments/CommentFromUserForStoreExists/` + storeId)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const deleteComment = async (commentId: CommentId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/Comments/DeleteMyComment?id=` + commentId)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response.data.Code == 999) {
          reject(response.data.Message);
        }
        else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};
