import { Flex, Text, Box } from "@chakra-ui/react";
import { ButtonLink, LayoutGrid, Popup, Heading, Button } from "app/shared";
import { StoreCard } from "app/stores";
import { Store, StoreId } from "app/stores/models";
import { getOwnedStores, deleteStore } from "app/stores/services";
import i18next from "i18next";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SearchForUserParam } from "../../search/models";
import { useMediaQueryContext } from "styles/context";

import { useRootRepositoryContext } from "../../../rootRepository";
import { CloseIcon } from "@chakra-ui/icons";

interface ViewProps {
  store: Store;
}

const editButtonStyle = {
  fontSize: "lg",
  color: "content.button.color",
  bg: "content.button.background",
  borderRadius: "0",
  width: "20%",
  _hover: {
    cursor: "pointer",
    bg: "content.button.hover",
  },
};

const View: React.FC<ViewProps> = ({ store }) => {
  const [deleted, setdeleted] = useState(false);

  const {
    isSmall,
    isSmallMedium,
    isMedium,
    isMediumLarge,
    isLarge,
    isVeryLarge,
  } = useMediaQueryContext();

  const onPopupDeleteClose = () => {
    setdeleted(true);
  };

  {
    if (!deleted) {
      return (
        <Flex direction="column" mr={isMedium ? "0" : 10}>
          <Flex direction="row" justifyContent="flex-end">
            <Text fontSize="lg" color="brandRed.300" pr={2}>
              {i18next.t("users:profile.ownedStoresGrid.delete")}
            </Text>
            <DeleteStoreBtn
              store={store}
              onPopupDeleteClose={onPopupDeleteClose}
            />
          </Flex>
          <StoreCard mr={0} store={store} showTriangle={false} />
          <Flex direction="row" justifyContent="space-between">
            <Text color="darkGrey" fontSize="lg" visibility="hidden">
              {i18next.t("users:profile.ownedStoresGrid.storeInfo", {
                numFavorites: store.numFavorites ?? 0,
                numContacts: store.numContacts ?? 0,
                numViews: store.numViews ?? 0,
              })}
            </Text>
            <ButtonLink
              {...editButtonStyle}
              to={`/editStore/${store.id}`}
              minWidth="150px"
            >
              {i18next.t("users:profile.ownedStoresGrid.edit")}
            </ButtonLink>
          </Flex>
        </Flex>
      );
    } else {
      return (
        <Flex direction="column" mr={10}>
          <Text fontSize="lg" color="brandRed.300" pr={2}>
            {i18next.t("users:profile.ownedStoresGrid.deleted")}
          </Text>
        </Flex>
      );
    }
  }
};

const DeleteStoreBtn: React.FC<{
  store: Store;
  onPopupDeleteClose: () => void;
}> = ({ store, onPopupDeleteClose }) => (
  <Popup
    trigger={(showPopup) => (
      <Box
        bg="content.button.background"
        px={3}
        py={1}
        _hover={{ cursor: "pointer", bg: "content.button.hover" }}
        onClick={showPopup}
      >
        <CloseIcon color="white" boxSize={"13px"} />
      </Box>
    )}
    content={(onClose) => (
      <DeleteStoreConfirmDialog
        closePopup={() => {
          onClose();
          onPopupDeleteClose();
        }}
        storeId={store.id}
      />
    )}
    contentMaxWidth={["800px"]}
  />
);

interface DeleteStoreConfirmationDialogProps {
  storeId: StoreId;
  closePopup: () => void;
}

const DeleteStoreConfirmDialog: React.FC<
  DeleteStoreConfirmationDialogProps
> = ({ storeId, closePopup }) => {
  const history = useHistory();
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <Box mb={1}>
      <Heading>
        {i18next.t("users:profile.ownedStoresGrid.deletePopup.title")}
      </Heading>
      <Text fontSize="lg" color="darkGrey">
        {i18next.t("users:profile.ownedStoresGrid.deletePopup.message")}
      </Text>
      <Button
        w="200px"
        mt={1}
        onClick={() => {
          deleteStore(storeId).then(() => {
            if (user?.ownedStores) {
              user.ownedStores = user?.ownedStores * 1 - 1;
            }
            closePopup();
          });
        }}
      >
        {i18next.t("users:profile.ownedStoresGrid.deletePopup.button")}
      </Button>
    </Box>
  );
};

export const OwnedStoresGrid: React.FC<SearchForUserParam> = ({ userId }) => {
  const {
    isSmall,
    isSmallMedium,
    isMedium,
    isMediumLarge,
    isLarge,
    isVeryLarge,
  } = useMediaQueryContext();
  const numberOfColumns = isMedium ? 1 : 2;

  return (
    <LayoutGrid
      fetchId={userId}
      fetchItems={getOwnedStores}
      loadMore={i18next.t("users:profile.ownedStoresGrid.loadMore")}
      numColumns={numberOfColumns}
      view={(item: Store) => <View store={item} />}
      emptyStateView={() => (
        <Box>
          <Text fontSize="lg" color="darkGrey" fontWeight="bold" pt={20}>
            {i18next.t("users:profile.ownedStoresGrid.emptyState")}
          </Text>
          <Flex
            direction="column"
            alignItems="center"
            borderTop="4px solid"
            borderColor="brandRed.300"
            mt={10}
          >
            <ButtonLink {...editButtonStyle} to={`/newStore`} minWidth="190px">
              {i18next.t("users:profile.ownedStoresGrid.loadMore")}
            </ButtonLink>
          </Flex>
        </Box>
      )}
      columnWidth={isMedium ? "100%" : isMediumLarge ? "50%" : "574px"}
    />
  );
};
