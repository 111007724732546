export type PersonTitle = "" | "Herr" | "Frau" | "Divers";
export const personTitleArray = { values: ["", "Herr", "Frau", "Divers"] };

export interface User {
  id: string;
  username: string;
  title: PersonTitle;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  profilePictureUrl?: string;
  ownedStores?: number;
  ownedProducts?: number;
  ownedComments?: number;
  ownedFavorites?: number;
}

export type UserId = string;
