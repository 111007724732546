import { ContentArea } from "app/shared";
import { StoreWindowInfo } from "app/storeWindows/storeWindowInfo";
import { StoreWindowRepositoryProvider } from "app/storeWindows/repositories";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import useScript from "../utils/useScript";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const StoreWindowDetailsBase: React.FC<Props> = ({ match }) => {
  const id = match.params.id;

  useScript.useScriptViaString(
    "if (typeof upScore == 'function') { upScore({data: {object_id: '" +
      id +
      "', section: 'storeWindow', pubdate: '', author: '', url: '/storeWindow', object_type: 'storeWindow' }}); } "
  );

  return (
    // TODO ML: richtige Farben wenn AppSettings geupdatet sind..
    <ContentArea
      color="content.store.storeDetail.color"
      bg="content.store.storeDetail.background"
    >
      <StoreWindowRepositoryProvider>
        <StoreWindowInfo storeWindowId={id} />
      </StoreWindowRepositoryProvider>
    </ContentArea>
  );
};

export const StoreWindowDetails = compose<Props, {}>(withRouter)(
  StoreWindowDetailsBase
);
