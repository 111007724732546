import {
  Image,
  Box,
  Stack,
  Text,
  Input,
  BoxProps,
} from "@chakra-ui/react";
import environment from "configurations";
import i18next from "i18next";
import React, { useState, useEffect } from "react";
import { useMediaQueryContext } from "styles/context";
import { PhotoIcon } from "styles/icons";

interface Props extends BoxProps {
  pictureUrl?: string;
  editDisabled: boolean;
  onPictureUpload: (file: File) => void;
}

export const ProfileAvatar: React.FC<Props> = ({
  pictureUrl,
  onPictureUpload,
  editDisabled,
  ...otherProps
}) => {
  const [avatarPicUrl, setAvatarPicUrl] = useState(pictureUrl ?? "");
  let hiddenInput: HTMLInputElement | undefined = undefined;

  useEffect(() => {
    if (pictureUrl) {
      setAvatarPicUrl(pictureUrl);
    }
  }, [pictureUrl]);

  const {
    isSmall,
    isSmallMedium,
    isMedium,
    isMediumLarge,
    isLarge,
    isVeryLarge,
  } = useMediaQueryContext();

  return (
    <>
      <Box _hover={{ cursor: "pointer" }} {...otherProps}>
        {avatarPicUrl ? (
          <Image
            objectFit="cover"
            borderRadius="50%"
            width={isSmall ? "110px" : "166px"}
            height={isSmall ? "110px" : "166px"}
            src={environment.site + avatarPicUrl}
            onClick={() => hiddenInput && hiddenInput.click()}
            boxShadow="sm"
          />
        ) : (
          <Stack
            borderRadius="50%"
            width={isSmall ? "110px" : "166px"}
            height={isSmall ? "110px" : "166px"}
            border="2px solid"
            borderColor="lightestBlack"
            bg="white"
            onClick={() => hiddenInput && hiddenInput.click()}
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems="center"
            boxShadow="sm"
          >
            <PhotoIcon boxSize="39px" />
            <Text
              fontWeight="bold"
              fontSize="lg"
              textAlign="center"
              color="brandRed.300"
            >
              {i18next.t("users:profile.emptyAvatar.info")}
            </Text>
          </Stack>
        )}
      </Box>
      {editDisabled && (
        <Input
          hidden
          type="file"
          id="avatarPhoto"
          onChange={(e: any) => {
            const file = e.currentTarget.files[0];
            setAvatarPicUrl(URL.createObjectURL(file));
            onPictureUpload(file);
          }}
          ref={(el: HTMLInputElement) => (hiddenInput = el)}
        />
      )}
    </>
  );
};
