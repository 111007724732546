import { Checkbox, CheckboxProps } from "@chakra-ui/react";
import React from "react";
import { useRootRepositoryContext } from "rootRepository";

export const StyledCheckbox: React.FC<CheckboxProps> = (props) => {
  let { cityContentRepository } = useRootRepositoryContext();
  let colorJson: any = cityContentRepository.colorJSON ?? {};
  let color = colorJson?.content?.form?.checkbox?.color ? "content.form.checkbox.color" : "red";
  let textColor = colorJson?.content?.form?.checkbox?.text ?? "black";
  let borderColor = colorJson?.content?.form?.checkbox?.border ?? "mdGrey";

  return (
    <Checkbox colorScheme={color} borderColor={borderColor}  {...props} />
  )
};


// content.category.subCategory.background
// content.form.checkbox.border
