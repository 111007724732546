import { Box, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { compose } from "recompose";
import { Headline } from "app/shared";
import i18next from "i18next";
import { NewsCard } from "./NewsCard";
import { News } from "../models/index";
//import { NewsRepository } from "../repositories/NewsRepository";
import { useNewsRepositoryContext } from "../repositories";
import { useMediaQueryContext } from "styles/context";


interface Props {

}

const NewsResultsBase: React.FC<Props> = () => {
  const { newsRepository } = useNewsRepositoryContext();
  const { isSmall, isSmallMedium } = useMediaQueryContext();

  let news: News[] =  newsRepository.news;

  return (
    <Box>
      <Headline as="h2" fontSize={["xl", "xl", "5xl", "5xl"]}>News für den Handel</Headline>
      <Flex
        direction={[
          "column",
        ]}
        justifyContent="space-between">

        {news.map((newsItem) => (

          <NewsCard
            imgSrc={newsItem.pictureFile}
            imgSrc2={""}
            imgPosition={"left"}
            text={newsItem.teaser}
            key={newsItem.id}
            id={newsItem.id}
            headline={newsItem.headline}
            date={newsItem.parsedDate}
            mb={isSmallMedium ? "50px" : "35px"}
          />
        ))}

      </Flex>
    </Box>
  );
};

export const NewsResult = compose<Props, Props>(observer)(NewsResultsBase);
