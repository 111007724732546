import { QuestionIcon } from "@chakra-ui/icons";
import { BoxProps, Flex, IconProps, Link, Stack } from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { useMediaQueryContext } from "styles/context";
import { FacebookIcon, InstagramIcon, TwitterIcon, WhatsappIcon } from "styles/icons";
import { Heading } from "../heading";
import { SocialNetworks } from "./constants";

interface Props extends BoxProps {
  header?: string;
}

export const SocialInfo: React.FC<Props> = ({ header, ...rest }) => {
  const { isSmallMedium } = useMediaQueryContext();

  return (
    <Flex visibility="hidden" direction="column" alignItems="center" pb={[6, 6, 0]} {...rest}>
      {header && (
        <Heading fontSize={["2xl", "4xl", "7xl"]}>{i18next.t(header)}</Heading>
      )}
      <Stack direction="row" spacing={[4, 6, 8]}>
        {SocialNetworks.map((socialNetwork, index) => (
          <Link
            key={`socialNetwork-${index}`}
            href={socialNetwork.link}
            isExternal
            target="_blank">
            <SocialIcon
              name={socialNetwork.name}
              boxSize={isSmallMedium ? "40px" : "70px"}
            />
          </Link>
        ))}
      </Stack>
    </Flex>
  );
};

interface SocialIconProps extends IconProps {
  name: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({ name, ...rest }) => {
  switch (name) {
    case "facebook": {
      return <FacebookIcon {...rest} />;
    }
    case "instagram": {
      return <InstagramIcon {...rest} />;
    }
    case "whatsapp": {
      return <WhatsappIcon {...rest} />;
    }
    case "twitter": {
      return <TwitterIcon {...rest} />;
    }
    default: {
      return <QuestionIcon {...rest} />;
    }
  }
};