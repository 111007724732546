import React from "react";
import { Box, AspectRatio, Image, Text, Link } from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router";

import { ProductForTable } from "app/products/models";
import i18next from "i18next";
import { LOCAL_DELIVERY, NATIONAL_DELIVERY } from "app/shared/models";
import { DEFAULT_RATIO } from "app/shared/constants";
import { useStoreWindowRepositoryContext } from "../repositories";
import environment from "configurations";
import { TextLink } from "app/shared";

interface ProductCardProps {
  product: ProductForTable;
}

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const { storeWindowRepository } = useStoreWindowRepositoryContext();

  let deliverTypeString = "";
  if (product.deliveryOptions.canDeliver) {
    if (product.deliveryOptions.deliveryType == LOCAL_DELIVERY)
      deliverTypeString += `${i18next.t(
        "storeWindows:storeWindowInfo.local"
      )} ${product.deliveryOptions.localDeliveryRadiusKm} km`;
    if (product.deliveryOptions.deliveryType == NATIONAL_DELIVERY)
      deliverTypeString += i18next.t("storeWindows:storeWindowInfo.national");
  }

  let deliveryOption;
  if (product.deliveryOptions.canPickup && product.deliveryOptions.canDeliver)
    deliveryOption = (
      <Text color="white" fontSize="lg">
        {i18next.t("storeWindows:storeWindowInfo.pickupAndDeliver")}{" "}
        {deliverTypeString}
      </Text>
    );
  else if (product.deliveryOptions.canPickup)
    deliveryOption = (
      <Text color="white" fontSize="lg">
        {i18next.t("storeWindows:storeWindowInfo.pickupOnly")}
      </Text>
    );
  else if (product.deliveryOptions.canDeliver)
    deliveryOption = (
      <Text color="white" fontSize="lg">
        {i18next.t("storeWindows:storeWindowInfo.deliverOnly")}{" "}
        {deliverTypeString}
      </Text>
    );
  else
    deliveryOption = (
      <Text color="white" fontSize="lg">
        {i18next.t("storeWindows:storeWindowInfo.noDeliveryAndPickup")}{" "}
      </Text>
    );

  return (
    <Box boxShadow="lg">
      <AspectRatio ratio={DEFAULT_RATIO}>
        <TextLink
          to={`/product/${storeWindowRepository.storeWindow.id}/${product.id}`}
        >
          <Image
            objectFit="contain"
            width="100%"
            height="100%"
            src={environment.site + product.photo?.url}
          />
        </TextLink>
      </AspectRatio>
      <Box p={8} bg="lighterBrandGrey">
        <TextLink
          to={`/product/${storeWindowRepository.storeWindow.id}/${product.id}`}
          color="white"
          fontSize="lg"
          fontWeight="bold"
        >
          {product.name}
        </TextLink>

        <Text color="white" fontSize="lg">{`${product.price} €`}</Text>

        {deliveryOption}
      </Box>
    </Box>
  );
};
