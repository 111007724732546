import { Store, StoreId } from "app/stores/models";
import { computed, flow, observable } from "mobx";
import { getOpeningHours, getStore } from "./../services/index";

type Status = "successful" | "pending" | "error";

export default class StoreRepository {
  @observable private _store: Store = {} as any;
  //@observable private _storeVideo: any = {} as any;
  @observable private _status: Status = "pending";

  fetchStore = flow(function* (this: StoreRepository, storeId: StoreId) {
    try {
      const store: Store = yield getStore(storeId);
      store.openingHours = yield getOpeningHours(storeId);

      this._status = "successful";
      this._store = store;
    } catch (error) {
      this._status = "error";
    }
  }).bind(this);

  //fetchTestimonial  = flow(function* (this: StoreRepository, storeId: StoreId) {
  //  try {
  //    const videoInfo: any = yield getStoreVideoInformation(storeId);

  //    this._status = "successful";
  //    this._storeVideo = JSON.parse(videoInfo);
  //  } catch (error) {
  //    this._status = "error";
  //  }
  //}).bind(this);

  @computed get store(): Store {
    return this._store;
  }

  //@computed get storeVideo(): any {
  //  return this._storeVideo;
  //}

  @computed get status(): Status {
    return this._status;
  }
}
